import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import VideoWithHover from "app/components/common/VideoWithHover";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import React from "react";
import { useAppSelector } from "app/hooks";
import { mediaGlobalSelectors } from "app/store/adapters/adapters";
import { MediaType, ServerFolderMedia } from "app/types/media";
import useDisplayUrls, { MediaUrl } from "app/hooks/useDisplayUrls";
import styled from "styled-components";
import { PreloadedVideo } from "app/types/playground";
import ConditionalRender from "app/components/common/ConditionalRender";

interface MediaFileItemProps {
  fileId: string;
  onSelectMedia: (payload: PreloadedVideo) => void;
}

const OuterImageBackgroundContainer = styled(H1_FlexRow)<{ $isBorderDisplayed: boolean }>`
  border-radius: 10px;
  border: 1px solid ${({ $isBorderDisplayed }) => ($isBorderDisplayed ? "white" : "transparent")};
  background-color: transparent;
  padding: 2px; /* Space between the border and the inner element */
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(0.95);
  }
`;

const MediaFileItem = ({ fileId, onSelectMedia }: MediaFileItemProps) => {
  const file = useAppSelector((state) =>
    mediaGlobalSelectors.selectById(state, fileId)
  ) as ServerFolderMedia;
  const { displayUrls, loading } = useDisplayUrls([file?.data?.url]);
  const displayUrl: MediaUrl | undefined = displayUrls[file?.data?.url as string];
  const isVideo = displayUrl?.assetType === MediaType.video;
  const customPresenter = useAppSelector((state) => state.playground.customPresenter);
  const livePortrait = useAppSelector((state) => state.playground.livePortrait);
  if (loading) {
    return null;
  }

  const onSelectFile = () => {
    onSelectMedia({
      url: file.data?.url as string,
      preview: file.data?.url as string,
      thumbnail: displayUrl?.thumbnail as string
    });
  };

  return (
    <H1_FlexColumn gap="5px">
      <OuterImageBackgroundContainer
        flex="0 0 87px"
        height="87px"
        $isBorderDisplayed={
          file.data?.url === customPresenter?.url || file.data?.url === livePortrait?.url
        }
        position="relative"
      >
        <H1_FlexRow
          position="relative"
          width="calc((81px * 16) / 9)"
          height="81px"
          onClick={onSelectFile}
          justify="center"
        >
          <ConditionalRender condition={isVideo}>
            <VideoWithHover
              video={displayUrl?.url as string}
              autoPlay={false}
              disableIcon
              shouldRestartOnLeave={false}
            />
          </ConditionalRender>
          <ConditionalRender condition={!isVideo}>
            <img src={displayUrl?.url as string} alt="" />
          </ConditionalRender>
        </H1_FlexRow>
      </OuterImageBackgroundContainer>
    </H1_FlexColumn>
  );
};

export default MediaFileItem;
