import { PusherMessagesBase } from "app/types/pusherMessages";
import { ReactNode } from "react";
import { MimeType } from "app/types/ui";
import { Folder, FolderType } from "app/types/folder";

export enum MediaSources {
  stableDiffusion = "stable_diffusion",
  upload = "upload"
}

export enum MediaLibraryTabs {
  MyMedia = "MyMedia",
  Unsplash = "Unsplash",
  Hourone = "Hourone",
  Pexels = "Pexels",
  StableDiffusion = "StableDiffusion"
}

export interface Media {
  owner: string;
  url: string;
  handle: string;
  name?: string;
  id: string;
  moderation_pass: string;
  media_type: string;
  metadata?: Record<string, any>;
  source: MediaSources;
  orderId?: string;
}

export interface MediaGeneratedMessage extends Media, PusherMessagesBase {}

export enum MediaType {
  folder = "folder",
  video = "video",
  image = "image",
  audio = "audio",
  all = "*"
}

export const allMediaTypeOptions = [
  MediaType.all,
  MediaType.image,
  MediaType.video,
  MediaType.folder
];
export enum AssetKeyType {
  draft = "draft",
  scene = "scene",
  brandkit = "brandkit"
}

export interface InternalMedia {
  fileName?: string;
  id: string; // can be handle value or temp value
  handle: string;
  status?: string;
  thumbnail?: string;
  thumbnailType?: MediaType;
  mediaType?: MediaType;
  mimetype?: string;
  uploaded?: number;
  url: string;
  percent?: number;
  loading?: boolean;
  size?: number;
  metadata?: Record<string, any>;
  source?: MediaSources;
}

export interface ServerFolderMedia extends Folder {
  id: string;
  type: FolderType;
  created_at: string;
  data?: Media;
}

export enum VideoFit {
  Scale = "Scale",
  Crop = "Crop",
  Clip = "Clip",
  Pad = "Pad"
}
export enum MediaTrimType {
  freeze = "freeze",
  adaptSpeed = "adapt_speed",
  loop = "loop"
}

export enum VideoAction {
  LOOP = "loop",
  INTERPOLATE = "interpolate",
  FREEZE = "freeze",
  CROP = "crop",
  SCALE = "scale",
  PAD_FIT = "pad_fit",
  TRIM = "trim"
}

export enum VideoScaleMethod {
  CLIP = "clip",
  MAX = "max",
  SCALE = "scale",
  CROP = "crop",
  COLOR = "color"
}
export interface MediaProperties {
  trim_type: MediaTrimType;
  duration: number;
  trim_start_sec: number;
  trim_end_sec: number;
}

export enum MediaTypeSort {
  date = "date",
  name = "name"
}
export enum SortOrder {
  up = "up",
  down = "down"
}

export interface MediaSort {
  prop: MediaTypeSort;
  order: SortOrder;
}

export interface SelectedImageContext {
  handle: string;
  url: string;
  thumbnailType?: MediaType;
  thumbnail?: string;
  title?: string | ReactNode;
  tab?: MediaLibraryTabs;
  mediaType?: MediaType;
  mediaId?: string;
}

export interface TypeTag {
  id: string;
  label: string;
  color: string;
  icon: string;
}
export interface TagAreaType {
  tags: TypeTag[];
  setTags: React.Dispatch<React.SetStateAction<TypeTag[]>>;
  editable?: boolean;
}

export enum FilterType {
  monochrome = "monochrome",
  sepia = "sepia",
  oil_paint = "oil_paint"
}

export interface CreateMedia {
  url: string;
  handle: string;
  parent_id?: string;
}

export interface MediaUpdateRequest {
  name: string;
}

export const allowedDefaultMediaFiles = [
  MimeType.allowedImages,
  MimeType.mp4,
  MimeType.mov,
  MimeType.presentations
];
export const defaultFileTypes = allowedDefaultMediaFiles.join(", ");

export enum UploadFileSource {
  mediaLibrary = "mediaLibrary",
  thirdParty = "thirdParty"
}
export interface UploadFileInfo {
  url: string;
  handle: string;
  source: UploadFileSource;
}
export interface StockMedia {
  id: string;
  handle: string;
  created_at: string;
  updated_at: string;
  width: number;
  height: number;
  url: string;
  media_type: MediaType;
  tags: string[];
}
