import styled from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { durationToMMSS, durationToMMSSss } from "app/utils/helpers";
import useVideoSyncEditor from "app/hooks/useVideoSyncEditor";
import TrimInput from "app/components/editor/sideDrawers/SceneDrawer/TrimInput";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { videoActionsMessages } from "app/components/editor/sideDrawers/SceneDrawer/messages";
import { useIntl } from "react-intl";
import { useEffect, useState, useRef } from "react";
import { VideoFit } from "app/types/media";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { Button } from "@nextui-org/react";

const StyledIconButton = styled(Button)`
  min-width: 20px;
  i {
    font-size: 25px;
    color: ${(props) => props.theme.gray8};
  }
  border: unset;
`;

const PlayButtonFlexRow = styled(H1_FlexRow)`
  top: 20px;
  left: 20px;
  z-index: 10;
`;

interface VideoTrimmerProps {
  video: HTMLVideoElement | null;
  onClickApply: ([min, max]: [number, number]) => void;
  onClickCancel: (initialVideoFit: VideoFit) => void;
  videoStartTime: number;
  videoEndedTime: number;
  durationInFrames: number;
  videoFit: VideoFit;
  onPlay: () => void;
  onPause: () => void;
  isPlaying: boolean;
}
const VideoTrimmer = ({
  video,
  onClickCancel,
  onClickApply,
  videoStartTime,
  videoEndedTime,
  durationInFrames,
  videoFit,
  onPlay,
  onPause,
  isPlaying
}: VideoTrimmerProps) => {
  const { currentTime, videoRange, videoMax, onVideoRangeChange, onTrackChange } =
    useVideoSyncEditor({
      video,
      durationInFrames,
      initialTime: video?.currentTime
    });
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [initialVideoFit] = useState<VideoFit>(videoFit);
  const { formatMessage } = useIntl();

  const videoRef = useRef<HTMLVideoElement | null>(video);

  useEffect(() => {
    videoRef.current = video;
  }, [video]);

  const handlePlayPause = () => {
    if (isPlaying) {
      onPause();
    } else {
      onPlay();
    }
  };

  useEffect(() => {
    if (videoEndedTime > videoStartTime) {
      onVideoRangeChange([videoStartTime, videoEndedTime]);
    }
  }, [videoEndedTime]);

  useEffect(() => {
    if (video && !isLoaded && videoRange[0] !== videoRange[1]) {
      video.currentTime = videoRange[0];
      video.pause();
      setIsLoaded(true);
    }
  }, [videoRange, isLoaded]);
  return (
    <H1_FlexColumn gap="20px" width="100%" justify="space-between" flex="1">
      <H1_FlexRow align="center" justify="center">
        <H1_TextXs whiteSpace="pre">
          {durationToMMSS(videoRange[0] <= currentTime ? currentTime - videoRange[0] : 0)} -{" "}
          {durationToMMSS(videoRange[1] - videoRange[0])}
        </H1_TextXs>
      </H1_FlexRow>
      <H1_FlexRow width="100%" flex="1 1 auto">
        <H1_FlexRow
          gap="25px"
          width="100%"
          align="center"
          justify="space-between"
          position="relative"
          padding="0 20px"
        >
          <PlayButtonFlexRow gap="5px" align="center" height="66px">
            <StyledIconButton
              isIconOnly
              variant="light"
              onClick={handlePlayPause}
              startContent={
                isPlaying ? (
                  <i className="fa-solid fa-circle-pause" />
                ) : (
                  <i className="fa-solid fa-circle-play" />
                )
              }
            />
          </PlayButtonFlexRow>
          <TrimInput
            trackValue={currentTime}
            formatter={(value?: number) => durationToMMSSss(Number(value ?? 0))}
            onTrackChange={onTrackChange}
            min={0}
            max={videoMax}
            onChange={onVideoRangeChange}
            value={videoRange}
          />
        </H1_FlexRow>
      </H1_FlexRow>
      <H1_FlexRow gap="20px" justify="flex-end" width="100%" flex="0 0 38px">
        <Button onClick={() => onClickCancel(initialVideoFit)} variant="light">
          {formatMessage(videoActionsMessages.cancelBtn)}
        </Button>
        <Button color="primary" onClick={() => onClickApply(videoRange)}>
          {formatMessage(videoActionsMessages.applyBtn)}
        </Button>
      </H1_FlexRow>
    </H1_FlexColumn>
  );
};

export default VideoTrimmer;
