import { useEffect } from "react";
import pusher from "app/services/pusher";
import { useAuth } from "app/auth/useAuth";
import { Channel } from "pusher-js";
import { useAppDispatch } from "app/hooks";
import { ChannelsTabs } from "app/types";
import { cgnActions } from "app/store/slices/cgn.slice";

const PusherCGNNextVideo = () => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    let channel: Channel;
    const handleCGN = (message: { channel_name: ChannelsTabs; next_video: string }) => {
      const { channel_name, next_video } = message;
      dispatch(cgnActions.updateChannelNextVideo({ channelName: channel_name, title: next_video }));
    };
    if (user?.sub) {
      const channelName = `hourone_streaming`;
      channel = pusher.subscribe(channelName);
      channel.bind("hourone.streaming.next_video", handleCGN);
    }
    return () => {
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, []);

  return null;
};

export default PusherCGNNextVideo;
