import { useIntl } from "react-intl";
import styled, { useTheme } from "styled-components";
import { homePageMessages } from "app/pages/HomePage/messages";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import {
  FeatureFlag,
  FlatRecipe,
  Location,
  PlanEnum,
  UseCaseSegmentType,
  WorkspaceType
} from "app/types";
import React, { useEffect, useMemo, useState } from "react";
import ConditionalRender from "app/components/common/ConditionalRender";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useNavigate } from "react-router-dom";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { NewVideoType } from "app/store/thunks/analyticsEvents.thunk";
import uiActions from "app/store/actions/ui.actions";
import useModal, { ModalName } from "app/hooks/useModal";
import ZoomModal from "app/pages/HomePage/ZoomModal";
import VideoWizardModalV2 from "app/components/common/VideoWizardModalV2/VideoWizardModalV2";
import { paymentModalMessages } from "app/pages/pricing/messages";
import { PlanFeature } from "app/config/planFeature";
import useUpgradeModal, { useShowCrown } from "app/hooks/useUpgradeModal";
import { charactersActions } from "app/store/slices/characters.slice";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import recipesSelectors from "app/store/selectorsV2/recipes.selectors";
import { draftsActions } from "app/store/slices/drafts.slice";
import { fetchingStatus } from "app/utils/helpers";
import * as draftsSelectors from "app/store/selectorsV2/drafts.selectors";
import { ThemeMode } from "app/utils/theme";
import { useWindowSize } from "@react-hook/window-size";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { H1_Dropdown } from "app/components/_Infrastructure/design-system/dropdown";
import PodcastModal from "app/pages/HomePage/PodcastModal/PodcastModal";

const StyledDropdown = styled(H1_Dropdown)`
  color: ${(props) => props.theme.gray11};
  background-color: ${({ theme }) => theme.gray2};
  transition: background-color 0.3s ease-in-out;
  .ant-dropdown-menu {
    color: ${(props) => props.theme.gray11};
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? `${theme.gray1}DD` : `${theme.gray12}DD`};
  }
  i {
    color: ${(props) => props.theme.gray11};
  }
  &:hover {
    background-color: ${({ theme }) => theme.gray2};
    color: ${(props) => props.theme.gray11};
    i {
      color: ${(props) => props.theme.gray11};
    }
  }
  padding: 10px;
  &.video-selection-type {
    .ant-dropdown {
      backdrop-filter: blur(4px);
      width: auto;
      .ant-dropdown-menu {
        padding: 10px;
        li.clickable:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
    max-height: 180px;
    border-radius: 5px;
    border: none;
    .clickable {
      border-radius: 5px;
      border: 1px solid ${({ theme }) => theme.gray4};
    }
    .selected {
      border: 1px solid ${({ theme }) => theme.blue4};
    }
    font-size: 12px;
    .ant-dropdown-menu-item {
      padding: 5px 10px;
      border-radius: 12px;
      i {
        color: ${({ theme }) => theme.gray1};
      }
      &:hover {
        background-color: ${({ theme }) => theme.gray3};
      }
    }
    .ant-dropdown-menu-item-group-title {
      padding: 5px 10px;
    }
  }
`;

const MaxFlexRow = styled(H1_FlexRow)`
  max-height: 250px;
`;

const SelectionContainer = styled(H1_FlexRow)`
  height: 64px;
  border-radius: 14px;
  padding-left: 13px;
  gap: 13px;
  width: 250px;
  transition: background-color 0.3s ease-in-out;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray3 : theme.gray2)};
  i {
    color: ${({ theme }) => theme.gray7};
  }
  &:hover {
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray4 : theme.gray3};
  }
  @media screen and (max-width: 1420px) {
    width: 200px;
  }
  @media screen and (max-width: 1300px) {
    width: 170px;
  }
  @media screen and (max-width: 1200px) {
    width: 150px;
    i {
      display: none;
    }
  }
`;

const SOURCE = "banner";

const HomePageVideoSelections = ({
  onShowTemplatesCollection,
  showTemplatesCollection
}: {
  onShowTemplatesCollection?: (bool: boolean) => void;
  showTemplatesCollection?: boolean;
}) => {
  const [isPodcastModalOpen, setIsPodcastModalOpen] = useState<boolean>(false);
  const [isZoomModalOpen, setIsZoomModalOpen] = useState<boolean>(false);
  const [isVideoWizardV2ModalOpen, setIsVideoWizardV2ModalOpen] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const [width] = useWindowSize();
  const { openModal } = useModal();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const flags = useFlags();
  const showPaymentModal = useUpgradeModal();
  const showCrown = useShowCrown({ requestedFeature: PlanFeature.summaryMeeting });

  const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));
  const preferences = useAppSelector((state) => state.user.preferences);
  const usageSegment = preferences.usage_segment;
  const flattenRecipes = useAppSelector(
    recipesSelectors.getFlattenRecipesCustomsFirst
  ) as FlatRecipe[];
  const createDraftStatus = useAppSelector(draftsSelectors.createDraftStatus);
  const createdDraft = useAppSelector(draftsSelectors.currentDraft);

  const isUsageSales = usageSegment === UseCaseSegmentType.Sales;
  const isUsageMediaEntertainment = usageSegment === UseCaseSegmentType.MediaEntertainment;
  const isIconicWorkspace =
    currentWorkspace?.metadata?.type === WorkspaceType.Iconic && flags[FeatureFlag.iconic];
  const isShow4Selections = width >= 1760;
  const textColor = theme.mode === ThemeMode.Light ? theme.gray6 : theme.gray5;

  useEffect(() => {
    if (isUsageSales && currentWorkspace?.id) {
      dispatch(charactersActions.getCharactersListRequest());
    }
  }, [currentWorkspace?.id, isUsageSales]);

  useEffect(() => {
    if (createDraftStatus === fetchingStatus.idle) {
      return;
    }
    if (createDraftStatus === fetchingStatus.succeeded) {
      dispatch(analyticsEvents.navigateToEditor({ source: "iconic_video" }));
      navigate(`/editor/${createdDraft.id}`);
      dispatch(draftsActions.setCreateDraftStatusToIdle());
    } else if (createDraftStatus === fetchingStatus.failed) {
      dispatch(draftsActions.setCreateDraftStatusToIdle());
    }
  }, [createDraftStatus]);

  const onClickPodcast = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: SOURCE,
        type: NewVideoType.podcast
      })
    );
    setIsPodcastModalOpen(true);
  };

  const onClickTemplate = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: SOURCE,
        type: NewVideoType.video
      })
    );
    if (isUsageSales && onShowTemplatesCollection) {
      onShowTemplatesCollection(!showTemplatesCollection);
    } else {
      navigate(Location.Templates);
    }
  };

  const onImportPresentation = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: SOURCE,
        type: NewVideoType.presentation
      })
    );
    dispatch(uiActions.setFtuVideoPreview(false));
    openModal(ModalName.presentationUploader);
  };

  const onClickDocs = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: SOURCE,
        type: NewVideoType.importDocument
      })
    );
    const recipeId = isIconicWorkspace ? flattenRecipes[0].id : undefined;
    openModal(ModalName.startFromDocumentModal, { recipeId });
  };
  const onClickUrl = () => {
    if (
      showPaymentModal({
        source: "start-from-url",
        upgradeText: formatMessage(paymentModalMessages.upgradeStartFromUrlTitle),
        requestedFeature: PlanFeature.StartFromUrl,
        targetPlan: PlanEnum.personal
      })
    ) {
      return;
    } else {
      dispatch(
        analyticsEvents.newVideo({
          source: SOURCE,
          type: NewVideoType.importUrl
        })
      );
      const recipeId = isIconicWorkspace ? flattenRecipes[0].id : undefined;
      openModal(ModalName.startFromUrlModal, { recipeId });
    }
  };
  const onClickScript = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: SOURCE,
        type: NewVideoType.script
      })
    );
    const recipeId = isIconicWorkspace ? flattenRecipes[0].id : undefined;
    openModal(ModalName.startFromScriptModal, { recipeId });
  };

  const onClickVideoWizard = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: SOURCE,
        type: NewVideoType.videoWizard
      })
    );
    setIsVideoWizardV2ModalOpen(true);
  };

  const onCloseVideoWizardModalV2 = () => {
    setIsVideoWizardV2ModalOpen(false);
    dispatch(analyticsEvents.videoWizardV2({ action: "closeVideoWizard", source: SOURCE }));
  };

  const onClickCreateCharacterAction = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: SOURCE,
        type: NewVideoType.createCharacter
      })
    );
    openModal(ModalName.createVirtualTwin);
  };

  const items = useMemo(() => {
    return [
      flags[FeatureFlag.mediaAndEntertainment] &&
        isUsageMediaEntertainment && {
          key: "video-wizard",
          onClick: onClickVideoWizard,
          icon: (
            <H1_Icon
              margin="0 13px 0 0"
              size="14px"
              color={theme.gray7}
              icon="fas fa-wand-magic-sparkles"
            />
          ),
          label: (
            <H1_FlexColumn width="250px" height="54px" justify="center">
              <H1_TextXs color={theme.gray7}>
                {formatMessage(homePageMessages.fromVideoWizardCard)}
              </H1_TextXs>
              <H1_TextXs color={textColor}>
                {formatMessage(homePageMessages.fromVideoWizardCardDesc)}
              </H1_TextXs>
            </H1_FlexColumn>
          )
        },
      !isUsageSales &&
        !isShow4Selections && {
          key: "document",
          onClick: onClickDocs,
          icon: (
            <H1_Icon
              margin="0 13px 0 0"
              size="14px"
              color={theme.gray7}
              icon="fas fa-file-signature"
            />
          ),
          label: (
            <H1_FlexColumn width="250px" height="54px" justify="center">
              <H1_TextXs color={theme.gray7}>
                {formatMessage(homePageMessages.startFromDocument)}
              </H1_TextXs>
              <H1_TextXs color={textColor}>
                {formatMessage(homePageMessages.fromDocument)}
              </H1_TextXs>
            </H1_FlexColumn>
          )
        },
      flags[FeatureFlag.fromUrl] &&
        !isUsageSales && {
          key: "url",
          onClick: onClickUrl,
          icon: (
            <H1_Icon margin="0 13px 0 0" size="14px" color={theme.gray7} icon="fas fa-browser" />
          ),
          label: (
            <H1_FlexColumn width="250px" height="54px" justify="center">
              <H1_TextXs color={theme.gray7}>{formatMessage(homePageMessages.fromUrl)}</H1_TextXs>
              <H1_TextXs color={textColor}>{formatMessage(homePageMessages.fromUrlDesc)}</H1_TextXs>
            </H1_FlexColumn>
          )
        },
      flags[FeatureFlag.fromScript] &&
        !isUsageSales && {
          key: "script",
          onClick: onClickScript,
          icon: (
            <H1_Icon margin="0 13px 0 0" size="14px" color={theme.gray7} icon="fas fa-scroll" />
          ),
          label: (
            <H1_FlexColumn width="250px" height="54px" justify="center">
              <H1_TextXs color={theme.gray7}>
                {formatMessage(homePageMessages.fromScript)}
              </H1_TextXs>
              <H1_TextXs color={textColor}>
                {formatMessage(homePageMessages.fromScriptDesc)}
              </H1_TextXs>
            </H1_FlexColumn>
          )
        },
      !isIconicWorkspace && {
        key: "create-avatar",
        onClick: onClickCreateCharacterAction,
        icon: <H1_Icon margin="0 13px 0 0" size="14px" color={theme.gray7} icon="fas fa-user" />,
        label: (
          <H1_FlexColumn width="250px" height="54px" justify="center">
            <H1_TextXs color={theme.gray7}>
              {formatMessage(homePageMessages.fromOwnCharacter)}
            </H1_TextXs>
            <H1_TextXs color={textColor}>
              {formatMessage(homePageMessages.fromOwnCharacterDesc)}
            </H1_TextXs>
          </H1_FlexColumn>
        )
      }
    ];
  }, [theme.mode, usageSegment, showCrown, isIconicWorkspace, isShow4Selections, textColor]);

  return (
    <MaxFlexRow width="100%" flex="1 1 auto" padding="0 50px 0 0" gap="30px">
      <PodcastModal visible={isPodcastModalOpen} onClose={() => setIsPodcastModalOpen(false)} />
      <ZoomModal visible={isZoomModalOpen} closeModal={() => setIsZoomModalOpen(false)} />
      <VideoWizardModalV2
        source={SOURCE}
        visible={isVideoWizardV2ModalOpen}
        onClose={onCloseVideoWizardModalV2}
      />
      <H1_FlexRow gap="25px" align="center">
        <ConditionalRender
          condition={
            !isIconicWorkspace &&
            flags[FeatureFlag.mediaAndEntertainment] &&
            flags[FeatureFlag.podcast] &&
            isUsageMediaEntertainment
          }
        >
          <H1_FlexColumn align="center" gap="6px" onClick={onClickPodcast}>
            <SelectionContainer align="center">
              <i className="fas fa-microphone" />
              <H1_FlexColumn>
                <H1_TextSmall color={theme.gray7}>
                  {formatMessage(homePageMessages.startFromPodcast)}
                </H1_TextSmall>
                <H1_TextXs color={textColor}>
                  {formatMessage(homePageMessages.fromPodcastCard)}
                </H1_TextXs>
              </H1_FlexColumn>
            </SelectionContainer>
          </H1_FlexColumn>
        </ConditionalRender>
        <ConditionalRender condition={!isIconicWorkspace}>
          <H1_FlexColumn align="center" gap="6px" onClick={onClickTemplate}>
            <SelectionContainer align="center">
              <i className="fas fa-objects-column" />
              <H1_FlexColumn>
                <H1_TextSmall color={theme.gray7}>
                  {formatMessage(homePageMessages.startFromTemplate)}
                </H1_TextSmall>
                <H1_TextXs color={textColor}>
                  {formatMessage(homePageMessages.fromTemplateCard)}
                </H1_TextXs>
              </H1_FlexColumn>
            </SelectionContainer>
          </H1_FlexColumn>
        </ConditionalRender>
        <ConditionalRender condition={!isIconicWorkspace}>
          <H1_FlexColumn align="center" gap="6px" onClick={onImportPresentation}>
            <SelectionContainer align="center">
              <i className="fas fa-presentation-screen" />
              <H1_FlexColumn>
                <H1_TextSmall color={theme.gray7}>
                  {formatMessage(homePageMessages.startFromPresentation)}
                </H1_TextSmall>
                <H1_TextXs color={textColor}>
                  {formatMessage(homePageMessages.fromPresentationCard)}
                </H1_TextXs>
              </H1_FlexColumn>
            </SelectionContainer>
          </H1_FlexColumn>
        </ConditionalRender>
        <ConditionalRender condition={!isUsageMediaEntertainment}>
          <H1_FlexColumn align="center" gap="6px" onClick={onClickVideoWizard}>
            <SelectionContainer align="center">
              <i className="fas fa-wand-magic-sparkles" />
              <H1_FlexColumn>
                <H1_TextSmall color={theme.gray7}>
                  {formatMessage(homePageMessages.fromVideoWizardCard)}
                </H1_TextSmall>
                <H1_TextXs color={textColor}>
                  {formatMessage(homePageMessages.fromVideoWizardCardDesc)}
                </H1_TextXs>
              </H1_FlexColumn>
            </SelectionContainer>
          </H1_FlexColumn>
        </ConditionalRender>
        <ConditionalRender condition={!isUsageSales && isShow4Selections}>
          <H1_FlexColumn align="center" gap="6px" onClick={onClickDocs}>
            <SelectionContainer align="center">
              <i className="fas fa-file-signature" />
              <H1_FlexColumn>
                <H1_TextSmall color={theme.gray7}>
                  {formatMessage(homePageMessages.startFromDocument)}
                </H1_TextSmall>
                <H1_TextXs color={textColor}>
                  {formatMessage(homePageMessages.fromDocument)}
                </H1_TextXs>
              </H1_FlexColumn>
            </SelectionContainer>
          </H1_FlexColumn>
        </ConditionalRender>
        <ConditionalRender condition={isIconicWorkspace}>
          <H1_FlexColumn align="center" gap="6px" onClick={onClickDocs}>
            <SelectionContainer align="center">
              <i className="fas fa-file-signature" />
              <H1_FlexColumn>
                <H1_TextSmall color={theme.gray7}>
                  {formatMessage(homePageMessages.startFromDocument)}
                </H1_TextSmall>
                <H1_TextXs color={textColor}>
                  {formatMessage(homePageMessages.fromDocument)}
                </H1_TextXs>
              </H1_FlexColumn>
            </SelectionContainer>
          </H1_FlexColumn>
        </ConditionalRender>
        <ConditionalRender condition={flags[FeatureFlag.fromUrl] && isIconicWorkspace}>
          <H1_FlexColumn align="center" gap="6px" onClick={onClickUrl}>
            <SelectionContainer align="center">
              <i className="fas fa-browser" />
              <H1_FlexColumn>
                <H1_TextSmall color={theme.gray7}>
                  {formatMessage(homePageMessages.fromUrl)}
                </H1_TextSmall>
                <H1_TextXs color={textColor}>
                  {formatMessage(homePageMessages.fromUrlDesc)}
                </H1_TextXs>
              </H1_FlexColumn>
            </SelectionContainer>
          </H1_FlexColumn>
        </ConditionalRender>
        <ConditionalRender condition={flags[FeatureFlag.fromScript] && isIconicWorkspace}>
          <H1_FlexColumn align="center" gap="6px" onClick={onClickScript}>
            <SelectionContainer align="center">
              <i className="fas fa-scroll" />
              <H1_FlexColumn>
                <H1_TextSmall color={theme.gray7}>
                  {formatMessage(homePageMessages.fromScript)}
                </H1_TextSmall>
                <H1_TextXs color={textColor}>
                  {formatMessage(homePageMessages.fromScriptDesc)}
                </H1_TextXs>
              </H1_FlexColumn>
            </SelectionContainer>
          </H1_FlexColumn>
        </ConditionalRender>
        <ConditionalRender condition={!isIconicWorkspace}>
          <StyledDropdown
            destroyPopupOnHide
            trigger={["click"]}
            menu={{ items }}
            className="video-selection-type"
            getPopupContainer={() => document.querySelector(`.video-selection-type`) as HTMLElement}
            withChevron={false}
            icon={<H1_Icon isCursorPointer icon="fa-regular fa-ellipsis" size="22px" />}
          />
        </ConditionalRender>
      </H1_FlexRow>
    </MaxFlexRow>
  );
};

export default HomePageVideoSelections;
