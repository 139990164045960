export enum UseCaseSegmentType {
  Training = "training",
  SocialMediaContent = "social_media_content",
  Marketing = "marketing",
  Learning = "e_learning",
  Sales = "sales",
  News = "news",
  MediaEntertainment = "media_entertainment"
}

export const useCaseSegmentTitle = {
  training: "Training",
  social_media_content: "Social Media Content",
  marketing: "Marketing",
  e_learning: "E-learning",
  sales: "Sales",
  media_entertainment: "Media & Entertainment",
  news: "News"
};

export const segmentTitle = (name: string) => {
  return useCaseSegmentTitle[name as keyof typeof useCaseSegmentTitle] || name;
};

export enum UserTypeSegmentType {
  Personal = "personal",
  SmallBusiness = "small_business",
  LargeCompany = "large_company",
  NonProfitOrCharity = "non_profit_or_charity",
  GovernmentInstitutions = "government_institutions",
  Other = "other"
}
