import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextMiddle, H1_TextSmall } from "app/components/_Infrastructure/Typography";
import styled, { useTheme } from "styled-components";
import { getTimeAgo } from "app/utils/helpers";
import { ThemeMode } from "app/utils/theme";
import React, { useMemo } from "react";
import { useAppSelector } from "app/hooks";
import { LiveStream } from "app/types";
import { capitalize } from "lodash-es";
import { Button } from "@nextui-org/react";
import ConditionalRender from "app/components/common/ConditionalRender";
import { getTHumbnail } from "app/pages/CGN/utils";

const ThumbnailImage = styled.img`
  border-radius: 15px;
  width: 169px;
  height: 95px;
`;

const LiveFlexRow = styled(H1_FlexRow)`
  border-radius: 14px;
  border: 1px solid #3b3b3b;
  background-color: #3b3b3b;
  top: 10px;
  left: 10px;
  padding: 0 10px;
  opacity: 0.7;
`;

const RedIcon = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #f31260;
`;

const ButtonsFlexRow = styled(H1_FlexRow)`
  z-index: -1;
  opacity: 0;
  transition: all 0.3s ease-in-out;
`;

const PositionButton = styled(Button)`
  min-width: 20px;
  background-color: rgba(202, 202, 202, 0.6);
  color: white;
  span {
    color: white;
  }
  border: none;
  &:hover,
  &:active {
    border: none;
    color: white;
    span {
      color: white;
    }
    background-color: rgba(202, 202, 202, 1);
    &&& i {
      color: white;
      fill: white;
    }
  }
`;

const StyledIcon = styled.i<{ $color?: string }>`
  font-size: 1.125rem;
  height: 2rem;
  line-height: 2rem;
  margin: auto;
  padding: 0 3px;
  color: ${({ theme, $color }) => ($color ? $color : theme.gray11)};
  &:hover {
    color: ${({ theme, $color }) =>
      $color ? $color : theme.mode === ThemeMode.Light ? theme.blue4 : theme.gray6};
  }
`;

const ContainerFlexRow = styled(H1_FlexRow)<{ $isSelected: boolean }>`
  border-radius: 15px;
  transition: all ease-in-out 0.3s;
  &:hover {
    ${ButtonsFlexRow} {
      z-index: 2;
      opacity: 1;
    }
  }
  &:active {
    background-color: ${({ theme }) => theme.gray3};
  }
`;

interface CGNVodProps {
  playbackId: string;
  onPlayVideo: () => void;
  isSelected: boolean;
}
const CGNVod = ({ playbackId, onPlayVideo, isSelected }: CGNVodProps) => {
  const theme = useTheme();
  const streamersVod = useAppSelector((state) => state.cgn.streamersVod);
  const channels = useAppSelector((state) => state.cgn.channels);

  const streams = useMemo(() => [...channels, ...streamersVod], [channels, streamersVod]);

  const currentStream: LiveStream | undefined = useMemo(
    () => streams.find((vod: LiveStream) => vod.playback_id === playbackId),
    [playbackId, streams]
  );

  if (!currentStream) {
    return null;
  }
  return (
    <ContainerFlexRow $isSelected={isSelected} gap="11px" onClick={onPlayVideo}>
      <H1_FlexRow position="relative">
        <ConditionalRender condition={!currentStream.thumbnail}>
          <H1_FlexRow position="relative">
            <ThumbnailImage src={getTHumbnail(currentStream)} />
            <LiveFlexRow height="24px" position="absolute" gap="10px" align="center">
              <RedIcon />
              <H1_TextSmall fontSize="10px" color="white">
                LIVE
              </H1_TextSmall>
            </LiveFlexRow>
          </H1_FlexRow>
        </ConditionalRender>
        <ConditionalRender condition={!!currentStream.thumbnail}>
          <ThumbnailImage src={currentStream.thumbnail}></ThumbnailImage>
        </ConditionalRender>
        <ButtonsFlexRow
          position="absolute"
          height="100%"
          width="100%"
          align="center"
          justify="center"
          gap="0.625rem"
        >
          <PositionButton
            isIconOnly
            onClick={onPlayVideo}
            startContent={<StyledIcon $color="white" className="fa-solid fa-play" />}
          />
        </ButtonsFlexRow>
      </H1_FlexRow>
      <H1_FlexColumn gap="5px">
        <H1_TextMiddle whiteSpace="normal" color={theme.gray11}>
          {currentStream.title || capitalize(currentStream.name)}
        </H1_TextMiddle>
        <H1_TextSmall color={theme.mode === ThemeMode.Light ? theme.gray7 : theme.gray5}>
          {getTimeAgo(currentStream.created_at)}
        </H1_TextSmall>
        <ConditionalRender condition={!!currentStream?.viewers}>
          <H1_TextSmall color={theme.gray6}>{currentStream?.viewers} viewers</H1_TextSmall>
        </ConditionalRender>
      </H1_FlexColumn>
    </ContainerFlexRow>
  );
};

export default CGNVod;
