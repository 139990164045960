import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled from "styled-components";
import { ThemeMode } from "app/utils/theme";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import {
  H1_TextBanner,
  H1_TextHeadline,
  H1_TextMiddle,
  H1_TextSmall
} from "app/components/_Infrastructure/Typography";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { cgnActions } from "app/store/slices/cgn.slice";
import {
  CGNType,
  ChannelsTabs,
  DEFIANCE,
  LiveStream,
  LiveVideoMode,
  Location,
  MuxStream
} from "app/types";
import { fetchingStatus, getTimeAgo } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import CGNHeader from "app/pages/CGN/CGNHeader";
import { SwiperSlide } from "swiper/react";
import { NavigationSwiper } from "app/components/common/NavigationSwiper";
import { getTHumbnail } from "./utils";
import MuxPlayer from "app/components/common/player/MuxPlayer";
import { Button } from "@nextui-org/react";
import MuxPlayerElement from "@mux/mux-player";
import CGNPlayerControls from "app/pages/CGN/CGNPlayerControls";
import useVideo from "app/hooks/useVideo";
import { StyledSkeletonButton } from "app/components/common/Loaders/SkeletonButtonLoader";
import { useNavigate, useSearchParams } from "react-router-dom";

const RoundButton = styled(Button)`
  border-radius: 50%;
`;

const ButtonsFlexColumn = styled(H1_FlexColumn)<{ $isHidden: boolean }>`
  right: 10px;
  top: 10px;
  transition: opacity 0.5s ease-in-out;
  opacity: ${(props) => (props.$isHidden ? 1 : 0)};
  &&&& {
    height: auto;
  }
`;

const MuxFlexColumn = styled(H1_FlexColumn)`
  mux-player {
    max-height: 100vh;
    --controls: none;
  }
  mux-player::part(--controls) {
    display: none !important;
  }
`;

const AnimatedHeader = styled(H1_FlexColumn)<{ isHidden: boolean }>`
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: ${(props) => (props.isHidden ? 0 : 1)};
  transform: ${(props) => (props.isHidden ? "translateY(-50px)" : "translateY(0)")};
  position: absolute;
  z-index: 2;
`;

const AnimatedFlexColumn = styled(H1_FlexColumn)<{ isHidden: boolean }>`
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: ${(props) => (props.isHidden ? 0 : 1)};
  transform: ${(props) => (props.isHidden ? "translateY(230px)" : "translateY(0)")};
`;

const AnimatedTexts = styled(H1_FlexColumn)<{ isHidden: boolean }>`
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: ${(props) => (props.isHidden ? 0 : 1)};
  transform: ${(props) => (props.isHidden ? "translateX(-100px)" : "translateX(0)")};
`;
const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background-color: ${(props) =>
    props.theme.mode === ThemeMode.Light ? props.theme.gray1 : props.theme.gray12};
`;

const VideoBackground = styled.video`
  position: absolute;
  width: 100%;
  transform: rotateY(180deg);
`;

const OpacTextHeadline = styled(H1_TextHeadline)`
  opacity: 0.8;
`;

const StyledSwiper = styled(NavigationSwiper)`
  position: static;
  overflow: hidden;
  padding-bottom: 5px;
  height: 180px;
  margin: 0 30px;
  .swiper-slide {
    width: calc(180px * 16 / 9);
  }
  .swiper-button-prev,
  .swiper-button-next {
    justify-content: center;
    top: 94px;
  }
  && .swiper-button-prev {
    left: 30px;
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
  }

  && .swiper-button-next {
    right: 15px;
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
  }
`;

const LiveFlexRow = styled(H1_FlexRow)`
  border-radius: 14px;
  border: 1px solid #3b3b3b;
  background-color: #3b3b3b;
  top: 10px;
  left: 10px;
  padding: 0 10px;
  opacity: 0.7;
`;

const ViewersRow = styled(H1_FlexRow)`
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 0 10px;
  border-radius: 10px;
  background-color: #3b3b3bcc;
`;
const SwiperContainerFlexColumn = styled(H1_FlexColumn)`
  position: relative;
  &:hover {
    ${ViewersRow} {
      opacity: 1;
      z-index: 2;
    }
  }
`;

const ThumbnailImage = styled.img`
  border-radius: 15px;
  width: calc(180px * 16 / 9);
  height: 175px;
  transition: all 0.3s ease-in-out;
  border: 1px solid #ccc;
  filter: brightness(90%);
  &:hover {
    cursor: pointer;
    filter: brightness(120%);
  }
`;

const RedIcon = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #f31260;
`;

const MOUSE_UNMOVE_TIME = 2000;
const TOP_HEIGHT = 80;
const BOTTOM_HEIGHT = 230;
const CGNPage = () => {
  const [videoMode, setVideoMode] = useState<LiveVideoMode>(LiveVideoMode.Player);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isStartingVideo, setIsStartingVideo] = useState<boolean>(true);
  const [isMuxPlayerAvailable, setIsMuxPlayerAvailable] = useState<boolean>(false);
  const [currentVodId, setCurrentVodId] = useState<string | undefined>(undefined);
  const [isHidden, setIsHidden] = useState(false);
  const [isLoadingFinished, setIsLoadingFinished] = useState<boolean>(false);
  const [activeLiveTab, setActiveLiveTab] = useState<ChannelsTabs | DEFIANCE>(ChannelsTabs.news);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const playerRef = useRef<MuxPlayerElement>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const vodId = searchParams.get("vod_id");
  const channelParam = searchParams.get("channel");

  const channels = useAppSelector((state) => state.cgn.channels);
  const streamersVod = useAppSelector((state) => state.cgn.streamersVod);
  const getCGNStatus = useAppSelector((state) => state.cgn.getCGNStatus);
  const getCGNStreamersVodStatus = useAppSelector((state) => state.cgn.getCGNStreamerVodStatus);
  const currentChannel = useAppSelector((state) =>
    state.cgn.channels.find((channel) => channel.name === activeLiveTab)
  );

  const cgnType = currentChannel?.type;
  const isCGNAsset = cgnType === CGNType.asset;
  const isCgnLoading = getCGNStatus === fetchingStatus.loading;
  const isVodLoading = getCGNStreamersVodStatus === fetchingStatus.loading;
  const isLoading = isVodLoading || isCgnLoading;
  const isVideoModePlayer = videoMode === LiveVideoMode.Player;
  const isVideoModeFullScreen = videoMode === LiveVideoMode.FullScreen;

  const streams = useMemo(() => [...channels, ...streamersVod], [channels, streamersVod]);

  const currentStream = useMemo(
    () => streams.find((stream) => currentVodId && stream.id && stream.id === currentVodId),
    [streams, currentVodId]
  );

  const currentViewPlaybackId = currentStream?.playback_id;

  const {
    videoLoaded,
    currentTime,
    duration,
    isPlaying,
    isMuted,
    isFullScreen,
    handleFullScreen,
    handleMuteUnmute,
    handlePlayPause
  } = useVideo({
    videoNode: playerRef.current as HTMLVideoElement,
    visible: isMuxPlayerAvailable,
    isAutoplay: true,
    isDefaultMuted: false,
    isDefaultFullScreen: false,
    containerNode: containerRef.current as HTMLDivElement,
    videoId: currentViewPlaybackId || currentChannel?.playback_id
  });

  useEffect(() => {
    dispatch(cgnActions.getCGNRequest());
    dispatch(cgnActions.getCGNStreamerVodRequest());
  }, []);

  useEffect(() => {
    if (channelParam) {
      setActiveLiveTab(channelParam as ChannelsTabs | DEFIANCE);
      setIsStartingVideo(false);
      setVideoMode(LiveVideoMode.FullScreen);
    }
  }, [channelParam]);
  useEffect(() => {
    if (vodId) {
      setCurrentVodId(vodId);
      setIsStartingVideo(false);
      setVideoMode(LiveVideoMode.FullScreen);
    }
  }, [vodId]);
  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setIsLoadingFinished(true);
      }, 500);
    }
  }, [isLoading]);

  // Because the isStartingVideo is conditional, the playerRef.current doesn't load on time, and the useVideo doesn't get the videoNode
  useEffect(() => {
    if (!isStartingVideo) {
      setTimeout(() => {
        setIsMuxPlayerAvailable(true);
      }, 100);
    }
  }, [isStartingVideo]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.code === "Space" && !isModalOpen) {
        if (playerRef.current) {
          const player = playerRef.current;
          if (player.paused) {
            player.play();
          } else {
            player.pause();
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [isModalOpen]);

  useEffect(() => {
    if (playerRef?.current) {
      if (currentViewPlaybackId) {
        const parsedChapters = currentStream.chapters.map((chapter) => {
          const { start_time, title } = chapter;
          return { startTime: start_time, value: title };
        });

        if (parsedChapters.length > 0) {
          // @ts-ignore - ignore endtime
          playerRef.current.addChapters(parsedChapters);
        }
      } else if (currentChannel) {
        const parsedChapters = currentChannel.chapters.map((chapter) => {
          const { start_time, title } = chapter;
          return { startTime: start_time, value: title };
        });

        if (parsedChapters.length > 0) {
          // @ts-ignore - ignore endtime
          playerRef.current.addChapters(parsedChapters);
        }
      }
    }
  }, [currentViewPlaybackId, currentChannel]);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (isVideoModePlayer) {
        return;
      }
      const { clientY } = event;
      const { innerHeight } = window;

      const isInZone =
        clientY <= TOP_HEIGHT ||
        (clientY >= innerHeight - BOTTOM_HEIGHT && clientY <= innerHeight - 20);

      if (isInZone) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
        setIsHidden(false);
      } else {
        if (!timeoutRef.current) {
          timeoutRef.current = setTimeout(() => {
            setIsHidden(true);
          }, MOUSE_UNMOVE_TIME);
        }
      }
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isVideoModePlayer]);

  const onPlayVideo = (name: ChannelsTabs, currId?: string) => {
    if (isVideoModePlayer) {
      if (playerRef.current) {
        playerRef.current.pause();
      }
      if (currId) {
        navigate(`${Location.Live}/${name}?vod_id=${currId}`);
      } else {
        navigate(`${Location.Live}/${name}`);
      }
    } else if (isVideoModeFullScreen) {
      setIsStartingVideo(false);
      if (currId) {
        setCurrentVodId(currId);
      } else {
        setCurrentVodId(undefined);
        setActiveLiveTab(name);
      }
    }
  };

  const onChangingTab = (name: ChannelsTabs | DEFIANCE) => {
    if (channelParam) {
      const updatedParams = new URLSearchParams(searchParams);
      updatedParams.delete("vod_id");
      updatedParams.delete("channel");
      setSearchParams(updatedParams);
    }
    setIsStartingVideo(false);
    setActiveLiveTab(name);
    setCurrentVodId(undefined);
  };

  const onChangeVideoMode = (videoMode: LiveVideoMode) => {
    setVideoMode(videoMode);
    if (!isStartingVideo && videoMode === LiveVideoMode.Player) {
      navigate(`${Location.Live}/${activeLiveTab}?vod_id=${currentVodId}`);
    }
  };

  return (
    <BackgroundFlexColumn
      overflow="hidden"
      position="relative"
      gap="28px"
      width="100%"
      height="100%"
    >
      <AnimatedHeader isHidden={isHidden} width="100%">
        <CGNHeader
          onChangeVideoMode={onChangeVideoMode}
          videoMode={videoMode}
          isMainPage
          activeTab={activeLiveTab}
          onChangeActiveTab={onChangingTab}
          setIsModalOpen={setIsModalOpen}
        />
      </AnimatedHeader>
      <ConditionalRender condition={isStartingVideo}>
        <VideoBackground
          src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/tvShow_gordon_v02.mp4"
          autoPlay
          loop
          muted
          playsInline
        />
      </ConditionalRender>
      <ConditionalRender condition={!isStartingVideo}>
        <MuxFlexColumn ref={containerRef} flex="1">
          <MuxPlayer
            shape="landscape"
            playbackId={
              currentViewPlaybackId ||
              currentChannel?.playback_id ||
              "f3uwVtnFPDHdGRrtR01RsfSUG4FO8ndEWTzvIGKdo80100"
            }
            streamType={isCGNAsset ? MuxStream.onDemand : MuxStream.live}
            overflow="hidden"
            loop
            autoPlay
            controls={false}
            ref={playerRef}
            centerPlayButton={true}
          />
          <ButtonsFlexColumn $isHidden={isHidden} gap="8px" position="absolute">
            <RoundButton isIconOnly startContent={<i className="fas fa-link" />} />
            <RoundButton isIconOnly startContent={<i className="fas fa-share" />} />
            <RoundButton isIconOnly startContent={<i className="fas fa-ellipsis" />} />
          </ButtonsFlexColumn>
          <CGNPlayerControls
            videoNode={playerRef.current as MuxPlayerElement}
            currentTime={currentTime}
            duration={duration}
            isPlaying={isPlaying}
            isMuted={isMuted}
            isFullScreen={isFullScreen}
            handleFullScreen={handleFullScreen}
            handleMuteUnmute={handleMuteUnmute}
            handlePlayPause={handlePlayPause}
            videoLoaded={videoLoaded}
          />
        </MuxFlexColumn>
      </ConditionalRender>
      <H1_FlexColumn margin="200px 0 0 30px" gap="20px" position="relative" flex="1 1 auto">
        <AnimatedTexts gap="28px" isHidden={isHidden}>
          <H1_FlexRow gap="10px">
            <H1_TextBanner fontSize="80px" lineHeight="80px" color="white">
              {isStartingVideo
                ? "Hour One"
                : (currentStream?.title || currentChannel?.title || "")
                    .split(" ")
                    .slice(0, -1)
                    .join(" ")}
            </H1_TextBanner>
            <H1_TextBanner fontSize="80px" lineHeight="80px" color="#ACCE3C">
              {isStartingVideo
                ? "Live"
                : (currentStream?.title || currentChannel?.title || "").split(" ").at(-1)}
            </H1_TextBanner>
          </H1_FlexRow>
          <OpacTextHeadline color="white">
            {isStartingVideo
              ? "Your fresh content is ready for you | We got everything"
              : getTimeAgo(currentStream?.created_at || currentChannel?.created_at)}
          </OpacTextHeadline>
        </AnimatedTexts>
      </H1_FlexColumn>
      <ConditionalRender condition={!isLoadingFinished}>
        <H1_FlexRow gap="28px" padding="0 0 60px 0">
          <StyledSkeletonButton $width="320px" $height="180px" active block $borderRadius="5px" />
          <StyledSkeletonButton $width="320px" $height="180px" active block $borderRadius="5px" />
          <StyledSkeletonButton $width="320px" $height="180px" active block $borderRadius="5px" />
          <StyledSkeletonButton $width="320px" $height="180px" active block $borderRadius="5px" />
        </H1_FlexRow>
      </ConditionalRender>
      <ConditionalRender condition={isLoadingFinished}>
        <AnimatedFlexColumn isHidden={isHidden}>
          <H1_FlexColumn flex="0 0 auto" padding="0 0 60px 0">
            <H1_FlexRow position="relative" margin="0 0 20px 30px">
              <H1_TextMiddle color="#ADADAD">Latest streams</H1_TextMiddle>
            </H1_FlexRow>
            <H1_FlexRow
              position="relative"
              ref={ref}
              padding="0 20px 0 0"
              width="100%"
              flex="1 0 auto"
            >
              <StyledSwiper
                allowTouchMove={false}
                navigation
                speed={700}
                spaceBetween={28}
                slidesPerView="auto"
                slidesPerGroup={4}
                lazy
              >
                {streams.map((stream: LiveStream) => (
                  <SwiperSlide key={stream.id || stream.playback_id}>
                    <SwiperContainerFlexColumn>
                      <ConditionalRender condition={!!stream.thumbnail}>
                        <ThumbnailImage
                          src={stream.thumbnail}
                          onClick={() => onPlayVideo(stream.name as ChannelsTabs, stream.id)}
                        ></ThumbnailImage>
                      </ConditionalRender>
                      <ConditionalRender condition={!stream.thumbnail}>
                        <H1_FlexRow position="relative">
                          <ThumbnailImage
                            onClick={() => onPlayVideo(stream.name as ChannelsTabs, stream.id)}
                            src={getTHumbnail(stream)}
                          />
                          <LiveFlexRow height="28px" position="absolute" gap="10px" align="center">
                            <RedIcon />
                            <H1_TextSmall fontSize="14px" color="white">
                              LIVE
                            </H1_TextSmall>
                          </LiveFlexRow>
                        </H1_FlexRow>
                      </ConditionalRender>
                      <ViewersRow>
                        <ConditionalRender condition={!!stream?.viewers}>
                          <H1_TextSmall color="white">{stream?.viewers} viewers</H1_TextSmall>
                        </ConditionalRender>
                      </ViewersRow>
                    </SwiperContainerFlexColumn>
                  </SwiperSlide>
                ))}
              </StyledSwiper>
            </H1_FlexRow>
          </H1_FlexColumn>
        </AnimatedFlexColumn>
      </ConditionalRender>
    </BackgroundFlexColumn>
  );
};

export default CGNPage;
