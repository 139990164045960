import { createAsyncThunk } from "@reduxjs/toolkit";
import { ChannelsTabs, DEFIANCE, LiveStream } from "app/types";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import {
  getPlayback,
  getStreamersVod,
  sendPublicLivePrompt,
  uploadStreamersVod
} from "app/services/serviceV2Apis";
import { parseISO, differenceInMilliseconds } from "date-fns";

const prefix = "[CGN]";

const getCGNRequest = createAsyncThunk<LiveStream[], void>(
  `${prefix} getCGNRequest`,
  async () => {
    const res = await getPlayback();
    return res;
  },
  thunkOptions
);

const getCGNStreamerVodRequest = createAsyncThunk<LiveStream[], void>(
  `${prefix} getCGNStreamerRequest`,
  async () => {
    let res = await getStreamersVod();
    res = res.map((streamer) => {
      if (streamer.created_at) {
        const createdAt = parseISO(streamer.created_at);
        const now = new Date();

        const timeDifference = differenceInMilliseconds(now, createdAt);
        const viewers = Math.floor(timeDifference / 100000);

        return { ...streamer, viewers };
      } else {
        return streamer;
      }
    });

    return res;
  },
  thunkOptions
);
const uploadVodRequest = createAsyncThunk<
  void,
  { url: string; channel: ChannelsTabs | DEFIANCE; title: string; orderId: string }
>(
  `${prefix} uploadVodRequest`,
  async ({ url, channel, title, orderId }) => {
    const res = await uploadStreamersVod({ url, channel, title, orderId });
    return res;
  },
  thunkOptions
);

const sendPublicLivePromptRequest = createAsyncThunk<
  void,
  { prompt: string; useCase: ChannelsTabs | DEFIANCE }
>(
  `${prefix} sendPublicLivePromptRequest`,
  async ({ prompt, useCase }) => {
    const res = await sendPublicLivePrompt(prompt, useCase);
    return res;
  },
  thunkOptions
);
export default {
  getCGNRequest,
  getCGNStreamerVodRequest,
  uploadVodRequest,
  sendPublicLivePromptRequest
};
