import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "app/hooks";
import ScenesSelectors from "app/store/selectorsV2/scenes.selectors";
import { checkUrlForImg } from "app/utils/helpers";
import { SequenceImage } from "app/types";
import { fallbackPlaceholder } from "app/assets/images/placeholder";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled from "styled-components";

const StyledVideo = styled.video<{ $width: number; $height: number; $aspectRatio: number }>`
  border-radius: 4px;
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio};
  width: ${({ $width }) => `${$width}px`};
  height: ${({ $height }) => `${$height}px`};
  z-index: 0;
`;
const StyledImage = styled.img<{ $aspectRatio: number }>`
  border-radius: 4px;
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  pointer-events: none;
  z-index: 0;
`;
const StyledProgressBarWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: rgba(114, 114, 114, 0.5);
  z-index: 1;
`;

const StyledProgressBar = styled.div`
  background-color: #4867ff;
  color: white;
  height: 5px;
`;

interface ProgressBarProps {
  sceneStart: number;
  sceneEnd: number;
  currentTime: number;
}

interface PlayerProps {
  url: string;
  width: number;
  height: number;
  aspectRatio: number;
}

const FPS = 30;

const ProgressBar = ({ sceneStart, sceneEnd, currentTime }: ProgressBarProps) => {
  const [progress, setProgress] = useState(0);
  const animationFrameId = useRef<number | null>(null);

  const updateProgress = () => {
    if (currentTime !== undefined) {
      const newProgress = Math.min(
        Math.max(((currentTime - sceneStart) / (sceneEnd - sceneStart)) * 100, 0),
        100
      );

      setProgress(newProgress);
    }

    // Continue the animation frame
    animationFrameId.current = requestAnimationFrame(updateProgress);
  };

  useEffect(() => {
    // Start the animation frame
    animationFrameId.current = requestAnimationFrame(updateProgress);
  }, [currentTime]);

  useEffect(() => {
    if (progress === 100) {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    }
  }, [progress]);

  return (
    <StyledProgressBarWrapper>
      <StyledProgressBar style={{ width: `${Math.min(Math.max(progress, 0), 100)}%` }} />
    </StyledProgressBarWrapper>
  );
};

const ScenesPlayer = ({ url, width, height, aspectRatio }: PlayerProps) => {
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [currentSceneIndex, setCurrentSceneIndex] = useState(0);
  const [scenesImagesWithPlaceholder, setScenesImagesWithPlaceholder] = useState<SequenceImage[]>(
    []
  );
  const videoRef = useRef<HTMLVideoElement>(null);
  const scenesImages = useAppSelector((state) =>
    ScenesSelectors.getScenesFramePreviewTiming(state, FPS)
  );

  useEffect(() => {
    (async () => {
      const urlResults = await Promise.all(
        scenesImages.map((sceneImage) => sceneImage.imageUrl && checkUrlForImg(sceneImage.imageUrl))
      );

      const result = scenesImages.map((sceneImage, index) => {
        if (urlResults[index]) {
          return sceneImage;
        } else {
          return { ...sceneImage, imageUrl: fallbackPlaceholder };
        }
      });
      setScenesImagesWithPlaceholder(result);
    })();
  }, [scenesImages]);

  useEffect(() => {
    if (videoRef.current) {
      const currentTimeInSeconds = videoRef.current.currentTime;
      const currentFrame = Math.floor(currentTimeInSeconds * FPS);

      // Determine the current scene based on the frame
      const newIndex = scenesImages.findIndex((sceneImage) => {
        return (
          currentFrame >= sceneImage.from &&
          currentFrame < sceneImage.from + sceneImage.durationInFrames
        );
      });

      if (newIndex !== -1 && newIndex !== currentSceneIndex) {
        setCurrentSceneIndex(newIndex);
      }
    }
  }, [currentTime]);

  const onTimeUpdate = (event: React.SyntheticEvent<HTMLVideoElement>) => {
    const videoElement = event.currentTarget;
    setCurrentTime(videoElement.currentTime);
  };

  if (!url) {
    return null;
  }
  return (
    <H1_FlexRow position="relative" width={`${width}px`} height={`${height}px`}>
      <StyledVideo
        ref={videoRef}
        $aspectRatio={aspectRatio}
        $width={width}
        $height={height}
        controls
        autoPlay
        src={url}
        onTimeUpdate={onTimeUpdate}
      />
      {scenesImagesWithPlaceholder[currentSceneIndex] && (
        <>
          <StyledImage
            $aspectRatio={aspectRatio}
            src={scenesImagesWithPlaceholder[currentSceneIndex].imageUrl}
          />
          <ProgressBar
            sceneStart={scenesImages[currentSceneIndex].from / FPS}
            sceneEnd={
              (scenesImages[currentSceneIndex].from +
                scenesImages[currentSceneIndex].durationInFrames) /
              FPS
            }
            currentTime={currentTime || 0}
          />
        </>
      )}
    </H1_FlexRow>
  );
};

export default ScenesPlayer;
