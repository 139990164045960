import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import styled from "styled-components";

import { MediaLibraryTabs, SelectedImageContext } from "app/types/media";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { Button, Input } from "@nextui-org/react";
import { fetchingStatus } from "app/utils/helpers";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";
import { v4 as uuidv4 } from "uuid";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import useMediaLibraryUploader from "app/pages/mediaLibrary/useMediaLibraryUploader";
import { FoldersContext } from "app/types";

const GenerateButton = styled(Button)`
  min-width: 150px;
`;
const AddButton = styled(Button)`
  min-width: 150px;
  position: absolute;
  bottom: 0px;
  z-index: 333;
  right: 0px;
`;

const ImagePreview = styled.div`
  width: 300px;
  max-width: 512px;
  aspect-ratio: 1;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export interface MediaLibraryTabSDGenerateProps {
  onImageSelect: (value: string, context?: SelectedImageContext) => void;
  selectedUrl?: string;
  onTabChange: (value: string) => void;
}

const MediaGeneratorTab = ({ onImageSelect, onTabChange }: MediaLibraryTabSDGenerateProps) => {
  const [prompt, setPrompt] = useState<string>("");
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { importLoading, storeUrl, uploadedMedia, resetUploader } = useMediaLibraryUploader({
    context: FoldersContext.media
  });
  const mediaGenerateStatus = useAppSelector((state) => state.media.mediaGeneratorStatus);
  const mediaGeneratorResult = useAppSelector((state) => state.media.mediaGeneratorResult);
  const imageResultUrl = mediaGeneratorResult?.images?.[0]?.url;

  const loading = mediaGenerateStatus === fetchingStatus.loading;

  const onChangePromptValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(e.target.value);
  };
  const handleGenerate = () => {
    dispatch(mediaActions.mediaGeneratorRequest({ prompt, orderId: uuidv4() }));
  };
  const onSaveInMedia = () => {
    setUploadLoading(true);
    storeUrl(imageResultUrl as string);
  };

  useEffect(() => {
    (async () => {
      if (uploadedMedia) {
        await dispatch(
          mediaActions.createMediaBulkRequest({
            bulk: [
              {
                url: uploadedMedia.url,
                handle: uploadedMedia.handle
              }
            ],
            context: FoldersContext.media
          })
        );
        onImageSelect(uploadedMedia.url, { url: uploadedMedia.url, handle: uploadedMedia.handle });
        setUploadLoading(false);
        resetUploader();
        onTabChange(MediaLibraryTabs.MyMedia);
      }
    })();
  }, [uploadedMedia]);

  return (
    <H1_FlexColumn
      flex="1 1 auto"
      overflow="hidden"
      gap="33px"
      height="100%"
      justify="flex-start"
      align="center"
      padding="24px"
    >
      <H1_FlexColumn gap="16px" width="100%">
        <Input
          size="lg"
          placeholder="Describe the image you want to generate..."
          value={prompt}
          onChange={onChangePromptValue}
          fullWidth
          disabled={loading}
        />
        <H1_FlexColumn flex={"1 0 auto"} height={"100px"} width={"150px"} alignSelf={"center"}>
          <GenerateButton
            color="primary"
            onClick={handleGenerate}
            disabled={!prompt.trim() || loading}
            isLoading={loading}
          >
            {imageResultUrl ? "Regenerate" : "Generate"}
          </GenerateButton>
        </H1_FlexColumn>
      </H1_FlexColumn>

      {imageResultUrl && (
        <H1_FlexRow position={"relative"}>
          <AddButton
            color="primary"
            onClick={onSaveInMedia}
            disabled={!prompt.trim() || loading}
            isLoading={importLoading || uploadLoading}
            size={"sm"}
          >
            {"Save"}
          </AddButton>
          <ImagePreview>
            <img src={imageResultUrl} alt={"Generated"} />
          </ImagePreview>
        </H1_FlexRow>
      )}
    </H1_FlexColumn>
  );
};

export default MediaGeneratorTab;
