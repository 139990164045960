import { createSelector } from "reselect";
import { RootState } from "app/store/store";
import { ChannelsTabs, DEFIANCE } from "app/types";

const streamersVod = (state: RootState) => state.cgn.streamersVod;

export const getStreamersVodByName = createSelector(
  [streamersVod, (state, name?: ChannelsTabs | DEFIANCE) => name],
  (streamersVod, name) => {
    return streamersVod.filter((streamer) => streamer.name === name);
  }
);
