import React from "react";
import styled, { useTheme } from "styled-components";
import { Progress, Tooltip } from "antd";
import MuxPlayer from "app/components/common/player/MuxPlayer";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextSmall,
  H1_TextSubBanner,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import { Button } from "@nextui-org/react";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import useModal, { ModalName } from "app/hooks/useModal";
import { playgroundActions } from "app/store/slices/playground.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { playgroundGlobalSelectors } from "app/store/adapters/adapters";
import { PlaygroundStatus } from "app/types/playground";
import AvatarInitials from "app/components/common/AvatarInitials";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { useAuth } from "app/auth/useAuth";
import { format } from "date-fns";
import { FeatureFlag } from "app/types";
import { useFlags } from "launchdarkly-react-client-sdk";
import useCopy from "app/hooks/useCopy";

const WhiteButton = styled(Button)`
  span {
    color: white;
  }
  & {
    background-color: #343445;
    color: white;
  }
  min-width: 39px;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  &:hover {
    background-color: #343445;
  }
  &:disabled {
    color: #595959;
    span {
      color: #595959;
    }
  }
`;
const PlayerContainer = styled(H1_FlexColumn)<{ $showBackground: boolean }>`
  border-radius: 3px;
  padding: ${({ $showBackground }) => ($showBackground ? "20px" : "unset")};
  background: ${({ $showBackground }) => ($showBackground ? "#27272A" : "unset")};
  color: white;
  > div:not(.ant-progress) {
    height: 100%;
    > div {
      height: 100%;
      > div:not(.informative-div) {
        height: 100%;
      }
    }
  }
  max-height: 550px;
  max-width: calc(550px * 16 / 9);
  &&& mux-player {
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
  }
`;

const AbsoluteFlexRow = styled(H1_FlexRow)`
  position: absolute;
  top: 10px;
  left: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  button {
    pointer-events: auto;
  }
`;

const ContainerFlexColumn = styled(H1_FlexColumn)`
  &:hover {
    ${AbsoluteFlexRow} {
      opacity: 1;
    }
  }
`;

interface VideoPlayerProps {
  progress?: number;
  loading?: boolean;
  playbackId?: string;
  videoId: string;
}
const VideoPlayer = ({ videoId, playbackId, loading, progress }: VideoPlayerProps) => {
  const theme = useTheme();
  const { user } = useAuth();
  const { openModal } = useModal();
  const dispatch = useAppDispatch();
  const { triggerCopy } = useCopy();
  const flags = useFlags();
  const video = useAppSelector((state) => playgroundGlobalSelectors.selectById(state, videoId));
  const currentTime = format(new Date(video?.created_at || Date.now()), "Pp");

  const renderingLoading =
    video?.status === PlaygroundStatus.started ||
    video?.status === PlaygroundStatus.processing ||
    video?.status === PlaygroundStatus.submitted_preprocessing;

  const onCopyLink = () => {
    dispatch(googleEvents.playgroundCopyLinkIntent({ videoId }));
    openModal(ModalName.playgroundPublishVideo, { videoId });
  };
  const onDownload = () => {
    dispatch(googleEvents.playgroundDownloadIntent({ videoId }));
    dispatch(playgroundActions.playgroundDownloadVideoRequest({ videoId: videoId as string }));
  };

  if (!video) {
    return null;
  }

  return (
    <PlayerContainer
      height="100%"
      width="100%"
      justify={"center"}
      align={"center"}
      $showBackground={!playbackId}
    >
      <ConditionalRender condition={loading}>
        <H1_FlexColumn flex={"1"} justify={"center"} align={"center"}>
          <H1_TextSubBanner fontWeight={700} margin="0 0 13px 0" color={theme.gray5}>
            {progress}%
          </H1_TextSubBanner>
          <H1_TextSmall color={theme.gray5}>Rendering your video</H1_TextSmall>
          <H1_TextXs color={theme.gray5}>Hang in there, this may take a few minutes</H1_TextXs>
        </H1_FlexColumn>
      </ConditionalRender>
      <ConditionalRender condition={!!playbackId}>
        <ContainerFlexColumn position="relative" width="100%">
          <MuxPlayer shape={"landscape"} playbackId={playbackId} />
          <AbsoluteFlexRow width="calc(100% - 20px)" justify="space-between">
            <H1_FlexRow align="center" gap="6px" className="informative-div" height="40px">
              <AvatarInitials src={user?.picture} text={user?.nickname || ""} size="md" />
              <H1_FlexColumn height="40px">
                <H1_TextSmall color="white">{video?.title}</H1_TextSmall>
                <H1_TextXs color="white">{currentTime}</H1_TextXs>
              </H1_FlexColumn>
            </H1_FlexRow>
            <H1_FlexColumn className="informative-div" gap="10px" align="center">
              <WhiteButton
                isDisabled={renderingLoading}
                isIconOnly
                variant="light"
                startContent={<i className="far fa-link" />}
                onClick={onCopyLink}
              />
              {/*<PlaygroundShareVideoMenu*/}
              {/*  disabled={renderingLoading}*/}
              {/*  loading={isShareVideoStatusLoading}*/}
              {/*  withText={false}*/}
              {/*  video={{ id: video.id, req_id: video.req_id, status: video.status } as Video}*/}
              {/*  source={PLAYGROUND_SOURCE}*/}
              {/*/>*/}
              <WhiteButton
                isDisabled={renderingLoading}
                isIconOnly
                variant="light"
                startContent={<i className="far fa-arrow-down-to-line" />}
                onClick={onDownload}
              />
              <ConditionalRender condition={flags[FeatureFlag.playgroundReqId]}>
                <Tooltip title={video?.req_id as string} placement="right">
                  <>
                    <WhiteButton
                      onClick={() => triggerCopy({ copyContent: video?.req_id as string })}
                      isIconOnly
                      variant="light"
                      startContent={<i className="fa-regular fa-info-circle" />}
                    />
                  </>
                </Tooltip>
              </ConditionalRender>
            </H1_FlexColumn>
          </AbsoluteFlexRow>
        </ContainerFlexColumn>
      </ConditionalRender>
      <ConditionalRender condition={loading}>
        <Progress percent={progress} showInfo={false} />
      </ConditionalRender>
    </PlayerContainer>
  );
};

export default VideoPlayer;
