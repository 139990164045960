import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled, { css, useTheme } from "styled-components";
import React, { useEffect, useMemo, useState } from "react";
import Cropper, { Area } from "react-easy-crop";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { Collapse, Popover, Slider, Tooltip } from "antd";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import { Point } from "react-easy-crop/types";
import {
  sceneDrawerMessages,
  videoActionsMessages
} from "app/components/editor/sideDrawers/SceneDrawer/messages";
import { useIntl } from "react-intl";
import { VideoActionButton } from "app/components/editor/sideDrawers/SceneDrawer/VideoActionButton";
import { VideoFit } from "app/types/media";
import ConditionalRender from "app/components/common/ConditionalRender";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { useAppSelector } from "app/hooks";
import templatesSelectors from "app/store/selectorsV2/templates.selectors";
import { PaletteColor, PaletteColorKey } from "app/types";
import { customColorPickerMessages } from "app/components/editor/sideDrawers/StyleDrawer/messages";
import { Button } from "@nextui-org/react";

const ReactCropFlexColumn = styled(H1_FlexColumn)`
  align-self: center;
  z-index: 3;
  background-color: ${(props: { $background?: string }) =>
    props.$background ? props.$background : ""};
`;

const LoadingFlexRow = styled(H1_FlexRow)`
  top: 0;
  left: 0;
  z-index: 4;
  background-color: white;
`;

const StyledSlider = styled(Slider)`
  width: 100%;
  .ant-slider-rail {
    background: rgba(160, 160, 160, 0.5);
  }
  && .ant-slider-handle:focus {
    box-shadow: none;
  }
  && .ant-slider-track {
    background-color: rgb(81, 81, 81);
    &&:hover {
      background-color: rgb(81, 81, 81);
    }
  }
  && .ant-slider-handle:focus,
  && .ant-slider-handle {
    background-color: rgb(81, 81, 81);
    border-color: rgb(81, 81, 81);
    &&:hover {
      border-color: rgb(81, 81, 81);
    }
  }
`;

const CustomCollapse = styled(Collapse)`
  &.ant-collapse {
    width: 231px;
    background-color: white;
    border: none;
    .ant-collapse-content {
      border: none;
    }
    .ant-collapse-content-box {
      padding-left: 0;
      padding-top: 0;
    }
    .ant-collapse-item {
      border: none;
      .ant-collapse-header {
        justify-content: space-between;
        position: relative;
        padding: 0 0 12px 0;
        .ant-collapse-expand-icon {
          right: 0;
          position: absolute;
        }
      }
    }

    .ant-collapse-header-text {
      color: ${({ theme }) => theme.gray7};
      font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
      font-weight: 600;
      font-size: 12px;
    }
  }
  &.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    padding: 0;
  }
`;

const ColorCircle = styled(H1_FlexRow)<{ $color: string; $selected: boolean }>`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
  transition: all 0.3s ease-in-out;
  &:hover {
    border-color: white;
    box-shadow: 0 0 12px 0 rgba(0, 0, 255, 0.15);
  }
  border: 2px ${(props) => props.theme.gray3} solid;
  ${({ $selected }) =>
    $selected &&
    css`
      filter: drop-shadow(0px 0px 4px #aaf);
    `};
`;

const ColorDot = styled(H1_FlexRow)<{ $color?: string }>`
  background-color: ${({ $color }) => $color};
  border-radius: 50%;
  cursor: pointer;
  border: 2px ${(props) => props.theme.gray3} solid;
  position: relative;
  filter: drop-shadow(0px 0px 4px #aaf);
`;

interface VideoCropProps {
  src: string;
  aspectRatio: number;
  onClickApplyCrop: (currentVideoFit: VideoFit, crop: Point, cropArea: Area, scale: number) => void;
  onClickCancel: (initialVideoFit?: VideoFit) => void;
  onUpdateCurrentVideoFit: (videoFit: VideoFit) => void;
  onChangePadColor: (color: PaletteColor) => void;
  padColor?: PaletteColor;
  initialCrop: Point;
  initialCropArea: Area;
  initialScale: number;
  videoFit: VideoFit;
}
const VideoCrop = ({
  src,
  aspectRatio,
  onClickApplyCrop,
  onClickCancel,
  onUpdateCurrentVideoFit,
  onChangePadColor,
  padColor,
  initialCrop,
  initialCropArea,
  initialScale,
  videoFit
}: VideoCropProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [initialVideoFit] = useState<VideoFit>(videoFit);
  const [crop, setCrop] = useState<Point>({ x: initialCrop.x, y: initialCrop.y });
  const [cropArea, setCropArea] = useState<Area>(initialCropArea);
  const [zoom, setZoom] = useState(initialScale);
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const appliedPalette = useAppSelector(templatesSelectors.getAppliedPalette);
  const zoomNumber = useMemo(() => Math.round(zoom * 100).toFixed(), [zoom]);

  const isVideoFitScale = videoFit === VideoFit.Scale;
  const isVideoFitCrop = videoFit === VideoFit.Crop;
  const isVideoFitPad = videoFit === VideoFit.Pad;

  const currentPadColor = useMemo(() => {
    if (!appliedPalette) {
      return undefined;
    }
    if (padColor) {
      const color = appliedPalette.find((paletteColor) => paletteColor.key === padColor.key);

      return color;
    } else {
      const color = appliedPalette.find(
        (paletteColor) => paletteColor.key === PaletteColorKey.color_A1
      );
      if (color) {
        onChangePadColor(color);
      }
      return color;
    }
  }, [padColor, appliedPalette]);

  useEffect(() => {
    if (videoFit === VideoFit.Crop) {
      setIsLoading(true);
    }
  }, [videoFit]);

  const onCropComplete = (newCropArea: Area, croppedAreaPixels: Area) => {
    setCropArea(croppedAreaPixels);
  };

  const onCropChange = (currCrop: Point) => {
    if (!isLoading) {
      setCrop(currCrop);
    }
  };
  const onClickApply = () => {
    onClickApplyCrop(videoFit, crop, cropArea, zoom);
  };

  const onSelectCrop = () => {
    onUpdateCurrentVideoFit(VideoFit.Crop);
  };

  const onSelectScale = () => {
    onUpdateCurrentVideoFit(VideoFit.Scale);
    setZoom(1);
    setCrop({ x: 0, y: 0 });
  };
  const onSelectPad = () => {
    onUpdateCurrentVideoFit(VideoFit.Pad);
  };

  const colorsPopoverContent = () => {
    const colorTooltip = (color: PaletteColor) => {
      switch (color.key) {
        case PaletteColorKey.color_A1:
          return formatMessage(customColorPickerMessages.a1Color);
        case PaletteColorKey.color_A2:
          return formatMessage(customColorPickerMessages.a2Color);
        case PaletteColorKey.color_A3:
          return formatMessage(customColorPickerMessages.a3Color);
        case PaletteColorKey.color_W:
          return formatMessage(customColorPickerMessages.wColor);
        case PaletteColorKey.color_B:
          return formatMessage(customColorPickerMessages.bColor);
        default:
          return "";
      }
    };

    return (
      <H1_FlexColumn padding="8px 4px" gap="10px">
        <CustomCollapse accordion defaultActiveKey={["1"]}>
          <Collapse.Panel
            header={formatMessage(sceneDrawerMessages.background)}
            key="1"
            showArrow={false}
            collapsible="disabled"
          >
            <H1_FlexRow padding="10px 0 0 0" gap="10px" align="center">
              {appliedPalette?.map((paletteColor: PaletteColor) => (
                <Tooltip title={colorTooltip(paletteColor)} key={paletteColor.key}>
                  <ColorCircle
                    onClick={() => onChangePadColor(paletteColor)}
                    $color={paletteColor.color}
                    $selected={currentPadColor?.key === paletteColor.key}
                  />
                </Tooltip>
              ))}
            </H1_FlexRow>
          </Collapse.Panel>
        </CustomCollapse>
      </H1_FlexColumn>
    );
  };

  return (
    <H1_FlexColumn gap="18px" height="100%" justify="space-between">
      <H1_FlexColumn flex="1 0 auto" width="100%" gap="20px">
        <ConditionalRender condition={isVideoFitCrop}>
          <ReactCropFlexColumn
            justify="center"
            align="center"
            position="relative"
            height="460px"
            width={aspectRatio > 1.2 ? "560px" : "calc(460px * 16 / 9)"}
            $background={theme.gray2}
          >
            <Cropper
              video={src}
              crop={crop}
              zoom={zoom}
              aspect={aspectRatio}
              onCropChange={onCropChange}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              onMediaLoaded={() => setIsLoading(false)}
            />
            <ConditionalRender condition={isLoading}>
              <LoadingFlexRow width="100%" height="100%" position="absolute">
                <CircleLoader />
              </LoadingFlexRow>
            </ConditionalRender>
          </ReactCropFlexColumn>
        </ConditionalRender>
        <H1_FlexRow
          gap="20px"
          justify={isVideoFitPad ? "flex-start" : "space-between"}
          align="center"
        >
          <H1_FlexRow gap="9px" align="center">
            <VideoActionButton
              startContent={<i className="far fa-crop" />}
              onClick={onSelectCrop}
              $selected={isVideoFitCrop}
            >
              {formatMessage(videoActionsMessages.crop)}
            </VideoActionButton>
            <VideoActionButton
              startContent={<i className="far fa-expand" />}
              onClick={onSelectScale}
              $selected={isVideoFitScale}
            >
              {formatMessage(videoActionsMessages.scale)}
            </VideoActionButton>
            <VideoActionButton
              startContent={<i className="far fa-arrows-maximize" />}
              onClick={onSelectPad}
              $selected={isVideoFitPad}
            >
              {formatMessage(videoActionsMessages.pad)}
            </VideoActionButton>
          </H1_FlexRow>
          <ConditionalRender condition={isVideoFitCrop}>
            <H1_FlexRow margin="0 0 0 30px" gap="20px" align="center" flex="1">
              <H1_TextMiddle>{formatMessage(videoActionsMessages.zoom)}</H1_TextMiddle>
              <StyledSlider
                tooltip={{ open: false }}
                min={1}
                max={3}
                onChange={setZoom}
                value={zoom}
                step={0.1}
              />
              <H1_TextMiddle>{zoomNumber}%</H1_TextMiddle>
            </H1_FlexRow>
          </ConditionalRender>
          <ConditionalRender condition={isVideoFitPad}>
            <Tooltip title={formatMessage(videoActionsMessages.paddingColor)}>
              <Popover
                trigger={["click"]}
                placement="left"
                align={{ offset: [0, -50] }}
                showArrow={false}
                content={colorsPopoverContent()}
              >
                <ColorDot
                  margin="0 0 0 10px"
                  flex="0 0 15px"
                  width="15px"
                  height="15px"
                  $color={currentPadColor?.color}
                />
              </Popover>
            </Tooltip>
          </ConditionalRender>
        </H1_FlexRow>
      </H1_FlexColumn>
      <H1_FlexColumn flex="0 0 38px">
        <H1_FlexRow gap="20px" alignSelf="flex-end">
          <Button onClick={() => onClickCancel(initialVideoFit)} variant="light">
            {formatMessage(videoActionsMessages.cancelBtn)}
          </Button>
          <Button color="primary" disabled={isVideoFitPad && !padColor} onClick={onClickApply}>
            {formatMessage(videoActionsMessages.applyBtn)}
          </Button>
        </H1_FlexRow>
      </H1_FlexColumn>
    </H1_FlexColumn>
  );
};

export default VideoCrop;
