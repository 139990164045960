import { Gender } from "app/types/voice";

export enum PlaygroundStatus {
  submitted_preprocessing = "submitted_preprocessing",
  processing = "processing",
  started = "started",
  ready = "ready",
  published = "published",
  failed = "failed"
}

export interface PlaygroundMedia {
  id: string;
  name: string;
  image: string;
  url: string;
  gender?: Gender;
}
export interface PlayGroundResponse {
  id: string;
  req_id?: string;
  order_id: string;
  voice_id?: string;
  source_video?: string;
  voice_url?: string;
  character_id?: string;
  transcript?: string;
  status?: PlaygroundStatus;
  progress?: number;
  playback_id?: string;
  created_at: string;
  title?: string;
}

export interface PlaygroundModel {
  name: string;
}
export interface PlayGroundDownloadResponse {
  status: "ready" | "processing";
  url?: string;
}

export enum DubbingRequestSourceTypes {
  file = "file",
  url = "url"
}

export enum VideoRequestType {
  avatar = "avatar",
  source_video = "source_video",
  portrait = "portrait"
}

export interface PlayGroundRequest {
  order_id: string;
  voice_id?: string;
  source_video?: string;
  voice_url?: string;
  character_id?: string;
  transcript?: string;
  title?: string;
  dubbing?: {
    source_type: DubbingRequestSourceTypes;
    source_lang: DubbingLanguages | null;
    target_lang: DubbingLanguages;
  };
  source_image_url?: string;
  driving_video_url?: string;
  apply_super_res?: boolean;
  lively?: boolean;
  model_id?: string;
  type?: VideoRequestType;
}

export enum PlaygroundScriptTab {
  script = "script",
  audio = "audio"
}

export enum PlaygroundVideoTypeTabs {
  AI_Video = "Ai_Video",
  Speaking_Video = "Speaking_Video",
  Translate = "Translate",
  Model = "Model",
  Lively = "Lively",
  Live_Portrait = "Live_Portrait"
}

export interface PreloadedVideo {
  url: string;
  thumbnail: string;
  preview: string;
}

export const DubbingLanguagesFull = {
  en: {
    flag: "us",
    language: "English"
  },
  hi: {
    flag: "in",
    language: "Hindi"
  },
  pt: {
    flag: "pt",
    language: "Portuguese"
  },
  zh: {
    flag: "cn",
    language: "Mandarin"
  },
  es: {
    flag: "es",
    language: "Spanish"
  },
  fr: {
    flag: "fr",
    language: "French"
  },
  de: {
    flag: "de",
    language: "German"
  },
  ja: {
    flag: "jp",
    language: "Japanese"
  },
  ar: {
    flag: "sa",
    language: "Arabic"
  },
  ru: {
    flag: "ru",
    language: "Russian"
  },
  ko: {
    flag: "kr",
    language: "Korean"
  },
  id: {
    flag: "id",
    language: "Indonesian"
  },
  it: {
    flag: "it",
    language: "Italian"
  },
  nl: {
    flag: "nl",
    language: "Dutch"
  },
  tr: {
    flag: "tr",
    language: "Turkish"
  },
  pl: {
    flag: "pl",
    language: "Polish"
  },
  sv: {
    flag: "se",
    language: "Swedish"
  },
  fil: {
    flag: "ph",
    language: "Filipino"
  },
  ms: {
    flag: "my",
    language: "Malay"
  },
  ro: {
    flag: "ro",
    language: "Romanian"
  },
  uk: {
    flag: "ua",
    language: "Ukrainian"
  },
  el: {
    flag: "gr",
    language: "Greek"
  },
  cs: {
    flag: "cz",
    language: "Czech"
  },
  da: {
    flag: "dk",
    language: "Danish"
  },
  fi: {
    flag: "fi",
    language: "Finnish"
  },
  bg: {
    flag: "bg",
    language: "Bulgarian"
  },
  hr: {
    flag: "hr",
    language: "Croatian"
  },
  sk: {
    flag: "sk",
    language: "Slovak"
  },
  ta: {
    flag: "in",
    language: "Tamil"
  }
};

export const AUTO_DETECT = "auto";
export enum DubbingLanguages {
  ENGLISH = "en",
  HINDI = "hi",
  PORTUGUESE = "pt",
  CHINESE = "zh",
  SPANISH = "es",
  FRENCH = "fr",
  GERMAN = "de",
  JAPANESE = "ja",
  ARABIC = "ar",
  RUSSIAN = "ru",
  KOREAN = "ko",
  INDONESIAN = "id",
  ITALIAN = "it",
  DUTCH = "nl",
  TURKISH = "tr",
  POLISH = "pl",
  SWEDISH = "sv",
  FILIPINO = "fil",
  MALAY = "ms",
  ROMANIAN = "ro",
  UKRAINIAN = "uk",
  GREEK = "el",
  CZECH = "cs",
  DANISH = "da",
  FINNISH = "fi",
  BULGARIAN = "bg",
  CROATIAN = "hr",
  SLOVAK = "sk",
  TAMIL = "ta"
}
