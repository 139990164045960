/* eslint react/prop-types: 0 */
import React from "react";
import { useRefCallback } from "app/components/_Infrastructure/core/use-ref-callback";
import type MuxPlayerElement from "@mux/mux-player";
import styled, { css } from "styled-components";
import { configs } from "./h1-mux-player-configs";
import { LazyH1MuxPlayer } from "./h1-mux-player-lazy";
import { DefaultH1MuxPlayer } from "./h1-mux-default-player";
import { H1MuxPlayerInnerProps } from "./types";

const MuxWrapper = styled.div<{
  $ratio: string;
  $circle: boolean;
  $controls: boolean;
  $centerPlayButton: boolean;
}>`
  mux-player {
    cursor: pointer;
    aspect-ratio: ${(props) => props.$ratio};
    --seek-backward-button: none;
    --seek-forward-button: none;
    --seek-live-button: none;
    --media-control-background: rgba(0, 0, 0, 0.4);
    ${(props) =>
      props.$circle &&
      css`
        clip-path: circle(48%);
        --captions-button: none;
        --airplay-button: none;
        --pip-button: none;
        --fullscreen-button: none;
        --cast-button: none;
        --playback-rate-button: none;
        --volume-range: none;
        --duration-display: none;
        --mute-button: none;
        --time-range: none;
        --time-display: none;
        --duration-display: none;
        --bottom-play-button: none;
        --media-object-position: 50%;
        --media-object-fit: cover;
      `}
    ${(props) =>
      !props.$centerPlayButton &&
      css`
        --center-controls: none;
      `}
    ${(props) =>
      !props.$controls &&
      css`
        --duration-display: none;
        --mute-button: none;
        --airplay-button: none;
        --pip-button: none;
        --fullscreen-button: none;
        --cast-button: none;
        --playback-rate-button: none;
        --volume-range: none;
        --time-range: none;
        --time-display: none;
        --duration-display: none;
        --bottom-play-button: none;
      `}
  }
`;

export interface H1MuxPlayerProps extends H1MuxPlayerInnerProps {
  /**
   * Show/hide all controls
   */
  controls?: boolean;

  /**
   * Posible configuration packs for player
   */
  shape?: "portrait" | "circle" | "landscape";

  /**
   * Display center play button
   */
  centerPlayButton?: boolean;

  /**
   * Use mux's lazy load option for SSR
   */
  lazyLoad?: boolean;

  /**
   * Trigger function on video ref mount
   */
  onMount?: (node: MuxPlayerElement) => void;

  /**
   * Trigger function on video ref unmount
   */
  onUnmount?: (node: MuxPlayerElement) => void;
  onTimeUpdate?: (event: any) => void;
}

export const H1MuxPlayer = React.forwardRef<MuxPlayerElement, H1MuxPlayerProps>(
  (
    {
      playbackId,
      metadata,
      muxEnvKey,
      placeholder,
      muxDebug = false,
      controls = true,
      shape = "landscape",
      autoPlay = false,
      loop = false,
      muted = false,
      centerPlayButton = true,
      defaultShowCaptions = false,
      lazyLoad = false,
      onUnmount = () => null,
      onMount = () => null,
      onTimeUpdate = () => null,
      ...rest
    },
    ref
  ) => {
    const config = configs[shape];
    const muxRef = useRefCallback({
      ref: ref === null ? undefined : (ref as React.MutableRefObject<MuxPlayerElement>),
      onMount,
      onUnmount,
      debug: muxDebug
    });
    return (
      <MuxWrapper
        $ratio={config.ratio}
        $circle={shape === "circle"}
        $controls={controls}
        $centerPlayButton={centerPlayButton}
      >
        {lazyLoad ? (
          <LazyH1MuxPlayer
            {...rest}
            ref={muxRef}
            playbackId={playbackId}
            muxEnvKey={muxEnvKey}
            muted={muted}
            muxDebug={muxDebug}
            autoPlay={autoPlay}
            defaultShowCaptions={defaultShowCaptions}
            metadata={metadata}
            loop={loop}
            placeholder={placeholder}
          />
        ) : (
          <DefaultH1MuxPlayer
            {...rest}
            ref={muxRef}
            playbackId={playbackId}
            muxEnvKey={muxEnvKey}
            muted={muted}
            muxDebug={muxDebug}
            autoPlay={autoPlay}
            defaultShowCaptions={defaultShowCaptions}
            metadata={metadata}
            loop={loop}
            placeholder={placeholder}
            onTimeUpdate={onTimeUpdate}
          />
        )}
      </MuxWrapper>
    );
  }
);

H1MuxPlayer.displayName = "H1MuxPlayer";
