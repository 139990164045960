/* eslint-disable max-len,no-template-curly-in-string */
import { defineMessages } from "react-intl";

export const pricingPlansMessages = defineMessages({
  planBasic: {
    id: `pricing.plans-messages.header-basic`,
    defaultMessage: `BASIC`
  },
  planBasicDisplayName: {
    id: `pricing.plans-messages.header-basic-display-name`,
    defaultMessage: `Lite`
  },
  planAdvanced: {
    id: `pricing.plans-messages.header-advanced`,
    defaultMessage: `ADVANCED`
  },
  planEnterprise: {
    id: `pricing.plans-messages.header-enterprise`,
    defaultMessage: `ENTERPRISE`
  },
  planBasicDescription: {
    id: `pricing.plans-messages.basic-description`,
    defaultMessage: `Video booster kit for {br} personal communication`
  },
  planAdvancedDescription: {
    id: `pricing.plans-messages.advanced-description`,
    defaultMessage: `The complete video suite {br} to scale video production {br} for your business`
  },
  planEnterpriseDescription: {
    id: `pricing.plans-messages.enterprise-description`,
    defaultMessage: `Fully tailored solution to {br} scale your business video {br} communication`
  },
  priceDescriptionBasic: {
    id: `pricing.plans-messages.price-description-basic`,
    defaultMessage: `Annual plan {br} billed monthly`
  },
  basicAdditionInfo1: {
    id: `pricing.plans-messages.basic-additional-info-1`,
    defaultMessage: "18 minutes"
  },
  basicAdditionInfo2: {
    id: `pricing.plans-messages.basic-additional-info-2`,
    defaultMessage: "Published video <u>per year</u>"
  },
  priceDescriptionAdvanced: {
    id: `pricing.plans-messages.price-description-advanced`,
    defaultMessage: `Annual plan {br} billed monthly`
  },
  advancedAdditionInfo1: {
    id: `pricing.plans-messages.advanced-additional-info-1`,
    defaultMessage: "60 minutes"
  },
  advancedAdditionInfo2: {
    id: `pricing.plans-messages.advanced-additional-info-2`,
    defaultMessage: "Published video <u>per year</u>"
  },
  priceDescriptionEnterprise: {
    id: `pricing.plans-messages.price-description-enterprise`,
    defaultMessage: `Professional {br} service plan`
  },
  priceEnterpriseCustomized: {
    id: `pricing.plans-messages.price-enterprise`,
    defaultMessage: `Customized`
  }
});

export const pricingPlansFeatures = defineMessages({
  fullyCustomized: {
    id: `pricing.plans-features.fully-customized`,
    defaultMessage: "Fully customized"
  },
  alsoViaApi: {
    id: `pricing.plans-features.also-via-api`,
    defaultMessage: "Also via API"
  },
  realHumanVoices: {
    id: `pricing.plans-features.real-human-voices`,
    defaultMessage: "Real human voices"
  },
  unlimited: {
    id: `pricing.plans-features.unlimited`,
    defaultMessage: "Unlimited"
  },
  paidAddOn: {
    id: `pricing.plans-features.paid-add-on`,
    defaultMessage: "Paid add-on (Get Quote)"
  },
  dedicated: {
    id: `pricing.plans-features.dedicated`,
    defaultMessage: "Dedicated"
  },
  templates3d: {
    id: `pricing.plans-features.templates-3d`,
    defaultMessage: "3D templates"
  },
  templates2dOnly: {
    id: `pricing.plans-features.templates-2d-only`,
    defaultMessage: "(2D only)"
  },
  all: {
    id: `pricing.plans-features.all`,
    defaultMessage: "All"
  },
  limited: {
    id: `pricing.plans-features.limited`,
    defaultMessage: "Limited"
  },
  shareViaLinkOnly: {
    id: `pricing.plans-features.share-via-link-only`,
    defaultMessage: "Share via link"
  },
  workflowCredits: {
    id: `pricing.plans-features.workflow-credits`,
    defaultMessage: "Workflow credits"
  },
  downloadShareViaLink: {
    id: `pricing.plans-features.download-share-via-link`,
    defaultMessage: "Download, embed, and share via link"
  },
  textToVideo: {
    id: `pricing.plans-features.text-to-video`,
    defaultMessage: "Text to video"
  },
  textToVideoInfo: {
    id: `pricing.plans-features.text-to-video-info`,
    defaultMessage: "Transform your text script into an AI-powered presenter-led video"
  },
  templatesCollection: {
    id: `pricing.plans-features.template-collection`,
    defaultMessage: "Templates collection"
  },
  templatesCollectionInfo: {
    id: `pricing.plans-features.templates-collection-info`,
    defaultMessage: "Select a background template from a growing collection of ready-made templates"
  },
  charactersLibrary: {
    id: `pricing.plans-features.characters-library`,
    defaultMessage: "Characters library"
  },
  charactersLibraryInfo: {
    id: `pricing.plans-features.characters-library-info`,
    defaultMessage: "Pick a preferred character from our AI character library"
  },
  uploadYourOwn: {
    id: `pricing.plans-features.upload-your-own`,
    defaultMessage: "Upload your media"
  },
  uploadYourOwnInfo: {
    id: `pricing.plans-features.upload-your-own-info`,
    defaultMessage: "Upload images, videos, and slides to be used within the template"
  },
  accessToImages: {
    id: `pricing.plans-features.access-to-images`,
    defaultMessage: "Stock media access"
  },
  accessToImagesInfo: {
    id: `pricing.plans-features.access-to-images-info`,
    defaultMessage: "Open access to stock media and AI-created images."
  },
  documentToVideo: {
    id: `pricing.plans-features.document-to-video`,
    defaultMessage: "Document-to-video"
  },
  promptToImage: {
    id: `pricing.plans-features.prompt-to-image`,
    defaultMessage: "Prompt-to-image"
  },
  voicesLibrary: {
    id: `pricing.plans-features.voices-library`,
    defaultMessage: "Voices library"
  },
  voicesLibraryInfo: {
    id: `pricing.plans-features.voices-library-info`,
    defaultMessage:
      "Choose a character's preferred voice or accent to use from our ready-made voice library"
  },
  videoPreview: {
    id: `pricing.plans-features.video-preview`,
    defaultMessage: "Video previews"
  },
  videoPreviewInfo: {
    id: `pricing.plans-features.video-preview-info`,
    defaultMessage:
      "There is no limit on videos you create in the editor. You'll be prompted for payment only if you publish a video (download, embed, or generate a sharing link) beyond your plan's minute credits."
  },
  scenesPerVideo: {
    id: `pricing.plans-features.scenes-per-video`,
    defaultMessage: "Max scenes per video"
  },
  scenesPerVideoInfo: {
    id: `pricing.plans-features.scenes-per-video-info`,
    defaultMessage: "Max number of scenes per video"
  },
  publishYourVideos: {
    id: `pricing.plans-features.publish-your-videos`,
    defaultMessage: "Publishing options"
  },
  publishYourVideosInfo: {
    id: `pricing.plans-features.publish-your-videos-info`,
    defaultMessage: "Download, embed, or share your AI-generated video."
  },
  videoTemplates: {
    id: `pricing.plans-features.video-templates`,
    defaultMessage: "Video templates"
  },
  videoTemplatesInfo: {
    id: `pricing.plans-features.video-templates-info`,
    defaultMessage: "Choose from a growing collection of ready-made background templates."
  },
  characterLibrary: {
    id: `pricing.plans-features.character-library`,
    defaultMessage: "Stock studio avatars"
  },
  characterLibraryInfo: {
    id: `pricing.plans-features.character-library-info`,
    defaultMessage: "Choose a virtual human to narrate and present your videos."
  },
  languageLibrary: {
    id: `pricing.plans-features.language-library`,
    defaultMessage: "Voices & languages"
  },
  languageLibraryInfo: {
    id: `pricing.plans-features.language-library-info`,
    defaultMessage: "Select from our extensive library of 60+ voices in various spoken languages"
  },
  noWaterMark: {
    id: `pricing.plans-features.no-water-mark`,
    defaultMessage: "Remove watermark"
  },
  noWaterMarkText: {
    id: `pricing.plans-features.no-water-mark-text`,
    defaultMessage: "No watermark"
  },
  hoWaterMarkText: {
    id: `pricing.plans-features.ho-water-mark-text`,
    defaultMessage: "HourOne watermark"
  },
  noWaterMarkInfo: {
    id: `pricing.plans-features.no-water-mark-info`,
    defaultMessage: "We may add HourOne’s Ads on none paid plans"
  },
  customerSupport: {
    id: `pricing.plans-features.customer-support`,
    defaultMessage: "Customer Support"
  },
  customerSupportInfo: {
    id: `pricing.plans-features.customer-support-info`,
    defaultMessage: "Dedicated personalized onboarding and troubleshooting."
  },
  moderation: {
    id: `pricing.plans-features.moderation`,
    defaultMessage: "Moderation"
  },
  no: {
    id: `pricing.plans-features.no`,
    defaultMessage: "No"
  },
  yes: {
    id: `pricing.plans-features.yes`,
    defaultMessage: "Yes"
  },
  addon: {
    id: `pricing.plans-features.add-on`,
    defaultMessage: "Add-on"
  },
  helpCenter: {
    id: `pricing.plans-features.help-center`,
    defaultMessage: "Help Center"
  },
  ticketingSupport: {
    id: `pricing.plans-features.ticketing-support`,
    defaultMessage: "Ticketing Support"
  },
  prioritySupport: {
    id: `pricing.plans-features.priority-support`,
    defaultMessage: "Priority Support"
  },
  videoQuality: {
    id: `pricing.plans-features.video-quality`,
    defaultMessage: "Resolution"
  },
  shareViaLink: {
    id: `pricing.plans-features.share-via-link`,
    defaultMessage: "Share via link"
  },
  download: {
    id: `pricing.plans-features.download`,
    defaultMessage: "Download"
  },
  embed: {
    id: `pricing.plans-features.embed`,
    defaultMessage: "Embed"
  },
  exportSubtitles: {
    id: `pricing.plans-features.export-subtitles`,
    defaultMessage: "Export subtitles"
  },
  internalChannel: {
    id: `pricing.plans-features.internal-channel`,
    defaultMessage: "Internal channel"
  },
  studioEditorSeats: {
    id: `pricing.plans-features.studio-editor-seats`,
    defaultMessage: "Studio Editor seats"
  },
  studioViewerSeats: {
    id: `pricing.plans-features.studio-viewer-seats`,
    defaultMessage: "Studio Viewer seats"
  },
  videoQualityInfo: {
    id: `pricing.plans-features.video-quality-info`,
    defaultMessage: "Resolution of the completed video download (mp4)."
  },
  postProduction: {
    id: `pricing.plans-features.post-production`,
    defaultMessage: "Professional production services"
  },
  postProductionInfo: {
    id: `pricing.plans-features.post-production-info`,
    defaultMessage:
      "Hour one's professional services team can create end-to-end AI videos for you. Call now."
  },
  webAvatar: {
    id: `pricing.plans-features.web-avatar`,
    defaultMessage: "Web avatar"
  },
  customPresenter: {
    id: `pricing.plans-features.custom-character`,
    defaultMessage: "Custom studio avatar"
  },
  customPresenterInfo: {
    id: `pricing.plans-features.custom-character-info`,
    defaultMessage: "Create and use your own virtual character(s) in videos."
  },
  customizedTemplate: {
    id: `pricing.plans-features.customized-template`,
    defaultMessage: "Custom template"
  },
  customizedTemplateInfo: {
    id: `pricing.plans-features.customized-template-info`,
    defaultMessage:
      "Contact us to get customized background templates tailored exclusively to your company's needs."
  },
  translation: {
    id: `pricing.plans-features.translation`,
    defaultMessage: "Translations"
  },
  translationInfo: {
    id: `pricing.plans-features.translation-info`,
    defaultMessage: "Contact us to learn how we'd translate your scripts across multiple languages."
  },
  apiAccess: {
    id: `pricing.plans-features.api-access`,
    defaultMessage: "API"
  },
  apiAccessInfo: {
    id: `pricing.plans-features.api-access-info`,
    defaultMessage:
      "Seamlessly integrate our powerful video platform into your website or application. Create, edit, and manage content effortlessly while enhancing user experience."
  },
  importPPT: {
    id: `pricing.plans-features.import-ppt`,
    defaultMessage: "Import PPT"
  },
  importPPTInfo: {
    id: `pricing.plans-features.import-ppt-info`,
    defaultMessage: "Import presentation slides into your template."
  },
  voiceToVideo: {
    id: `pricing.plans-features.voice-to-video`,
    defaultMessage: "Voice to video"
  },
  voiceToVideoInfo: {
    id: `pricing.plans-features.voice-to-video-info`,
    defaultMessage: "Use your own voice recording for videos instead of text-to-speech."
  },
  quickPreview: {
    id: `pricing.plans-features.quick-preview`,
    defaultMessage: "Quick preview"
  },
  transparentBackground: {
    id: `pricing.plans-features.transparent-background`,
    defaultMessage: "Transparent background"
  },
  transparentBackgroundInfo: {
    id: `pricing.plans-features.transparent-background-info`,
    defaultMessage:
      "Transparent background option. Perfect for seamless integration into various scenes, greenscreens, or other video projects."
  },
  screenRecording: {
    id: `pricing.plans-features.screen-recording`,
    defaultMessage: "Screen recording"
  },
  screenRecordingInfo: {
    id: `pricing.plans-features.screen-recording-info`,
    defaultMessage:
      "Record your screen and upload the final capture as a video background, to create engaging and informative scenes with ease. Perfect for tutorials and presentations."
  },
  studioProfessionalServices: {
    id: `pricing.plans-features.studio-professional-services`,
    defaultMessage: "Studio professional services"
  },
  studioProfessionalServicesInfo: {
    id: `pricing.plans-features.studio-professional-services-info`,
    defaultMessage: "Our studio team will create for you a tailor made AI videos"
  },
  customerExperienceSpecialist: {
    id: `pricing.plans-features.customer-experience-specialist`,
    defaultMessage: "Customer experience specialist"
  },
  customerExperienceSpecialistInfo: {
    id: `pricing.plans-features.customer-experience-specialist-info`,
    defaultMessage:
      "Get a dedicated customer experience specialist to escort and service you with your AI video projects"
  },
  minutesOfVideoPublished: {
    id: `pricing.plans-features.minutes-of-video-published`,
    defaultMessage: "Video credits minutes"
  },
  freeMinutesPerMonth: {
    id: `pricing.plans-features.free-minutes-per-month`,
    defaultMessage: "3 min total"
  },
  liteMinutesPerMonth: {
    id: `pricing.plans-features.lite-minutes-per-month`,
    defaultMessage: "10 min per month"
  },
  businessMinutesPerMonth: {
    id: `pricing.plans-features.business-minutes-per-month`,
    defaultMessage: "{number} min per month"
  },
  liteMinutesPerYear: {
    id: `pricing.plans-features.lite-minutes-per-year`,
    defaultMessage: "240 min per year"
  },
  businessMinutesPerYear: {
    id: `pricing.plans-features.business-minutes-per-year`,
    defaultMessage: "{number} min per year"
  },
  contactUs: {
    id: `pricing.plans-features.contact-us`,
    defaultMessage: "Contact us"
  },
  minutesOfVideoPublishedInfo: {
    id: `pricing.plans-features.minutes-of-video-published-info`,
    defaultMessage: "Unlimited previews; Only published videos deduct credits."
  },
  additionalMinutesPublished: {
    id: `pricing.plans-features.additional-minutes-published`,
    defaultMessage: "Additional minutes published"
  },
  additionalMinutesPublishedInfo: {
    id: `pricing.plans-features.additional-minutes-published-info`,
    defaultMessage:
      "Price for an additional minute of the published video once the plan's minute credits have exceeded.{br}" +
      "*Lite plan - up to 30 minutes per month.{br}" +
      "*Business plan - up to 60 minutes per month."
  },
  userSeats: {
    id: `pricing.plans-features.user-seats`,
    defaultMessage: "Users (Seats)"
  },
  teamsSeats: {
    id: `pricing.plans-features.team-seats`,
    defaultMessage: "Workspaces"
  },
  teams: {
    id: `pricing.plans-features.seats`,
    defaultMessage: "Teams"
  },
  projects: {
    id: `pricing.plans-features.projects`,
    defaultMessage: "Projects"
  },
  accessManagement: {
    id: `pricing.plans-features.access-management`,
    defaultMessage: "Access Management"
  },
  teamsSeatsInfo: {
    id: `pricing.plans-features.team-seat-info`,
    defaultMessage: "Create your workspace to work with your team."
  },
  membersLoginToWorkspace: {
    id: `pricing.plans-features.members-login-to-workspace`,
    defaultMessage: "Member logins to workspace (seats)"
  },
  membersLoginToWorkspaceInfo: {
    id: `pricing.plans-features.members-login-to-workspace-info`,
    defaultMessage: "Collaborate with others in a shared workspace."
  },
  knowledgeBase: {
    id: `pricing.plans-features.knowledgebase`,
    defaultMessage: "Knowledgebase"
  },
  techSupport247: {
    id: `pricing.plans-features.tech-support-24-7`,
    defaultMessage: "24/7 on-call tech support"
  },
  successManager: {
    id: `pricing.plans-features.success-manager`,
    defaultMessage: "Dedicated success manager"
  },
  mfa: {
    id: `pricing.plans-features.mfa`,
    defaultMessage: "Multi factor authentication"
  },
  samlSso: {
    id: `pricing.plans-features.saml-sso`,
    defaultMessage: "SAML/SSO"
  },
  videoExpertAssistance: {
    id: `pricing.plans-features.video-expert-assistance`,
    defaultMessage: "Video expert assistance"
  },
  successManagerInfo: {
    id: `pricing.plans-features.success-manager-info`,
    defaultMessage: "Hands-on support and assistance."
  },
  studioGradecustomPresenter: {
    id: `pricing.plans-features.studio-grade-custom-character`,
    defaultMessage: "Studio grade custom character"
  },
  studioGradecustomPresenterInfo: {
    id: `pricing.plans-features.studio-grade-custom-character-info`,
    defaultMessage:
      "Contact us to create custom your own studio grade characters - exclusive to your brand's videos."
  },
  custom: {
    id: `pricing.plans-features.custom`,
    defaultMessage: "Custom"
  },
  basic: {
    id: `pricing.plans-features.basic`,
    defaultMessage: "Basic"
  },
  premium: {
    id: `pricing.plans-features.premium`,
    defaultMessage: "Premium"
  },
  tailored: {
    id: `pricing.plans-features.tailored`,
    defaultMessage: "Custom"
  },
  allPreviewOnly: {
    id: `pricing.plans-features.all-preview-only`,
    defaultMessage: "All (Preview only)"
  },
  only2D: {
    id: `pricing.plans-features.only-2d`,
    defaultMessage: "2D only"
  },
  only2d3d: {
    id: `pricing.plans-features.only-2d-3d`,
    defaultMessage: "2D + 3D"
  },
  studioGradeCustomTemplate: {
    id: `pricing.plans-features.studio-grade-custom-template`,
    defaultMessage: "Studio grade custom template"
  },
  studioGradeCustomTemplateInfo: {
    id: `pricing.plans-features.studio-grade-custom-template-info`,
    defaultMessage:
      "Contact us to get customized background templates tailored exclusively to your company's needs"
  },
  aiVideoWizard: {
    id: `pricing.plans-features.ai-video-wizard`,
    defaultMessage: "Video Wizard"
  },
  aiVideoWizardInfo: {
    id: `pricing.plans-features.ai-video-wizard-info`,
    defaultMessage: "GPT-powered custom template creation"
  },
  aiScriptWizard: {
    id: `pricing.plans-features.ai-script-wizard`,
    defaultMessage: "Script Wizard"
  },
  aiScriptWizardInfo: {
    id: `pricing.plans-features.ai-script-wizard-info`,
    defaultMessage: "GPT-powered script writing assistant."
  },
  meetingBotWizard: {
    id: `pricing.plans-features.meeting-bot-wizard`,
    defaultMessage: "Meeting Bot"
  },
  translationWizard: {
    id: `pricing.plans-features.translation-wizard`,
    defaultMessage: "Translation Wizard"
  },
  aiCredits: {
    id: `pricing.plans-features.ai-credits`,
    defaultMessage: "AI Wizard Credits"
  },
  voiceCloning: {
    id: `pricing.plans-features.ai-voice-cloning`,
    defaultMessage: "Voice clone"
  },
  voiceCloningInfo: {
    id: `pricing.plans-features.voice-cloning-info`,
    defaultMessage: "Use an AI-powered clone of your own voice for video narration."
  },
  musicUpload: {
    id: `pricing.plans-features.music-upload`,
    defaultMessage: "Music upload"
  },
  voiceUpload: {
    id: `pricing.plans-features.voice-upload`,
    defaultMessage: "Voice upload"
  },
  brandKit: {
    id: `pricing.plans-features.brand-kit`,
    defaultMessage: "Brand kit"
  },
  brandKitInfo: {
    id: `pricing.plans-features.brand-kit-info`,
    defaultMessage: "Personalize videos with intro/outros, logo, and your brand's color palette."
  },
  brandIntroOutro: {
    id: `pricing.plans-features.brand-into-outro`,
    defaultMessage: "Branded intro/outro"
  }
});

export const paymentModalMessages = defineMessages({
  upgradeToRealsTitle: {
    id: `pricing.payment-modal.upgrade-to-reals-title`,
    defaultMessage: "Get Reals Lite"
  },
  upgradeToRealsTitlePersonal: {
    id: `pricing.payment-modal.upgrade-to-reals-title-personal`,
    defaultMessage: "Get Reals Business"
  },
  backButton: {
    id: `pricing.payment-modal.back-button`,
    defaultMessage: "Back"
  },
  upgradeToRealsSubTitle: {
    id: `pricing.payment-modal.upgrade-to-reals-subtitle`,
    defaultMessage:
      "Ready to get serious about your content?{br}Take the next step and get Reals Lite"
  },
  upgradeToRealsSubTitlePersonal: {
    id: `pricing.payment-modal.upgrade-to-reals-subtitle-personal`,
    defaultMessage:
      "Ready to get serious about your content?{br}Take the next step and get Reals Business"
  },
  hereWhatYouGet: {
    id: `pricing.payment-modal.here-what-you-get`,
    defaultMessage: "What you’ll get:"
  },
  minutesVideo: {
    id: `pricing.payment-modal.minutes-video`,
    defaultMessage: "10 minutes of video each month"
  },
  minutesVideoPersonal: {
    id: `pricing.payment-modal.minutes-video-personal`,
    defaultMessage: "10 minutes of videos per month"
  },
  createTeam2SeatsPersonal: {
    id: `pricing.payment-modal.create-team-6-seats-personal`,
    defaultMessage: "Create a team with {number} seats"
  },
  customPresenterPersonal: {
    id: `pricing.payment-modal.custom-character-personal`,
    defaultMessage: "Custom Character"
  },
  accessTo3dTemplatesPersonal: {
    id: `pricing.payment-modal.access-to-3d-templates-personal`,
    defaultMessage: "Access to 3D templates"
  },
  brandKitPersonal: {
    id: `pricing.payment-modal.brand-kit-personal`,
    defaultMessage: "Brand kit"
  },
  unlimitedPreviews: {
    id: `pricing.payment-modal.unlimited-previews`,
    defaultMessage: "Unlimited previews"
  },
  downloadEmbed: {
    id: `pricing.payment-modal.download-embed`,
    defaultMessage: "Download, Embed, and Share your videos"
  },
  unlockAllVoices: {
    id: `pricing.payment-modal.unlock-all-voices`,
    defaultMessage: "Unlock all voices and languages"
  },
  addYourLogos: {
    id: `pricing.payment-modal.add-your-logos`,
    defaultMessage: "Add your logos and brand colors"
  },
  priorityAccess: {
    id: `pricing.payment-modal.priority-access`,
    defaultMessage: "First access to new templates and characters"
  },
  upToScenes: {
    id: `pricing.payment-modal.up-to-scenes`,
    defaultMessage: "Up to 10 scenes per video"
  },
  upToScenesPersonal: {
    id: `pricing.payment-modal.up-to-scenes-personal`,
    defaultMessage: "Up to 15 scenes per video"
  },
  limitedTime: {
    id: `pricing.payment-modal.limited-time`,
    defaultMessage: "For a limited time get a custom template designed only for your brand"
  },
  addYourLogosAndColors: {
    id: `pricing.payment-modal.add-your-logos-and-colors`,
    defaultMessage: "Add your logos and brand colors"
  },
  unlockAllVoicesAndLanguages: {
    id: `pricing.payment-modal.unlock-all-voices-and-languages`,
    defaultMessage: "Unlock all voices and languages:"
  },
  upgradeToRealsButton: {
    id: `pricing.payment-modal.upgrade-to-reals-button`,
    defaultMessage: "Upgrade to Reals Lite"
  },
  upgradeToRealsButtonPersonal: {
    id: `pricing.payment-modal.upgrade-to-reals-button-personal`,
    defaultMessage: "Upgrade to Reals Business"
  },
  engaging: {
    id: `pricing.payment-modal.engaging`,
    defaultMessage: "Grow your brand with engaging content that sticks."
  },
  createVideos: {
    id: `pricing.payment-modal.create-videos`,
    defaultMessage: "All of your professional videos in 60+ languages."
  },
  paymentYearly: {
    id: `pricing.payment-modal.payment-yearly`,
    defaultMessage: "{price}/month"
  },
  paymentYearlyPersonal: {
    id: `pricing.payment-modal.payment-yearly-personal`,
    defaultMessage: "${price}/month"
  },
  bestValue: {
    id: `pricing.payment-modal.best-value`,
    defaultMessage: "Best Value - Save {discount}%"
  },
  paymentMonthly: {
    id: `pricing.payment-modal.payment-monthly`,
    defaultMessage: "Monthly"
  },
  paymentYearlyText: {
    id: `pricing.payment-modal.payment-yearly-text`,
    defaultMessage: "Annual Commitment (Billed Monthly)"
  },
  paymentYearlyBilledText: {
    id: `pricing.payment-modal.payment-yearly-billed-text`,
    defaultMessage: "Annual Commitment (Billed Yearly)"
  },
  nextButton: {
    id: `pricing.payment-modal.next-button`,
    defaultMessage: "Next"
  },
  paymentDetails: {
    id: `pricing.payment-modal.payment-details`,
    defaultMessage: "Payment Details"
  },
  submitButton: {
    id: `pricing.payment-modal.submit-button`,
    defaultMessage: "Get Reals Lite"
  },
  submitButtonPersonal: {
    id: `pricing.payment-modal.submit-button-personal`,
    defaultMessage: "Get Reals Business"
  },
  iHaveRead: {
    id: `pricing.payment-modal.iHave-read`,
    defaultMessage:
      "By clicking here, I state that I have read and{br}understood the terms and conditions."
  },
  cardholderNamePlaceholder: {
    id: `pricing.payment-modal.cardholder-name-placeholder`,
    defaultMessage: "Cardholder Name"
  },
  postalCodePlaceholder: {
    id: `pricing.payment-modal.postal-code-placeholder`,
    defaultMessage: "Postal Code"
  },
  gotCouponCode: {
    id: `pricing.payment-modal.got-coupon-code`,
    defaultMessage: "Got a coupon code?"
  },
  couponCodePlaceholder: {
    id: `pricing.payment-modal.coupon-code-placeholder`,
    defaultMessage: "Coupon Code"
  },
  applyButton: {
    id: `pricing.payment-modal.apply-button`,
    defaultMessage: "Apply"
  },
  couponMonthFree: {
    id: `pricing.payment-modal.coupon-month-free`,
    defaultMessage: "{percent}% off"
  },
  paymentTypeInfoLine1: {
    id: `pricing.payment-modal.payment-type-info-line1`,
    defaultMessage: "Pro rated cancellation in the first 14 days"
  },
  paymentTypeInfoLine2: {
    id: `pricing.payment-modal.payment-type-info-line2`,
    defaultMessage: "Both plans are annual commitment"
  },
  monthly: {
    id: `pricing.payment-modal.monthly`,
    defaultMessage: "Monthly"
  },
  monthlyPopover: {
    id: `pricing.payment-modal.monthly-popover`,
    defaultMessage:
      "Once canceled in the first 14 days you will be charged for one month of use. We recommend that you will use the entire creations minutes for this month before you cancel"
  },
  yearly: {
    id: `pricing.payment-modal.yearly`,
    defaultMessage: "Yearly"
  },
  yearlyPopover: {
    id: `pricing.payment-modal.yearly-popover`,
    defaultMessage:
      "Once canceled in the first 14 days you will be charged for the number of minutes that have been used"
  },
  pricePerMonth: {
    id: `pricing.payment-modal.price-per-month`,
    defaultMessage: "${price}/month"
  },
  pricePerYear: {
    id: `pricing.payment-modal.price-per-year`,
    defaultMessage: "${price}/year"
  },
  couponApplied: {
    id: `pricing.payment-modal.coupon-applied`,
    defaultMessage: "Coupon applied"
  },
  couponInvalid: {
    id: `pricing.payment-modal.coupon-invalid`,
    defaultMessage: "Invalid Coupon"
  },
  paymentErrorHeader: {
    id: `pricing.payment-modal.payment-error-header`,
    defaultMessage: "We encountered an error!"
  },
  paymentErrorDescription: {
    id: `pricing.payment-modal.payment-error-description`,
    defaultMessage: "Please contact support"
  },
  welcomeToReals: {
    id: `pricing.payment-modal.welcome-to-reals`,
    defaultMessage: "Welcome to Reals Lite!"
  },
  welcomeToRealsPersonal: {
    id: `pricing.payment-modal.welcome-to-reals-personal`,
    defaultMessage: "Welcome to Reals Business!"
  },
  trustedBy: {
    id: `pricing.payment-modal.trusted-by`,
    defaultMessage: "TRUSTED BY"
  },
  theProfessionalSuite: {
    id: `pricing.payment-modal.the-professional-suite`,
    defaultMessage: "You are now a Reals Lite member.{br}Let’s make some really engaging content"
  },
  theProfessionalSuitePersonal: {
    id: `pricing.payment-modal.the-professional-suite-personal`,
    defaultMessage:
      "You are now a Reals Business member.{br}Let’s make some really engaging content"
  },
  callToAction: {
    id: `pricing.payment-modal.call-to-action`,
    defaultMessage: "Reals features included in your plan:"
  },
  videos: {
    id: `pricing.payment-modal.videos`,
    defaultMessage: "Videos"
  },
  videosDescription: {
    id: `pricing.payment-modal.videos-description`,
    defaultMessage: "10 minutes of video each month"
  },
  videosDescriptionPersonal: {
    id: `pricing.payment-modal.videos-description-personal`,
    defaultMessage: "10 minutes of video per month"
  },
  preview: {
    id: `pricing.payment-modal.preview`,
    defaultMessage: "Preview"
  },
  previewDescription: {
    id: `pricing.payment-modal.preview-description`,
    defaultMessage: "Unlimited video previews"
  },
  logo: {
    id: `pricing.payment-modal.logo`,
    defaultMessage: "Brand kit"
  },
  logoDescription: {
    id: `pricing.payment-modal.logo-description`,
    defaultMessage: "Add your logo and brand colors"
  },
  voices: {
    id: `pricing.payment-modal.voices`,
    defaultMessage: "Voices"
  },
  voicesDescription: {
    id: `pricing.payment-modal.voices-description`,
    defaultMessage: "All voices and languages are now unlocked"
  },
  music: {
    id: `pricing.payment-modal.music`,
    defaultMessage: "Music"
  },
  musicDescription: {
    id: `pricing.payment-modal.music-description`,
    defaultMessage: "a large selection of music, upload your own music"
  },
  scenes: {
    id: `pricing.payment-modal.scenes`,
    defaultMessage: "Scenes"
  },
  scenesDescription: {
    id: `pricing.payment-modal.scenes-description`,
    defaultMessage: "Up to 10 scenes"
  },
  scenesDescriptionPersonal: {
    id: `pricing.payment-modal.scenes-description-personal`,
    defaultMessage: "Up to 15 scenes"
  },
  presentation: {
    id: `pricing.payment-modal.presentation`,
    defaultMessage: "Presentation & PDF"
  },
  presentationDescription: {
    id: `pricing.payment-modal.presentation-description`,
    defaultMessage: "Unlimited amount of scenes"
  },
  presenters: {
    id: `pricing.payment-modal.presenters`,
    defaultMessage: "Characters"
  },
  presentersDescription: {
    id: `pricing.payment-modal.presenters-description`,
    defaultMessage: "All characters unlocked. First access to new characters."
  },
  presentersDescriptionPersonal: {
    id: `pricing.payment-modal.presenters-description-personal`,
    defaultMessage: "Contact us to create custom characters — exclusive to your brand’s videos"
  },
  teams: {
    id: `pricing.payment-modal.teams`,
    defaultMessage: "Teams"
  },
  teamsDescription: {
    id: `pricing.payment-modal.teams-description`,
    defaultMessage: "2 seats per organization"
  },
  colors: {
    id: `pricing.payment-modal.colors`,
    defaultMessage: "Colors"
  },
  colorsDescription: {
    id: `pricing.payment-modal.colors-description`,
    defaultMessage: "Customize colors for your brand"
  },
  templates: {
    id: `pricing.payment-modal.templates`,
    defaultMessage: "Templates"
  },
  templatesDescription: {
    id: `pricing.payment-modal.templates-description`,
    defaultMessage: "Access to 3D templates"
  },
  share: {
    id: `pricing.payment-modal.share`,
    defaultMessage: "Share, Embed & Download"
  },
  shareDescription: {
    id: `pricing.payment-modal.share-description`,
    defaultMessage: "Distribute and publish your videos"
  },
  upgradeColorsTitle: {
    id: `pricing.payment-modal.upgrade-color-title`,
    defaultMessage: "Upgrade to use this palette"
  },
  upgradeDownloadTitle: {
    id: `pricing.payment-modal.upgrade-download-title`,
    defaultMessage: "Upgrade to download video"
  },
  upgradeDownloadSubtitlesTitle: {
    id: `pricing.payment-modal.upgrade-download-subtitles-title`,
    defaultMessage: "Upgrade to download subtitles"
  },
  upgradeExportScormTitle: {
    id: `pricing.payment-modal.upgrade-download-scorm-title`,
    defaultMessage: "Upgrade to export scorm"
  },
  upgradeStartFromUrlTitle: {
    id: `pricing.payment-modal.upgrade-start-from-url-title`,
    defaultMessage: "Upgrade to create video from url"
  },
  upgradeEmbedTitle: {
    id: `pricing.payment-modal.upgrade-embed-title`,
    defaultMessage: "Upgrade to embed video"
  },
  upgradeSceneTitle: {
    id: `pricing.payment-modal.upgrade-scene-title`,
    defaultMessage: "Upgrade to add more scenes"
  },
  upgradeLogoTitle: {
    id: `pricing.payment-modal.upgrade-logo-title`,
    defaultMessage: "Upgrade to add logo"
  },
  createTeamTitle: {
    id: `pricing.payment-modal.create-team-title`,
    defaultMessage: "Upgrade to create teams"
  }
});

export const enterpriseUpgradeModalMessages = defineMessages({
  upgradeToRealsTitle: {
    id: `pricing.enterprise-upgrade-modal.upgrade-to-reals-title`,
    defaultMessage: "Create your 3D News video"
  },

  upgradeToRealsSubTitle: {
    id: `pricing.enterprise-upgrade-modal.upgrade-to-reals-subtitle`,
    defaultMessage:
      "Use your imagination and create immersive 3D News content within Reals. Book a meeting with us and we can walk you through it!"
  }
});

export const characterModalMessages = defineMessages({
  characterInfoTitle: {
    id: `pricing.character-modal.character-info-title`,
    defaultMessage: "Create your first video using your virtual human!"
  },
  characterInfoSubTitle: {
    id: `pricing.character-modal.character-info-subtext`,
    defaultMessage: "Congratulations!"
  },
  characterInfoText: {
    id: `pricing.character-modal.character-info-text`,
    defaultMessage:
      "Unleash your creativity and make your mark in the digital world by creating your first video using a virtual human. Design and plan your script, and bring your ideas to life on screen. Get ready to impress and engage your audience like never before."
  },
  createVideoButton: {
    id: `pricing.character-modal.create-video-button`,
    defaultMessage: "Create video"
  },
  maybeLaterButton: {
    id: `pricing.character-modal.maybe-later-button`,
    defaultMessage: "Maybe later"
  }
});

export default defineMessages({
  pricingHeadline: {
    id: `pricing.headline`,
    defaultMessage: "Reals Pricing"
  },
  pricingSubTitle: {
    id: `pricing.subtitle`,
    defaultMessage:
      "Pick the plan that best suits your needs or contact our sales team to get your tailored quote"
  },
  pricingMostPopularBadge: {
    id: `pricing.popular.label`,
    defaultMessage: "RECOMMENDED"
  },
  pricingActionButtonCurrentPlan: {
    id: `pricing.currentPlan.button.label`,
    defaultMessage: "Current Plan"
  },
  pricingFeaturesHeadline: {
    id: `pricing.features.headline`,
    defaultMessage: "FEATURE:"
  },
  pricingUpgradeButtonLabel: {
    id: `pricing.plan.button.label`,
    defaultMessage: "Upgrade Now"
  },
  pricingEnterpriseButtonLabel: {
    id: `pricing.enterprise.button.label`,
    defaultMessage: "Talk to sales"
  },
  pricingMonth: {
    id: `pricing.month`,
    defaultMessage: "month"
  }
});

export const pricingPageMessages = defineMessages({
  title: {
    id: `pricing.pricing-page.title`,
    defaultMessage: "Pricing Plans"
  },
  subtitle: {
    id: `pricing.pricing-page.subtitle`,
    defaultMessage: "Select the annual plan to fit your needs"
  },
  togglerTextYearly: {
    id: `pricing.pricing-page.toggler-text-yearly`,
    defaultMessage: "Billed yearly"
  },
  togglerTextYearlyWithDiscount: {
    id: `pricing.pricing-page.toggler-text-yearly-discount`,
    defaultMessage: "Billed yearly ({discount})"
  },
  togglerTextMonthly: {
    id: `pricing.pricing-page.toggler-text-monthly`,
    defaultMessage: "Billed monthly"
  },
  yourCurrentPlanButton: {
    id: `pricing.pricing-page.your-current-plan-button`,
    defaultMessage: "Your Current Plan"
  },
  doneButton: {
    id: `pricing.done-button`,
    defaultMessage: "Done"
  },
  contactSalesButton: {
    id: `pricing.pricing-page.contact-sales-button`,
    defaultMessage: "Schedule a call"
  },
  selectPlanButton: {
    id: `pricing.pricing-page.select-plan-button`,
    defaultMessage: "Select Plan"
  },
  getStartedButton: {
    id: `pricing.pricing-page.get-started-button`,
    defaultMessage: "Get started"
  },
  videoCreationFeatures: {
    id: `pricing.pricing-page.video-creation-features`,
    defaultMessage: "Video Creation"
  },
  aiFeatures: {
    id: `pricing.pricing-page.ai-features`,
    defaultMessage: "AI Wizard Tools"
  },
  mediaFeatures: {
    id: `pricing.pricing-page.media-features`,
    defaultMessage: "Media"
  },
  publishingFeatures: {
    id: `pricing.pricing-page.publishing-features`,
    defaultMessage: "Publishing"
  },
  collaborationFeature: {
    id: `pricing.pricing-page.collaboration-features`,
    defaultMessage: "Collaboration"
  },
  supportFeature: {
    id: `pricing.pricing-page.support-features`,
    defaultMessage: "Support"
  },
  customization: {
    id: `pricing.pricing-page.customization`,
    defaultMessage: "Customization"
  },
  mostPopular: {
    id: `pricing.pricing-page.most-popular`,
    defaultMessage: "RECOMMENDED"
  },
  perMonth: {
    id: `pricing.pricing-page.per-month`,
    defaultMessage: "/month"
  },
  monthlyTitle: {
    id: `pricing.pricing-page.monthly`,
    defaultMessage: "Monthly"
  },
  yearlyTitle: {
    id: `pricing.pricing-page.yearly`,
    defaultMessage: "Yearly"
  },
  onBoardingTitle: {
    id: `pricing.pricing-page.free-title`,
    defaultMessage: "Free"
  },
  onBoardingTableTitle: {
    id: `pricing.pricing-page.free-table-title`,
    defaultMessage: "Free"
  },
  onBoardingSubtitle: {
    id: `pricing.pricing-page.free-subtitle`,
    defaultMessage: " "
  },
  onBoardingPrice: {
    id: `pricing.pricing-page.free-price`,
    defaultMessage: `$0`
  },
  onBoardingPriceDescription: {
    id: `pricing.pricing-page.free-price-explain`,
    defaultMessage: `Free trial for businesses`
  },
  onBoardingOptionsTitle: {
    id: `pricing.pricing-page.free-options-title`,
    defaultMessage: `What’s included:`
  },
  onBoarding3Minute: {
    id: `pricing.pricing-page.up-to-3-minutes`,
    defaultMessage: `3 Minutes`
  },
  onBoardingHourOneWatermark: {
    id: `pricing.pricing-page.hourone-watermark`,
    defaultMessage: `Hour One Watermark`
  },
  onBoardingShareVideosViaLink: {
    id: `pricing.pricing-page.share-videos-via-link`,
    defaultMessage: `Share Videos via Link`
  },
  onBoardingOneUser: {
    id: `pricing.pricing-page.one-user`,
    defaultMessage: `1 Editor & 1 Viewer`
  },
  onBoardingAiAvatars: {
    id: `pricing.pricing-page.ai-avatars`,
    defaultMessage: `100+ AI Avatars`
  },
  onBoardingMonthlyAiCredits: {
    id: `pricing.pricing-page.on-boarding-monthly-ai-credits`,
    defaultMessage: `10 AI Wizard Credits`
  },
  onBoardingYearlyAiCredits: {
    id: `pricing.pricing-page.on-boarding-yearly-ai-credits`,
    defaultMessage: `120 AI Wizard Credits`
  },
  onBoardingVideoTemplates: {
    id: `pricing.pricing-page.access-to-video-templates`,
    defaultMessage: `Access to video templates (including 3D)`
  },
  onBoardingAccessToVoicesAndLanguages: {
    id: `pricing.pricing-page.unlimited-video-previews`,
    defaultMessage: `Access to voices and languages`
  },
  personalTitle: {
    id: `pricing.pricing-page.personal-title`,
    defaultMessage: "Lite"
  },
  proTitle: {
    id: `pricing.pricing-page.pro-title`,
    defaultMessage: "Pro"
  },
  personalSubtitle: {
    id: `pricing.pricing-page.personal-subtitle`,
    defaultMessage: " "
  },
  personalPrice: {
    id: `pricing.pricing-page.personal-price`,
    defaultMessage: `$30 <span>/monthly</span>`
  },
  generalPrice: {
    id: `pricing.pricing-page.personal-general-price`,
    defaultMessage: "${price} <span>/mo</span>"
  },
  generalPriceAnnually: {
    id: `pricing.pricing-page.personal-general-price-annually`,
    defaultMessage: "${price} <span>/mo (billed annually)</span>"
  },
  generalYearlyPrice: {
    id: `pricing.pricing-page.personal-general-yearly-price`,
    defaultMessage: "You'll get 240 minutes up front"
  },
  freeMinutes: {
    id: `pricing.pricing-page.free-minutes`,
    defaultMessage: " "
  },
  enterpriseMinutes: {
    id: `pricing.pricing-page.enterprise-minutes`,
    defaultMessage: "Unlimited minutes"
  },
  generalMonthlyPrice: {
    id: `pricing.pricing-page.personal-general-monthly-price`,
    defaultMessage: "You'll get 10 minutes each month"
  },
  personalPriceDescription: {
    id: `pricing.pricing-page.personal-price-explain`,
    defaultMessage: `AI videos for professionals or small teams`
  },
  personalPriceYearlyDescription: {
    id: `pricing.pricing-page.personal-yearly-price-explain`,
    defaultMessage: `AI videos for professionals or small teams`
  },
  personalOptionsTitle: {
    id: `pricing.pricing-page.personal-options-title`,
    defaultMessage: `What’s included:`
  },
  personal10Minutes: {
    id: `pricing.pricing-page.personal-up-to-10-minutes`,
    defaultMessage: `10 Minutes / Month`
  },
  personalNoWatermark: {
    id: `pricing.pricing-page.personal-no-watermark`,
    defaultMessage: "No Watermark"
  },
  personalAll2dTemplates: {
    id: `pricing.pricing-page.personal-all-2d-templates`,
    defaultMessage: "All 2D Templates"
  },
  personalScriptVideoWizard: {
    id: `pricing.pricing-page.personal-script-video-wizard`,
    defaultMessage: "Script & Video Wizard"
  },
  personalLiteSeats: {
    id: `pricing.pricing-page.personal-lite-seats`,
    defaultMessage: "1 Editor & 3 Viewer Seats"
  },
  personalMonthlyAiCredits: {
    id: `pricing.pricing-page.personal-monthly-ai-credits`,
    defaultMessage: "20 AI Wizard Credits"
  },
  personalYearlyAiCredits: {
    id: `pricing.pricing-page.personal-yearly-ai-credits`,
    defaultMessage: "240 AI Wizard Credits"
  },
  personalVideoDownload: {
    id: `pricing.pricing-page.personal-video-download`,
    defaultMessage: "Share & Download"
  },
  teamSeats1: {
    id: `pricing.pricing-page.team-seats-1`,
    defaultMessage: `1 Seat`
  },
  teamSeats3: {
    id: `pricing.pricing-page.team-seats-3`,
    defaultMessage: `3 Users`
  },
  teamSeatsTooltip: {
    id: `pricing.pricing-page.team-seats-tooltip`,
    defaultMessage: "Collaborate with team members on projects"
  },
  minutesTooltip: {
    id: `pricing.pricing-page.minutes-tooltip`,
    defaultMessage: `There is no limit on videos you create in the editor. You'll be prompted for payment only if you publish a video (download, embed, or generate a sharing link) beyond your plan's minute credits.`
  },
  personalAccessAllPresenters: {
    id: `pricing.pricing-page.personal-access-all-presenters`,
    defaultMessage: `Access to stock characters`
  },
  personalAccess30Voices: {
    id: `pricing.pricing-page.personal-access-30-voices`,
    defaultMessage: `Access to voices and languages library`
  },
  personalAccessStockMedia: {
    id: `pricing.pricing-page.personal-access-stock-media`,
    defaultMessage: `Access to stock-media or generate your own by using AI`
  },
  personalAccess2dVideo: {
    id: `pricing.pricing-page.personal-access-2d-video`,
    defaultMessage: `Access 2D video templates (not including 3D)`
  },
  personalShareYourVideos: {
    id: `pricing.pricing-page.personal-share-your-videos`,
    defaultMessage: `Download, embed or share your AI videos`
  },
  businessTitle: {
    id: `pricing.pricing-page.business-title`,
    defaultMessage: "Business"
  },
  businessMonthlyTitle: {
    id: `pricing.pricing-page.business-monthly-title`,
    defaultMessage: "Business Monthly"
  },
  businessYearlyTitle: {
    id: `pricing.pricing-page.business-yearly-title`,
    defaultMessage: "Business Yearly"
  },
  personalMonthlyTitle: {
    id: `pricing.pricing-page.personal-monthly-title`,
    defaultMessage: "Personal Monthly"
  },
  personalYearlyTitle: {
    id: `pricing.pricing-page.personal-yearly-title`,
    defaultMessage: "Personal Yearly"
  },
  businessSubtitle: {
    id: `pricing.pricing-page.business-subtitle`,
    defaultMessage: " "
  },
  businessPriceDescription: {
    id: `pricing.pricing-page.business-price-explain`,
    defaultMessage: `For companies scaling video creation.`
  },
  businessPriceYearlyDescription: {
    id: `pricing.pricing-page.business-yearly-price-explain`,
    defaultMessage: `For companies scaling video creation.`
  },
  businessGeneralMonthlyPrice: {
    id: `pricing.pricing-page.business-general-monthly-price`,
    defaultMessage: "You'll get {number} minutes each month"
  },
  businessGeneralYearlyPrice: {
    id: `pricing.pricing-page.business-general-yearly-price`,
    defaultMessage: "You'll get {number} minutes up front"
  },
  businessOptionsTitle: {
    id: `pricing.pricing-page.business-options-title`,
    defaultMessage: `Get everything in Lite and:`
  },
  businessSupport: {
    id: `pricing.pricing-page.support`,
    defaultMessage: `Customer Support`
  },
  businessMonthlyAiCredits: {
    id: `pricing.pricing-page.business-monthly-ai-credits`,
    defaultMessage: `100 AI Wizard Credits`
  },
  businessYearlyAiCredits: {
    id: `pricing.pricing-page.business-yearly-ai-credits`,
    defaultMessage: `1200 AI Wizard Credits`
  },
  businessTranslationWizard: {
    id: `pricing.pricing-page.translation-wizard`,
    defaultMessage: `Translation Wizard`
  },
  businessImprovedRendering: {
    id: `pricing.pricing-page.improved-rendering`,
    defaultMessage: `Improved Rendering`
  },
  businessWebAvatar: {
    id: `pricing.pricing-page.web-avatars`,
    defaultMessage: `Web Avatar`
  },
  businessCreateTeam: {
    id: `pricing.pricing-page.business-create-team`,
    defaultMessage: `Create a team with 2 seats`
  },
  business5TeamSeats: {
    id: `pricing.pricing-page.team-seats-5`,
    defaultMessage: `1 Editor & 5 Viewers`
  },
  businessWorkspaceCollaboration: {
    id: `pricing.pricing-page.business-workspace-collaboration`,
    defaultMessage: `Workspace Collaboration`
  },
  minutesPerMonth: {
    id: `pricing.pricing-page.business-minutes-per-month`,
    defaultMessage: `{number} Minutes / Month`
  },
  businessBrandKit: {
    id: `pricing.pricing-page.business-brand-kit`,
    defaultMessage: `Custom Brand Kit & Logo`
  },
  businessAccessTo3dTemplates: {
    id: `pricing.pricing-page.business-access-to-3d-templates`,
    defaultMessage: `3D & Premium Templates`
  },
  enterpriseTitle: {
    id: `pricing.pricing-page.enterprise-title`,
    defaultMessage: "Enterprise"
  },
  enterpriseSubtitle: {
    id: `pricing.pricing-page.enterprise-subtitle`,
    defaultMessage: " "
  },
  enterprisePrice: {
    id: `pricing.pricing-page.enterprise-price`,
    defaultMessage: `Let's talk`
  },
  enterprisePriceDescription: {
    id: `pricing.pricing-page.enterprise-price-description`,
    defaultMessage: `Complete AI video pack tailored to your company.`
  },
  enterpriseOptionsTitle: {
    id: `pricing.pricing-page.enterprise-options-title`,
    defaultMessage: `Get everything in Business and:`
  },
  enterpriseUnlimitedAmount: {
    id: `pricing.pricing-page.enterprise-unlimited-amount`,
    defaultMessage: `Unlimited Video Minutes`
  },
  enterpriseUserAccessManagement: {
    id: `pricing.pricing-page.enterprise-user-access-management`,
    defaultMessage: `User Access Management`
  },
  enterpriseTotalCustomization: {
    id: `pricing.pricing-page.enterprise-seat-teams`,
    defaultMessage: `Full Brand Customization`
  },
  enterprisePriorityQueue: {
    id: `pricing.pricing-page.enterprise-queue`,
    defaultMessage: `Priority Rendering Queue`
  },
  enterpriseUnlimitedCustomerPresenters: {
    id: `pricing.pricing-page.enterprise-unlimited-customer-presenters`,
    defaultMessage: `Custom Avatars`
  },
  enterpriseCustomTemplates: {
    id: `pricing.pricing-page.enterprise-custom-templates`,
    defaultMessage: `Custom Templates`
  },
  enterpriseCustomNumberOfUsers: {
    id: `pricing.pricing-page.enterprise-custom-number-of-users`,
    defaultMessage: `Workspace Collaboration`
  },
  enterpriseDedicatedCustomerSuccess: {
    id: `pricing.pricing-page.enterprise-dedicated-customer-success`,
    defaultMessage: `Customer Success Manager`
  },
  enterpriseApiAccess: {
    id: `pricing.pricing-page.enterprise-api-access`,
    defaultMessage: `API Access`
  },
  enterpriseSSO: {
    id: `pricing.pricing-page.enterprise-sso`,
    defaultMessage: `SAML/SSO`
  }
});
