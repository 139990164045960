import { useEffect } from "react";
import pusher from "app/services/pusher";
import { useAuth } from "app/auth/useAuth";
import { Channel } from "pusher-js";
import { useAppDispatch } from "app/hooks";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";

const PusherMediaGenerator = () => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    let channel: Channel;
    const handleMediaGeneration = (message: {
      status: "ready" | "failed";
      images?: { url: string }[];
      order_id: string;
    }) => {
      const { status, images, order_id } = message;
      if (status === "ready") {
        dispatch(
          mediaActions.setMediaGenerationResult({
            orderId: order_id,
            images: images,
            status: status
          })
        );
      } else if (status === "failed") {
        dispatch(
          mediaActions.setMediaGenerationResult({
            orderId: order_id,
            images: images,
            status: status
          })
        );
      }
    };
    if (user?.sub) {
      const userId = user.sub.split("|")[1];
      const channelName = `${userId}`;
      channel = pusher.subscribe(channelName);
      channel.bind("media_generator", handleMediaGeneration);
    }
    return () => {
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, []);

  return null;
};

export default PusherMediaGenerator;
