import { useEffect, useState } from "react";
import { fetchingStatus, resizeImage } from "app/utils/helpers";
import * as Sentry from "@sentry/react";
import buildGeneralError from "app/hoc/ErrorNotifier/buildGeneralError";
import buildGeneralMessage from "app/hoc/ErrorNotifier/buildGeneralMessage";
import useErrors from "app/hooks/useErrors";
import { useAppDispatch, useAppSelector } from "app/hooks";
import * as filestackClient from "app/services/filestackClient";
import { InternalMedia, UploadFileInfo, UploadFileSource } from "app/types/media";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";
import { useIntl } from "react-intl";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import { FilestackPolicy, FoldersContext } from "app/types";
import { mediaLibraryMessages } from "app/pages/mediaLibrary/messages";
import { MAX_FILE_SIZE } from "app/services/filestackClient";

class MediaError extends Error {
  public item: any;
  constructor(message: string, item?: any) {
    super(message);
    this.name = "MediaError";
    this.item = item;
  }
}
const useMediaLibraryUploader = ({
  context = FoldersContext.media,
  onSucceedUploading
}: {
  context?: FoldersContext;
  onSucceedUploading?: CallableFunction;
}) => {
  const [uploadedMedia, setUploadedMedia] = useState<UploadFileInfo | undefined>();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const { notifyError } = useErrors();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { scene, sceneId, selectedSceneIndex } = useSelectedScene();
  const filestackPolicy = useAppSelector((state) => state.media.filestackReadPolicy);
  const createMediaStatus = useAppSelector((state) => state.media.createMediaStatus);
  const sceneName = scene?.name as string;

  useEffect(() => {
    if (!loading) {
      return;
    }
    if (
      createMediaStatus === fetchingStatus.succeeded ||
      createMediaStatus === fetchingStatus.failed
    ) {
      setLoading(false);
      if (createMediaStatus === fetchingStatus.succeeded && onSucceedUploading) {
        onSucceedUploading();
      }
      dispatch(mediaActions.updateCreateMediaStatusToIdle());
    }
  }, [createMediaStatus]);

  const executeImport = async (file: File[]) => {
    if (!file || !file.length) {
      return;
    }

    for (let i = 0; i < file.length; i++) {
      const currentFile = file[i];
      if (currentFile.size > MAX_FILE_SIZE) {
        notifyError({
          general: buildGeneralError(
            `File size can't be larger than ${MAX_FILE_SIZE / 1024 / 1024}MB`,
            intl
          )
        });
        return;
      }
    }

    try {
      setLoading(true);
      setProgress(0);
      const { payload: uploadPolicy } = await dispatch(
        mediaActions.getMediaPolicyRequest("upload")
      );

      filestackClient.init(uploadPolicy as FilestackPolicy, "upload");
      let isResized = false;
      const sanitizedFiles = await Promise.all(
        file.map(async (file) => {
          const result = await resizeImage(file);
          isResized = isResized || result.isResized;
          return result.fileResult;
        })
      );

      const client = filestackClient?.getClient("upload");
      const result = await client?.multiupload(sanitizedFiles, {
        onProgress: (evt) => {
          if (evt?.totalPercent) {
            setProgress(evt.totalPercent);
          }
        },
        progressInterval: 500
      });

      const bulkMedia = await Promise.all(
        result.map(
          async (item: {
            status: string;
            name: string;
            mimetype: string;
            handle: string;
            url: string;
          }) => {
            if (item.status !== "Stored") {
              throw new MediaError("Failed to store file", item);
            }
            const isVideo = item.mimetype.includes("video");
            const isImage = item.mimetype.includes("image");
            const isWebm = item.mimetype.includes("webm");
            const isAvi = item.mimetype.includes("avi");

            if (isWebm) {
              throw new MediaError("*.webm files are not allowed");
            }
            if (isAvi) {
              throw new MediaError("*.avi files are not allowed");
            }

            if (isVideo) {
              await filestackClient.createVideoThumbnail(filestackPolicy, item.handle);
            } else if (isImage) {
              await filestackClient.createImageThumbnail(filestackPolicy, item.handle);
            } else {
              throw new MediaError(`File ${item.name} type is not supported`);
            }

            const media: Omit<InternalMedia, "id"> = {
              url: item.url,
              handle: item.handle
            };
            return media;
          }
        )
      );

      if (isResized) {
        notifyError({
          general: buildGeneralMessage(
            intl.formatMessage(mediaLibraryMessages.fileSizeReducedHeader),
            intl.formatMessage(mediaLibraryMessages.fileSizeReduced)
          )
        });
      }

      dispatch(
        analyticsEvents.uploadMedia({
          selectedScene: {
            name: sceneName,
            id: sceneId,
            index: selectedSceneIndex
          }
        })
      );
      dispatch(
        mediaActions.createMediaBulkRequest({
          bulk: bulkMedia.filter((item) => !!item),
          context
        })
      );

      if (bulkMedia.length > 0) {
        setUploadedMedia({
          url: bulkMedia[0].url,
          handle: bulkMedia[0].handle,
          source: UploadFileSource.mediaLibrary
        });
      }
    } catch (err) {
      console.error("error", err);
      Sentry.captureException(err, {
        extra: {
          description: "user failed upload media",
          item: err instanceof MediaError ? err.item : undefined
        }
      });
      const description = err instanceof MediaError ? err.message : "Failed process upload media";
      notifyError({ general: { type: "alert", message: "Something went wrong", description } });
      setLoading(false);
    }
  };

  const storeUrl = async (outerUrl: string) => {
    if (!outerUrl) {
      return;
    }

    try {
      setLoading(true);
      setProgress(0);
      const { payload: uploadPolicy } = await dispatch(
        mediaActions.getMediaPolicyRequest("upload")
      );

      filestackClient.init(uploadPolicy as FilestackPolicy, "upload");
      const client = filestackClient?.getClient("upload");
      const storeResult = (await client?.storeURL(outerUrl)) as {
        url: string;
        handle: string;
        filename: string;
        size: number;
        type: string;
        mimetype: string;
      };

      await filestackClient.createImageThumbnail(filestackPolicy, storeResult.handle as string);
      const url = `https://cdn.filestackcontent.com/${storeResult.handle}`;

      setUploadedMedia({ url, handle: storeResult.handle, source: UploadFileSource.thirdParty });
    } catch (err) {
      console.error("error", err);
      Sentry.captureException(err, {
        extra: { description: `user failed upload image from url ${outerUrl}` }
      });

      notifyError({ general: buildGeneralError("Failed process upload image", intl) });
    } finally {
      setLoading(false);
    }
  };

  const resetUploader = () => {
    setUploadedMedia(undefined);
    setLoading(false);
    setProgress(0);
  };

  const clearUserUploadedMedia = () => {
    setUploadedMedia(undefined);
  };

  return {
    uploadedMedia,
    storeUrl,
    executeImport,
    importLoading: loading,
    progress,
    resetUploader,
    clearUserUploadedMedia
  };
};

export default useMediaLibraryUploader;
