import { StyledModal } from "app/components/common/StyledModal";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import styled, { useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Input, Selection } from "@nextui-org/react";
import FileUploader from "app/components/common/FileUploader";
import { ChannelsTabs, defiance, DEFIANCE, FeatureFlag, MimeType } from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import useFileUploadFilestack from "app/components/editor/scene/transcriptAudioUploader/useFileUploadFilestack";
import { fetchingStatus } from "app/utils/helpers";
import { cgnActions } from "app/store/slices/cgn.slice";
import NextSelect from "app/components/common/NextUI/Select/NextSelect";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { v4 as uuidv4 } from "uuid";
import { useFlags } from "launchdarkly-react-client-sdk";

const FILE_TYPES = MimeType.mp4;

const StyledSelect = styled(NextSelect)`
  &&& button {
    background-color: ${({ theme }) => theme.gray3};
    &:hover {
      background-color: ${({ theme }) => theme.gray4};
    }
  }
`;

interface UploadVideoModalProps {
  visible: boolean;
  onClose: () => void;
}

const UploadVideoModal = ({ visible, onClose }: UploadVideoModalProps) => {
  const [channel, setChannel] = useState<ChannelsTabs | DEFIANCE>(ChannelsTabs.news);
  const [title, setTitle] = useState<string>("");
  const fileUploaderRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const flags = useFlags();

  const uploadVodStatus = useAppSelector((state) => state.cgn.uploadVodStatus);

  const { reset, onSelectFile, importLoading, fileStoreResult } = useFileUploadFilestack({
    autoUpload: true
  });

  const items = useMemo(() => {
    let result: any = Object.values(ChannelsTabs).map((key) => ({
      key,
      label: key.charAt(0).toUpperCase() + key.slice(1)
    }));

    if (flags[FeatureFlag.cgnVodUpload]) {
      result = [{ key: defiance, label: "Defiance Media" }, ...result];
    }

    return result;
  }, []);

  useEffect(() => {
    if (flags[FeatureFlag.cgnVodUpload]) {
      setChannel(defiance);
    }
  }, [flags[FeatureFlag.cgnVodUpload]]);

  useEffect(() => {
    if (!visible) {
      return;
    }
    if (uploadVodStatus === fetchingStatus.succeeded) {
      reset();
      dispatch(cgnActions.updateUploadVodStatusToIdle());
      onClose();
    }
    if (uploadVodStatus === fetchingStatus.failed) {
      dispatch(cgnActions.updateUploadVodStatusToIdle());
    }
  }, [uploadVodStatus]);

  useEffect(() => {
    if (fileStoreResult) {
      const { url } = fileStoreResult;
      dispatch(cgnActions.uploadVodRequest({ url, channel, title, orderId: uuidv4() }));
    }
  }, [fileStoreResult]);

  useEffect(() => {
    if (!visible) {
      setTitle("");
    }
  }, [visible]);

  const onChannelChange = (currChannel: Selection) => {
    const selection = Array.from(currChannel)[0] as ChannelsTabs;
    setChannel(selection as ChannelsTabs);
  };

  const onClickUploadVideo = (e: React.MouseEvent) => {
    if (fileUploaderRef.current) {
      (fileUploaderRef.current as any).onClick(e);
    }
  };

  return (
    <StyledModal
      width={"409px"}
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "359px" }}
      onCancel={onClose}
      destroyOnClose
      maskClosable
      closeIcon={<CloseModalIcon />}
      $padding="31px"
      $gap="21px"
      $justifyContent="space-between"
    >
      <H1_FlexColumn gap="20px">
        <H1_FlexColumn padding="10px 0 0 0" flex="0 0 auto" width={"100%"}>
          <H1_TextSubtitle color={theme.gray10}>Create VOD</H1_TextSubtitle>
        </H1_FlexColumn>
        <Input
          placeholder="Enter video title"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          size="sm"
          label="Video Title"
          labelPlacement="outside"
        />

        <StyledSelect
          items={items}
          label="Channel"
          labelPlacement="outside"
          size="sm"
          selectedKeys={[channel]}
          onSelectionChange={onChannelChange}
          selectorIcon={<H1_Icon color={theme.gray11} icon="far fa-chevron-down" />}
        />
      </H1_FlexColumn>
      <FileUploader
        onFilesSelected={(files) => onSelectFile(files[0])}
        disabled={importLoading}
        fileTypes={FILE_TYPES}
        multiple={false}
        ref={fileUploaderRef}
      >
        <Button
          color="primary"
          size="sm"
          isDisabled={!channel || !title}
          onClick={onClickUploadVideo}
          isLoading={importLoading}
          startContent={<i className="fas fa-export" />}
        >
          Upload Video
        </Button>
      </FileUploader>
    </StyledModal>
  );
};

export default UploadVideoModal;
