import { useIntl } from "react-intl";
import styled from "styled-components";
import { homePageMessages } from "app/pages/HomePage/messages";
import { H1_TextLargeHeadline, H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch } from "app/hooks";
import React from "react";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import { Location } from "app/types";
import { useNavigate } from "react-router-dom";
import { Button } from "@nextui-org/react";

const SizeTextLargeHeadline = styled(H1_TextLargeHeadline)`
  line-height: 40px;
  @media (max-width: 1550px) {
    font-size: 30px;
  }
  @media (max-width: 1400px) {
    font-size: 28px;
  }

  @media (max-width: 1300px) {
    font-size: 24px;
  }
`;

const ImageContainer = styled(H1_FlexRow)`
  right: -10px;
  bottom: -10px;
  @media (max-width: 1400px) {
    display: none;
  }
`;

const GradientSectionFlexRow = styled(H1_FlexRow)`
  background: #9bd2be;
  border-radius: 10px;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    span {
      font-size: 16px;
    }
  }
`;

const WorkflowGradientSectionFlexRow = styled(GradientSectionFlexRow)`
  background: #c6beff;
  border-radius: 10px;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    span {
      font-size: 16px;
    }
  }
`;

const PositionButton = styled(Button)`
  background-color: white;
  margin-top: 13px;
  padding: 2px 30px;
  width: min-content;
  border-radius: 60px;
  & {
    height: 47px;
  }
`;

const HomePageTopSectionSales = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onWorkflowClick = () => {
    const SOURCE = "banner";
    dispatch(
      analyticsEvents.homepageCTA({
        source: SOURCE,
        actionDescription: "ExploreWorkflow"
      })
    );
    navigate(Location.Workflows);
  };

  return (
    <>
      <H1_FlexColumn
        position="relative"
        flex="1 1 auto"
        height="250px"
        data-auto-id="homepage-sales-banner"
      >
        <WorkflowGradientSectionFlexRow
          position="relative"
          height="100%"
          overflow="hidden"
          padding="30px 30px 40px"
        >
          <H1_FlexColumn justify="space-between">
            <H1_FlexColumn gap="5px">
              <SizeTextLargeHeadline whiteSpace="break-spaces" fontWeight={500} color="white">
                {formatMessage(homePageMessages.largeHeadingWorkflow)}
              </SizeTextLargeHeadline>
              <H1_TextMiddle fontWeight={500} whiteSpace="break-spaces" color="white">
                {formatMessage(homePageMessages.largeHeadingWorkflowSubtitle)}
              </H1_TextMiddle>
            </H1_FlexColumn>
            <PositionButton type="secondary" onClick={onWorkflowClick}>
              {formatMessage(homePageMessages.workflowButton)}
            </PositionButton>
          </H1_FlexColumn>
        </WorkflowGradientSectionFlexRow>
        <ImageContainer position="absolute">
          <img
            width="158px"
            height="153px"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/createWorkflowIcon.webp"
            alt="workflow"
          />
        </ImageContainer>
      </H1_FlexColumn>
    </>
  );
};

export default HomePageTopSectionSales;
