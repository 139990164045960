export const enum FeatureFlag {
  framePreviewFeature = "framePreviewFeature",
  framePreviewQuality = "framePreviewQuality",
  cgnVodUpload = "cgnVodUpload",
  interviewRecipeId = "interviewRecipeId",
  playgroundReqId = "playgroundReqId",
  podcastRecipeId = "podcastRecipeId",
  falAi = "falAi",
  cgn = "cgn",
  podcastFile = "podcastFile",
  playlists = "playlists",
  speechToSpeech = "speechToSpeech",
  whatsNew = "whatsNew",
  withPaymentYearly = "withPaymentYearly",
  downloadAudioTranscript = "downloadAudioTranscript",
  podcast = "podcast",
  playgroundModels = "playgroundModels",
  mediaAndEntertainment = "mediaAndEntertainment",
  freeCopyLink = "freeCopyLink",
  globalPronunciation = "globalPronunciation",
  voiceSettings = "voiceSettings",
  dubbing = "dubbing",
  csvTable = "csvTable",
  commentsNotifications = "commentsNotifications",
  workflow = "workflow",
  avatarPanel = "avatarPanel",
  fromUrl = "fromUrl",
  fromScript = "fromScript",
  scenePreset = "scenePreset",
  multiplePresenters = "multiplePresenters",
  templateSelectionAutoOpen = "templateSelectionAutoOpen",
  voiceProvider = "voiceProvider",
  printScript = "printScript",
  mobileQr = "mobileQr",
  videoActions = "videoActions",
  avatarHair = "avatarHair",
  mobileCharacterModal = "mobileCharacterModal",
  announceKit = "announceKit",
  presentersPage = "presentersPage",
  briefsPage = "briefsPage",
  reversedEditor = "reversedEditor",
  ftuVideoPreview = "ftuVideoPreview",
  videoWizard = "videoWizard",
  zoomBot = "zoomBot",
  fonts = "fonts",
  videoWizardRevert = "videoWizardRevert",
  liveBlocks = "liveBlocks",
  pronunciation = "pronunciation",
  selfieModalAction = "selfieModalAction",
  iconic = "iconic",
  musicv2 = "musicv2",
  recentVideoFeed = "videoFeed", // Cannot change the string in LD, but wanted to reused this unused feature flag
  teams = "teams",
  createWorkspace = "createWorkspace",
  dark = "dark",
  homepageTabs = "homepageTabs",
  changeTemplate = "changeTemplate",
  personalTemplates = "personalTemplates",
  screenCaptureFeature = "screenCaptureFeature",
  presentationImport = "presentationImport",
  transcriptAudioUpload = "transcriptAudioUpload",
  blockProTemplateOnCreateDraft = "blockProTemplateOnCreateDraft",
  imageFit = "imageFit",
  sdPromptValidation = "sdPromptValidation",
  showCoupon = "showCoupon",
  responsiveBannerCode = "responsiveBannerCode",
  responsiveBannerNoCode = "responsiveBannerNoCode",
  homepageFtu = "homepageFtu",
  socialMediaCollection = "socialMediaCollection",
  lexicalEditor = "lexicalEditor",
  sdMediaTab = "sdMediaTab",
  pexels = "pexels",
  sceneAugmentation = "sceneAugmentation",
  generateTranscript = "generateTranscript",
  scriptWizard = "scriptWizard",
  disableFilestackThumbnail = "disableFilestackThumbnail",
  transcriptAudioUploadNoLimit = "transcriptAudioUploadNoLimit",
  allowCustomColorForAnyPlan = "allowCustomColorForAnyPlan",
  cloneYourVoice = "cloneYourVoice",
  showBrandKitButton = "showBrandKitButton",
  skipSurvey50 = "skipSurvey50",
  expSkipEditorOnBoarding = "expSkipEditorOnBoarding",
  oneTimePayment = "oneTimePayment",
  notificationInbox = "notificationInbox",
  community = "community",
  previewButton = "previewButton",
  headerLightning = "headerLightning",
  minutesUsage = "minutesUsage",
  subscriptionCard = "subscriptionCard",
  securityCard = "securityCard",
  segmentationFullFlow = "segmentationFullFlow",
  playground = "playground",
  addAvatar = "addAvatar",
  showAddAvatarOnPresenters = "showAddAvatarOnPresenters",
  skipScene = "skipScene",
  sso = "sso",
  startFromDocsAndScript = "startFromDocsAndScript",
  summaryV2 = "summaryV2",
  avatarFromWebcam = "avatarFromWebcam",
  mediaSideBar = "mediaSideBar",
  houroneStock = "houroneStock",
  trash = "trash"
}

export type FeatureFlags = {
  [key in FeatureFlag]?: any;
};
