import { StyledModal } from "app/components/common/StyledModal";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import styled, { useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import React, { Key, useEffect, useMemo, useState } from "react";
import { Button, Selection, Textarea } from "@nextui-org/react";
import useNotifications from "app/hooks/useNotifications";
import NextSelect from "app/components/common/NextUI/Select/NextSelect";
import { ChannelsTabs } from "app/types";
import { useAppDispatch } from "app/hooks";
import { cgnActions } from "app/store/slices/cgn.slice";

const PromptButton = styled(Button)`
  align-self: flex-end;
  justify-content: center;
  border-radius: 10px;
  i {
    color: ${({ theme }) => theme.gray1};
  }
`;

interface PodcastModalProps {
  visible: boolean;
  onClose: () => void;
}

const WatchNextModal = ({ visible, onClose }: PodcastModalProps) => {
  const [prompt, setPrompt] = useState<string>("");
  const [useCase, setUseCase] = useState<ChannelsTabs>(ChannelsTabs.news);
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { notifyMessages } = useNotifications();

  useEffect(() => {
    () => {
      setPrompt("");
    };
  }, [visible]);
  const onChangePromptValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPrompt(value);
  };

  const onSendPrompt = () => {
    dispatch(cgnActions.sendPublicLivePromptRequest({ prompt, useCase }));
    notifyMessages([
      {
        message: "Stream being updated"
      }
    ]);
    onClose();
  };

  const onSelectionChange = (value: Selection) => {
    const parsedValue = Array.from(value)[0] as ChannelsTabs;
    setUseCase(parsedValue);
  };

  const useCaseSelectionsItems = useMemo(() => {
    const items = [
      {
        key: ChannelsTabs.news,
        label: "News"
      },
      {
        key: ChannelsTabs.podcast,
        label: "Podcast"
      }
    ];
    return items;
  }, []);

  return (
    <StyledModal
      width={"409px"}
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "359px" }}
      onCancel={onClose}
      destroyOnClose
      maskClosable
      closeIcon={<CloseModalIcon />}
      $padding="31px"
      $gap="21px"
    >
      <H1_FlexColumn padding="10px 0 0 0" flex="0 0 auto" width={"100%"}>
        <H1_TextSubtitle color={theme.gray10}>What do you want to watch?</H1_TextSubtitle>
      </H1_FlexColumn>
      <H1_FlexColumn flex="1" gap="10px" align={"center"}>
        <Textarea
          placeholder={"Enter your prompt"}
          value={prompt}
          onChange={onChangePromptValue}
          fullWidth
          minRows={4}
        />
        <NextSelect
          selectedKeys={[useCase as Key]}
          onSelectionChange={onSelectionChange}
          items={useCaseSelectionsItems}
          size="sm"
        />
      </H1_FlexColumn>
      <PromptButton
        onClick={onSendPrompt}
        isIconOnly
        startContent={<i className="fas fa-sparkles" />}
        isDisabled={!prompt}
        color="primary"
      />
    </StyledModal>
  );
};

export default WatchNextModal;
