import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled, { useTheme } from "styled-components";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { templatesPageMessages } from "app/pages/Templates/messages";
import { useIntl } from "react-intl";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { useAppDispatch, useAppSelector } from "app/hooks";
import recipesSelectors from "app/store/selectorsV2/recipes.selectors";
import { FeatureFlag, Orientation, Recipe, RecipeList, TemplateSelection } from "app/types";
import { recipesActions } from "app/store/slices/recipes.slice";
import { fetchingStatus } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import FullTemplatesPageCollection from "app/pages/Templates/FullTemplatesPageCollection";
import FullTemplatePageSkeleton from "app/pages/Templates/FullTemplatePageSkeleton";
import SingleTemplateItem from "app/pages/Templates/SingleTemplateItem";
import { useAuth } from "app/auth/useAuth";
import { MenuProps } from "antd";
import FullTemplatesPageEmptyStates from "app/pages/Templates/FullTemplatesPageEmptyStates";
import ChipsContainer from "app/pages/Templates/CategoriesChips";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { NewVideoType } from "app/store/thunks/analyticsEvents.thunk";
import { debounce } from "lodash-es";
import FullTemplatesPageGridCollection from "app/pages/Templates/FullTemplatesPageGridCollection";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import HomePageActionButton from "app/pages/HomePage/HomePageActionButton";
import { homePageMessages } from "app/pages/HomePage/messages";
import VideoWizardModalV2 from "app/components/common/VideoWizardModalV2/VideoWizardModalV2";
import { useFlags } from "launchdarkly-react-client-sdk";
import uiActions from "app/store/actions/ui.actions";
import useModal, { ModalName } from "app/hooks/useModal";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import { H1_Input } from "app/components/_Infrastructure/design-system/input";
import NextDropdown from "app/components/common/NextUI/Dropdown/NextDropdown";
import { ThemeMode } from "app/utils/theme";

const SOURCE = "template_page";
const LND_NAME = "Learning & Development";
const SALES = "Sales";
const PRODUCT_MARKETING = "Product Marketing";
const CREATORS = "Creators";
const PRODUCT_EXPERIENCE = "Product Experience";

const IconContainer = styled(H1_FlexRow)`
  background-color: ${(props: { $backgroundColor: string }) => props.$backgroundColor};
  border-radius: 50%;
  @media (max-width: 1400px) {
    display: none;
  }
`;

const CategoriesFlexRow = styled(H1_FlexRow)`
  top: 70px;
  z-index: 3;
  opacity: 1;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: none;
`;

const BackgroundFlexColumn = styled(H1_FlexColumn)<{ $isLoading: boolean }>`
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray12)};
  min-height: ${({ $isLoading }) => ($isLoading ? "auto" : 0)};
`;

const SearchFlexColumn = styled(H1_FlexColumn)`
  top: 0px;
  z-index: 3;
`;

const SearchIcon = styled.i`
  color: ${({ theme }) => theme.gray6};
  font-size: 11px;
  left: 18px;
  top: calc(50% - 5px);
  position: absolute;
  z-index: 3;
`;

const RoundSearch = styled(H1_Input)`
  border-radius: 43px;
  input {
    padding-left: 40px;
    height: 50px;
    border-radius: 43px;
    transition-property: box-shadow;
    transition-duration: 0.2s;
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
    transition-timing-function: ease-in-out;
    @media (max-width: ${SMALL_SCREEN_PX}) {
      min-width: 300px;
    }
  }
  i {
    color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray7 : theme.gray6)};
  }
`;

const Icon = styled.i<{ $color: string }>`
  color: ${({ $color }) => $color};
  padding-right: 5px;
`;

const BoxTextSmall = styled(H1_TextSmall)`
  padding-right: 5px !important;
`;

const Box = styled(H1_FlexRow)<{ $isColored?: boolean }>`
  padding: 0 7px 0 10px;
  border-radius: 50px;
  border: 2px solid ${(props) => (props.$isColored ? props.theme.blue4 : props.theme.gray4)};
  background-color: ${(props) => (props.$isColored ? props.theme.blue4 : props.theme.gray4)};
  transition: transform 0.3s ease-in-out;
  &&& span {
    color: ${(props) => (props.$isColored ? props.theme.gray4 : props.theme.gray9)};
  }
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
`;

const TEMPLATE_HEIGHT = 160;
const TEMPLATE_WIDTH = TEMPLATE_HEIGHT * (16 / 9);
const SPACE_BETWEEN_TEMPLATES = 28;
const MAX_NUM_OF_SLIDES = 6;

const FullTemplatesPage = () => {
  const [isVideoWizardV2ModalOpen, setIsVideoWizardV2ModalOpen] = useState<boolean>(false);
  const [orientation, setOrientation] = useState<Orientation>(Orientation.AnySize);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const ref = useRef<HTMLDivElement>(null);
  const fullPageRef = useRef<HTMLDivElement>(null);
  const { openModal } = useModal();
  const flags = useFlags();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { isAuthenticated } = useAuth();
  const currentWorkspaceId = useAppSelector((state) => getCurrentWorkspace(state)?.id);

  const selectedSectionsNames: string[] = useAppSelector(
    (state) => state.recipes.selectedSectionsNames
  );
  const templateSelection = useAppSelector((state) => state.recipes.sourceTemplateSelection);
  const selectedSectionData = useAppSelector((state) =>
    recipesSelectors.selectedSectionData(state, selectedSectionsNames, undefined)
  );
  const orientationFlatMap = useAppSelector((state) =>
    recipesSelectors.getFilteredFlattenRecipes(
      state,
      orientation,
      selectedSectionsNames,
      searchQuery
    )
  );
  const categoriesNames = useAppSelector((state) =>
    recipesSelectors.getRecipesCategoriesNames(state)
  );
  const recipesStatus = useAppSelector((state) => state.recipes.status);
  const isLoading = recipesStatus === fetchingStatus.loading;
  const isSearching = !!searchQuery;
  const isEmptyState = orientationFlatMap.length === 0;
  const isCollapse = isSearching || selectedSectionsNames.length > 0;
  const isSalesQuickActionsDisplayed =
    selectedSectionsNames.length === 1 && selectedSectionsNames[0] === SALES;
  const isLnDQuickActionsDisplayed =
    selectedSectionsNames.length === 1 && selectedSectionsNames[0] === LND_NAME;
  const startFromDocsAndScriptFlag = flags[FeatureFlag.startFromDocsAndScript];
  const isProductQuickActionsDisplayed = useMemo(
    () =>
      [PRODUCT_MARKETING, CREATORS, PRODUCT_EXPERIENCE].every((section) =>
        selectedSectionsNames.includes(section)
      ) && selectedSectionsNames.length === 3,
    [selectedSectionsNames]
  );

  const orientationText = useMemo(() => {
    let resultText: string;
    switch (orientation) {
      case Orientation.Portrait:
        resultText = formatMessage(templatesPageMessages.portrait);
        break;
      case Orientation.Square:
        resultText = formatMessage(templatesPageMessages.square);
        break;
      case Orientation.Landscape:
        resultText = formatMessage(templatesPageMessages.landscape);
        break;
      case Orientation.AnySize:
      default:
        resultText = formatMessage(templatesPageMessages.anysize);
        break;
    }

    return resultText;
  }, [orientation]);

  useEffect(() => {
    dispatch(recipesActions.getRecipesRequest({ examples: !isAuthenticated }));

    return () => {
      dispatch(recipesActions.clearTemplateSelection());
    };
  }, [currentWorkspaceId]);

  useEffect(() => {
    if (templateSelection) {
      const namesToOpen: string[] = [];
      switch (templateSelection) {
        case TemplateSelection.LnD:
          namesToOpen.push(LND_NAME);
          if (flags[FeatureFlag.templateSelectionAutoOpen]) {
            onClickDocs();
          }
          break;
        case TemplateSelection.Sales:
          namesToOpen.push(SALES);
          break;
        case TemplateSelection.Product:
          namesToOpen.push(PRODUCT_MARKETING);
          namesToOpen.push(CREATORS);
          namesToOpen.push(PRODUCT_EXPERIENCE);
          if (flags[FeatureFlag.templateSelectionAutoOpen]) {
            onClickFromVideoWizardCard();
          }
          break;
        default:
        // Do nothing
      }
      dispatch(recipesActions.clearSelectedSectionNames());
      namesToOpen.forEach((name: string) => {
        dispatch(
          recipesActions.updateSelectedSectionNames({
            name
          })
        );
      });
    }
  }, [templateSelection]);

  const numOfSlides = useMemo(() => {
    if (ref?.current && selectedSectionData.length > 0) {
      // @ts-ignore handles intl formatting with elements issue
      const width = ref?.current?.offsetWidth;
      return Math.floor((width - 100) / (TEMPLATE_WIDTH + SPACE_BETWEEN_TEMPLATES)) || 1; // 100 due to padding left 50 and padding right 50
    }

    return MAX_NUM_OF_SLIDES;
  }, [ref?.current, selectedSectionData.length]);

  const updateSearch = debounce((value: string) => {
    setSearchQuery(value);
    if (value) {
      dispatch(analyticsEvents.templateSearch({ value }));
    }
  }, 750);

  const debounceRequest = useCallback((value: any) => updateSearch(value), []);

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceRequest(e.target.value);
  };

  const onBlurSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(analyticsEvents.templateSearch({ value: e.target.value }));
  };

  const onClear = () => {
    setSearchQuery("");
  };

  const onOrientationChange = (currentOrientation: Orientation) => {
    dispatch(
      analyticsEvents.changeOrientation({ source: "templatePage", orientation: currentOrientation })
    );
    setOrientation(currentOrientation);
  };

  const onCloseVideoWizardModalV2 = () => {
    setIsVideoWizardV2ModalOpen(false);
    dispatch(analyticsEvents.videoWizardV2({ action: "closeVideoWizard", source: SOURCE }));
  };

  const onImportPresentation = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: SOURCE,
        type: NewVideoType.presentation
      })
    );
    dispatch(uiActions.setFtuVideoPreview(false));
    openModal(ModalName.presentationUploader);
  };

  const onClickDocs = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: SOURCE,
        type: NewVideoType.importDocument
      })
    );
    openModal(ModalName.startFromDocumentModal);
  };
  const onClickScript = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: SOURCE,
        type: NewVideoType.script
      })
    );
    openModal(ModalName.startFromScriptModal);
  };

  const onClickFromVideoWizardCard = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: SOURCE,
        type: NewVideoType.videoWizard
      })
    );
    dispatch(uiActions.setFtuVideoPreview(false));
    setIsVideoWizardV2ModalOpen(true);
    dispatch(analyticsEvents.videoWizardV2({ action: "openVideoWizard", source: SOURCE }));
  };

  const onClickCreateCharacterAction = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: SOURCE,
        type: NewVideoType.createCharacter
      })
    );
    openModal(ModalName.createVirtualTwin);
  };

  const items: MenuProps["items"] = useMemo(() => {
    const initialItems: MenuProps["items"] = [
      {
        className: "orientation-menu clickable",
        key: "any-size",
        icon: <Icon $color={theme.green4} className="far fa-arrows-maximize" />,
        onClick: () => onOrientationChange(Orientation.AnySize),
        label: <H1_TextSmall>{formatMessage(templatesPageMessages.anysize)}</H1_TextSmall>
      },
      {
        className: "orientation-menu clickable",
        key: "portrait",
        icon: <Icon $color={theme.blue4} className="far fa-rectangle-vertical" />,
        onClick: () => onOrientationChange(Orientation.Portrait),
        label: <H1_TextSmall>{formatMessage(templatesPageMessages.portrait)}</H1_TextSmall>
      },
      {
        className: "orientation-menu clickable",
        key: "landscape",
        icon: <Icon $color={theme.orange4} className="far fa-rectangle" />,
        onClick: () => onOrientationChange(Orientation.Landscape),
        label: <H1_TextSmall>{formatMessage(templatesPageMessages.landscape)}</H1_TextSmall>
      },
      {
        className: "orientation-menu clickable",
        key: "square",
        icon: <Icon $color={theme.pink4} className="far fa-square" />,
        onClick: () => onOrientationChange(Orientation.Square),
        label: <H1_TextSmall>{formatMessage(templatesPageMessages.square)}</H1_TextSmall>
      }
    ];

    return initialItems;
  }, [orientationText]);

  return (
    <BackgroundFlexColumn overflow="hidden" $isLoading={isLoading} flex="1" ref={fullPageRef}>
      <VideoWizardModalV2
        source={SOURCE}
        visible={isVideoWizardV2ModalOpen}
        onClose={onCloseVideoWizardModalV2}
      />
      <SearchFlexColumn padding="10px 0 10px 50px" width="100%" gap="15px">
        <H1_FlexRow gap="15px" width="100%" height="60px" position="relative" align="center">
          <SearchIcon className="fa-regular fa-search" />
          <RoundSearch
            allowClear
            onChange={onChangeSearch}
            onBlur={onBlurSearch}
            onClear={onClear}
            placeholder={
              isCollapse
                ? formatMessage(templatesPageMessages.shortSearchPlaceholder)
                : formatMessage(templatesPageMessages.headerSearchPlaceholder)
            }
            minWidth="444px"
            isFixedWidth
          />
          <ConditionalRender condition={isSalesQuickActionsDisplayed}>
            <HomePageActionButton
              dataAutoId="quick-action-zoom-bot-card"
              title={formatMessage(homePageMessages.fromOwnCharacter)}
              onClick={onClickCreateCharacterAction}
              height="50px"
              shadowColor={theme.gray5}
              borderColor={theme.gray5}
              icon={
                <IconContainer
                  width="33px"
                  height="33px"
                  justify="center"
                  align="center"
                  $backgroundColor={theme.gray3}
                >
                  <H1_Icon isCursorPointer color={theme.gray8} icon="fa-regular fa-user-plus" />
                </IconContainer>
              }
            />
          </ConditionalRender>
          <ConditionalRender condition={isLnDQuickActionsDisplayed}>
            <ConditionalRender condition={startFromDocsAndScriptFlag}>
              <HomePageActionButton
                dataAutoId="quick-action-document-card"
                title={formatMessage(homePageMessages.fromDocument)}
                onClick={onClickDocs}
                height="50px"
                shadowColor={theme.pink1}
                borderColor={theme.gray5}
                icon={
                  <IconContainer
                    flex="0 0 33px"
                    width="33px"
                    height="33px"
                    justify="center"
                    align="center"
                    $backgroundColor={theme.pink1}
                  >
                    <H1_Icon isCursorPointer color={theme.pink4} icon="fa-light fa-file" />
                  </IconContainer>
                }
              />
            </ConditionalRender>
            <HomePageActionButton
              dataAutoId="quick-action-presentation-card"
              title={formatMessage(homePageMessages.fromPresentationCard)}
              onClick={onImportPresentation}
              height="50px"
              shadowColor={theme.orange1}
              borderColor={theme.gray5}
              icon={
                <IconContainer
                  flex="0 0 33px"
                  width="33px"
                  height="33px"
                  justify="center"
                  align="center"
                  $backgroundColor={theme.orange1}
                >
                  <H1_Icon
                    color={theme.orange4}
                    isCursorPointer
                    icon="fa-regular fa-presentation-screen"
                  />
                </IconContainer>
              }
            />
            <ConditionalRender condition={startFromDocsAndScriptFlag}>
              <HomePageActionButton
                dataAutoId="quick-action-script-card"
                title={formatMessage(homePageMessages.fromScript)}
                onClick={onClickScript}
                height="50px"
                shadowColor={theme.gray6}
                borderColor={theme.gray5}
                icon={
                  <IconContainer
                    width="33px"
                    height="33px"
                    justify="center"
                    align="center"
                    $backgroundColor={theme.gray6}
                  >
                    <H1_Icon isCursorPointer color={theme.gray1} icon="fa-light fa-text" />
                  </IconContainer>
                }
              />
            </ConditionalRender>
          </ConditionalRender>
          <ConditionalRender condition={isProductQuickActionsDisplayed}>
            <HomePageActionButton
              dataAutoId="quick-action-video-wizard-card"
              title={formatMessage(homePageMessages.fromVideoWizardCard)}
              onClick={onClickFromVideoWizardCard}
              height="50px"
              shadowColor={theme.blue1}
              borderColor={theme.gray5}
              icon={
                <IconContainer
                  flex="0 0 33px"
                  width="33px"
                  height="33px"
                  justify="center"
                  align="center"
                  $backgroundColor={theme.blue1}
                >
                  <H1_Icon
                    isCursorPointer
                    color={theme.blue4}
                    icon="fa-regular fa-wand-magic-sparkles"
                  />
                </IconContainer>
              }
            />
          </ConditionalRender>
        </H1_FlexRow>
      </SearchFlexColumn>

      {/* Skeleton */}
      <ConditionalRender condition={isLoading}>
        <FullTemplatePageSkeleton />
      </ConditionalRender>
      <ConditionalRender condition={!isLoading}>
        {/* Categories */}
        <CategoriesFlexRow padding="0 50px" flex="0 0 70px" width="100%" gap="17px" align="center">
          <H1_FlexRow flex="0 0 auto" align="center" gap="17px">
            <H1_TextSmall color={theme.gray7}>
              {formatMessage(templatesPageMessages.filterBy)}
            </H1_TextSmall>
            <NextDropdown withWhiteBackground items={items}>
              <Box
                width="auto"
                align="center"
                height="29px"
                $isColored={orientation !== Orientation.AnySize}
              >
                <BoxTextSmall color={theme.gray9}>{orientationText}</BoxTextSmall>
                <H1_Icon
                  padding="0 5px 0 0"
                  icon="far fa-chevron-down"
                  color={orientation !== Orientation.AnySize ? theme.gray1 : theme.gray7}
                />
              </Box>
            </NextDropdown>
          </H1_FlexRow>
          <ChipsContainer />
        </CategoriesFlexRow>
        <H1_FlexColumn flex="1" ref={ref} overflow={isLoading ? "visible" : "hidden auto"}>
          <ConditionalRender condition={isSearching}>
            {/* Grid after search */}
            <H1_FlexRow padding="20px 50px" wrap="wrap" gap="28px">
              <ConditionalRender condition={!isEmptyState}>
                {orientationFlatMap.map((recipe: Recipe, index: number) => (
                  <SingleTemplateItem recipe={recipe} key={recipe.id || index} />
                ))}
              </ConditionalRender>
            </H1_FlexRow>
            <ConditionalRender condition={isEmptyState}>
              <H1_FlexColumn flex="1" justify="center" align="center" width="100%" height="100%">
                <FullTemplatesPageEmptyStates />
              </H1_FlexColumn>
            </ConditionalRender>
          </ConditionalRender>
          <ConditionalRender condition={!isSearching && selectedSectionsNames.length > 0}>
            <ConditionalRender condition={isEmptyState}>
              <H1_FlexColumn flex="1" justify="center" align="center" width="100%" height="100%">
                <FullTemplatesPageEmptyStates />
              </H1_FlexColumn>
            </ConditionalRender>
            <ConditionalRender condition={!isEmptyState}>
              {selectedSectionsNames.map((selectedSectionsName: string) => (
                <FullTemplatesPageGridCollection
                  key={selectedSectionsName}
                  orientation={orientation}
                  sectionName={selectedSectionsName}
                />
              ))}
            </ConditionalRender>
          </ConditionalRender>
          <ConditionalRender condition={!isSearching && selectedSectionsNames.length === 0}>
            {/* Split to categories */}
            <H1_FlexColumn flex="1 0 auto" padding="17px 0 0 0">
              <ConditionalRender condition={isEmptyState}>
                <H1_FlexColumn flex="1" justify="center" align="center" width="100%" height="100%">
                  <FullTemplatesPageEmptyStates />
                </H1_FlexColumn>
              </ConditionalRender>
              <ConditionalRender condition={!isEmptyState}>
                {categoriesNames.map((category: Partial<RecipeList>, index) => (
                  <H1_FlexColumn key={index}>
                    <FullTemplatesPageCollection
                      orientation={orientation}
                      numOfSlides={numOfSlides}
                      sectionName={category.name as string}
                      sectionId={category.id}
                    />
                  </H1_FlexColumn>
                ))}
              </ConditionalRender>
            </H1_FlexColumn>
          </ConditionalRender>
        </H1_FlexColumn>
      </ConditionalRender>
    </BackgroundFlexColumn>
  );
};

export default FullTemplatesPage;
