import React, { Ref, useEffect, useState } from "react";
import { FetchStatus } from "app/types";
import { fetchingStatus } from "app/utils/helpers";
import { fetchSecured } from "app/store/security";
import Config from "app/config/Config";
import ConditionalRender from "app/components/common/ConditionalRender";
import styled, { useTheme } from "styled-components";
import { useIntl } from "react-intl";
import { useAuth } from "app/auth/useAuth";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import messages from "app/components/common/player/messages";
import type { H1PlayerAnalyticsProps } from "app/components/_Infrastructure/core/h1-player-analytics";
import { H1PlayerAnalytics } from "app/components/_Infrastructure/core/h1-player-analytics";
import { SegmentContextContext } from "app/components/_Infrastructure/core/segment-context";
import MuxPlayerElement from "@mux/mux-player";

type WrappedH1PlayerProps = Omit<H1PlayerAnalyticsProps, "muxEnvKey" | "playbackId">;
interface MuxPlayerProps extends WrappedH1PlayerProps {
  reqId?: string;
  templateFlavor?: string;
  disableCC?: boolean;
  borderRadius?: string;
  overflow?: string;
  playbackId?: string;
  displayFeedbackBar?: boolean;
  videoId?: string;
  onVideoHalfPlayed?: () => void;
  onTimeUpdate?: (evt: Event) => void;
  width?: string;
  ref?: Ref<MuxPlayerElement> | undefined;
}

const DISABLE_FULLSCREEN_FLAVORS = ["1", "sb-face", "basic"];

const PlayerContainer = styled("div")<{
  $disableFS: boolean;
  $disableCC: boolean;
  $borderRadius?: string;
  $overflow?: string;
}>`
  width: 100%;
  height: auto;
  mux-player {
    --fullscreen-button: ${(props) => (props.$disableFS ? "none" : "initial")};
    --top-captions-button: none;
    --media-control-background: rgb(0 0 0 / 40%);
    --secondary-color: rgb(0 0 0 / 40%);
    --captions-button: ${(props) => (props.$disableCC ? "none" : "initial")};
    border-radius: ${(props) => (props.$borderRadius ? props.$borderRadius : "initial")};
    overflow: ${(props) => (props.$overflow ? props.$overflow : "initial")};
  }
`;

const PlayerFailure = styled("div")`
  text-align: center;
`;

const MuxPlayer = React.forwardRef<MuxPlayerElement, MuxPlayerProps>((props, ref) => {
  const {
    reqId,
    templateFlavor,
    muted = false,
    controls = true,
    loop = false,
    autoPlay = false,
    shape = "landscape",
    centerPlayButton = true,
    disableCC = false,
    borderRadius,
    playbackId = undefined,
    overflow,
    width,
    onVideoHalfPlayed,
    onTimeUpdate,
    ...other
  } = props;
  const [fetchMuxStatus, setFetchStatus] = useState<FetchStatus>(fetchingStatus.idle);
  const [muxPlaybackId, setMuxPlaybackId] = useState<string | undefined>(playbackId);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const intl = useIntl();
  const { user } = useAuth();
  const theme = useTheme();

  const analytics = React.useMemo(() => window?.analytics, [window?.analytics]);
  const calculateShouldTriggerFeedback = (evt: Event) => {
    if (onVideoHalfPlayed) {
      // @ts-ignore does not recognize event type
      const halfPlayed = evt.target.currentTime > evt.target.duration / 2;
      if (halfPlayed) {
        onVideoHalfPlayed();
      }
    }
    if (onTimeUpdate) {
      onTimeUpdate(evt);
    }
  };

  useEffect(() => {
    const fetchMuxPlaybackId = async () => {
      setFetchStatus(fetchingStatus.loading);
      setMuxPlaybackId(undefined);
      try {
        const res = await fetchSecured(`${Config.serverUrl}/v1/playback/${reqId}`);
        if (res.status === 200) {
          const body = res.data;
          if (body.playback_id === "") {
            setErrorMessage(intl.formatMessage(messages.genralError));
            setFetchStatus(fetchingStatus.failed);
          } else {
            setMuxPlaybackId(body.playback_id);
            setFetchStatus(fetchingStatus.idle);
          }
        } else if (res.status === 403) {
          setErrorMessage(intl.formatMessage(messages.unAuthorized));
        } else {
          throw Error(`unexpected status code from playback ${res.status}`);
        }
      } catch (e) {
        console.error(e);
        setFetchStatus(fetchingStatus.failed);
      }
    };
    if (reqId) {
      fetchMuxPlaybackId();
    }

    return () => {
      setFetchStatus(fetchingStatus.idle);
      setErrorMessage(undefined);
      setMuxPlaybackId(undefined);
    };
  }, [reqId]);

  return (
    <H1_FlexColumn width={width}>
      <SegmentContextContext.Provider value={{ analytics }}>
        <PlayerContainer
          data-reqid={reqId}
          $borderRadius={borderRadius}
          $overflow={overflow}
          $disableCC={disableCC}
          $disableFS={
            (templateFlavor && DISABLE_FULLSCREEN_FLAVORS.includes(templateFlavor)) || false
          }
        >
          <ConditionalRender condition={!!muxPlaybackId && fetchMuxStatus === fetchingStatus.idle}>
            <H1PlayerAnalytics
              ref={ref}
              accentColor={theme.blue4}
              trackEventsProps={{ reqId }}
              metadata={{
                // eslint-disable-next-line @typescript-eslint/naming-convention
                env_key: Config.env,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                video_id: reqId || "",
                // eslint-disable-next-line @typescript-eslint/naming-convention
                viewer_user_id: (user?.sub as string) || "anonymus"
              }}
              onTimeUpdate={calculateShouldTriggerFeedback}
              muxEnvKey={Config.muxEnvKey}
              playbackId={(playbackId || muxPlaybackId) as string}
              debug={["dev", "local"].includes(Config.env)}
              muted={muted}
              controls={controls}
              loop={loop}
              autoPlay={autoPlay} // boolean: Optional
              shape={shape}
              centerPlayButton={centerPlayButton} // boolean: Optional - should display inner play button
              {...other}
            />
          </ConditionalRender>
          <ConditionalRender condition={fetchMuxStatus === fetchingStatus.loading}>
            <CircleLoader />
          </ConditionalRender>
          <ConditionalRender condition={fetchMuxStatus === fetchingStatus.failed}>
            <PlayerFailure>
              <H1_TextSmall>
                {errorMessage || intl.formatMessage(messages.genralError)}
              </H1_TextSmall>
            </PlayerFailure>
          </ConditionalRender>
        </PlayerContainer>
      </SegmentContextContext.Provider>
    </H1_FlexColumn>
  );
});

MuxPlayer.displayName = "MuxPlayer";

export default MuxPlayer;
