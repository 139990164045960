import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import React from "react";
import styled, { useTheme } from "styled-components";
import { Button } from "@nextui-org/react";
import MuxPlayerElement from "@mux/mux-player";

const ControlButton = styled(Button)`
  i {
    font-size: 22px;
  }
`;

const AbsoluteFlexRow = styled(H1_FlexRow)`
  bottom: 0;
  left: 0;
  z-index: 100;
`;

const ProgressBarContainer = styled(H1_FlexRow)`
  background-color: ${({ theme }) => theme.gray4};
`;
const ProgressBarTrack = styled(H1_FlexRow)<{ $progress: number }>`
  background-color: ${({ theme }) => theme.gray7};
  width: ${({ $progress }) => $progress}%;
`;

const FontAwesomeIcon = styled.i`
  font-size: 28px;
  color: ${({ theme }) => theme.gray7};
`;

interface CGNPlayerControlsProps {
  currentTime: number;
  duration: number;
  isPlaying: boolean;
  isMuted: boolean;
  isFullScreen: boolean;
  handleFullScreen: () => void;
  handleMuteUnmute: () => void;
  handlePlayPause: () => void;
  videoLoaded: boolean;
  videoNode: MuxPlayerElement;
}
const CGNPlayerControls = ({
  currentTime,
  duration,
  isPlaying,
  isMuted,
  isFullScreen,
  handleFullScreen,
  handleMuteUnmute,
  handlePlayPause,
  videoNode
}: CGNPlayerControlsProps) => {
  const theme = useTheme();

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleProgressClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (videoNode) {
      const progressBar = event.currentTarget;
      const rect = progressBar.getBoundingClientRect();
      const totalWidth = progressBar.offsetWidth;
      const clickPosition = event.clientX - rect.left;
      const seekTime = (clickPosition / totalWidth) * videoNode.duration;
      videoNode.currentTime = seekTime;
    }
  };

  return (
    <AbsoluteFlexRow width="100%" position="absolute" gap="10px" align="center">
      <ControlButton
        size="sm"
        variant="light"
        isIconOnly
        startContent={<FontAwesomeIcon className={isPlaying ? "fas fa-pause" : "fas fa-play"} />}
        onClick={handlePlayPause}
      />
      <H1_FlexRow>
        <H1_TextXs color={theme.gray7}>
          {formatTime(currentTime)} / {formatTime(duration)}
        </H1_TextXs>
      </H1_FlexRow>
      <ProgressBarContainer flex="1" height="5px" onClick={handleProgressClick}>
        <ProgressBarTrack height="100%" $progress={(currentTime / duration) * 100} />
      </ProgressBarContainer>
      <H1_FlexRow gap="10px" align="center">
        <ControlButton
          size="sm"
          variant="light"
          isIconOnly
          startContent={
            <FontAwesomeIcon className={isMuted ? "fas fa-volume-slash" : "fas fa-volume"} />
          }
          onClick={handleMuteUnmute}
        />
        <ControlButton
          size="sm"
          variant="light"
          isIconOnly
          startContent={
            <FontAwesomeIcon className={isFullScreen ? "fas fa-compress" : "fas fa-expand"} />
          }
          onClick={handleFullScreen}
        />
      </H1_FlexRow>
    </AbsoluteFlexRow>
  );
};

export default CGNPlayerControls;
