import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import styled, { useTheme } from "styled-components";
import { MenuProps } from "antd";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ConditionalRender from "app/components/common/ConditionalRender";
import VideoTrimmer from "app/components/editor/sideDrawers/SceneDrawer/VideoTrimmer";
import { MediaTrimType, VideoAction, VideoFit, VideoScaleMethod } from "app/types/media";
import VideoCrop from "app/components/editor/sideDrawers/SceneDrawer/VideoCrop";
import { Area } from "react-easy-crop";
import VideoPreview from "./VideoPreview";
import { Point } from "react-easy-crop/types";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import {
  MediaAssetProperties,
  MediaCategory,
  PaletteColor,
  PaletteColorKey,
  PatchOperation,
  VideoAdjustment,
  VideoProperties
} from "app/types";
import { H1_TextSubtitle, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { useIntl } from "react-intl";
import { videoActionsMessages } from "app/components/editor/sideDrawers/SceneDrawer/messages";
import { H1_Dropdown } from "app/components/_Infrastructure/design-system/dropdown";
import { VideoActionButton } from "app/components/editor/sideDrawers/SceneDrawer/VideoActionButton";
import { scenesActions } from "app/store/slices/scenes.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { StyledSkeletonButton } from "app/components/common/Loaders/SkeletonButtonLoader";
import templatesSelectors from "app/store/selectorsV2/templates.selectors";
import { Button } from "@nextui-org/react";
import { isMediaPlaying } from "app/utils/helpers";
import { StyledModal } from "app/components/common/StyledModal";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import scenesSelectors from "app/store/selectorsV2/scenes.selectors";

const fps = 30;
enum VideoMode {
  None = "None",
  Fit = "Fit",
  Crop = "Crop",
  Trim = "Trim"
}

const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.blue4};
  position: absolute;
  top: 7px;
  right: 7px;
`;

const StyledDropdown = styled(H1_Dropdown)`
  color: ${(props) => props.theme.gray11};
  background-color: ${({ theme }) => theme.gray1};
  transition: background-color 0.3s ease-in-out;
  i {
    color: ${(props) => props.theme.gray11};
  }
  &:hover {
    background-color: ${({ theme }) => theme.gray2};
    color: ${(props) => props.theme.gray11};
    i {
      color: ${(props) => props.theme.gray11};
    }
  }
  padding: 10px;
  &.video-fill-type {
    .ant-dropdown {
      width: auto;
      .ant-dropdown-menu {
        padding: 10px;
        li.clickable:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
    max-height: 180px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.gray4};
    .clickable {
      border-radius: 5px;
      border: 1px solid ${({ theme }) => theme.gray4};
    }
    .selected {
      border: 1px solid ${({ theme }) => theme.blue4};
    }
    font-size: 12px;
    .ant-dropdown-menu-item {
      padding: 5px 10px;
      i {
        color: ${({ theme }) => theme.gray1};
      }
      &:hover {
        background-color: ${({ theme }) => theme.gray3};
      }
    }
    .ant-dropdown-menu-item-group-title {
      padding: 5px 10px;
    }
  }
`;

const HiddenAudio = styled("audio")`
  display: none;
`;

const Modal = styled(StyledModal)`
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    gap: 20px;
    padding: 0 40px 40px;
  }
`;

const ResetButton = styled(Button)`
  align-self: flex-end;
  position: absolute;
  right: 0;
  bottom: 8px;
`;

const HiddenVideo = styled.video`
  height: 460px;
  width: auto;
  aspect-ratio: 16 / 9;
  display: none;
`;

const HideShowFlexRow = styled(H1_FlexRow)<{ $visibility: boolean }>`
  display: ${({ $visibility }) => ($visibility ? "flex" : "none")};
`;

const MinHeightFlexColumn = styled(H1_FlexColumn)<{ $minHeight: number }>`
  min-height: ${({ $minHeight }) => `${$minHeight}px`};
`;

const Blue2Circle = styled(H1_FlexRow)`
  border-radius: 50%;
  display: inline-flex;
  color: ${({ theme }) => theme.gray1};
  background-color: ${({ theme }) => theme.blue2};
`;

interface VideoSettingsModalProps {
  initialVideoFit: VideoFit;
  visible: boolean;
  onClose: (properties?: {
    duration: number;
    videoStartTime: number;
    videoEndedTime: number;
    trimType: MediaTrimType;
  }) => void;
  url: string;
  ratioX: number;
  ratioH: number;
  attributeType: MediaCategory;
  assetKey: string;
  assetProperties: MediaAssetProperties;
  sceneId: string;
  draftId: string;
  sceneIdx: number;
  source: string;
  onRetryRecord?: () => void;
  audioUrl?: string;
}
const VideoSettingsModal = ({
  sceneIdx,
  initialVideoFit,
  visible,
  onClose,
  url,
  ratioX,
  ratioH,
  assetKey,
  assetProperties,
  attributeType,
  sceneId,
  draftId,
  source,
  onRetryRecord,
  audioUrl
}: VideoSettingsModalProps) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [durationInFrames, setDurationInFrames] = useState(0);
  const [fillType, setFillType] = useState<MediaTrimType>(MediaTrimType.adaptSpeed);
  const [videoStartTime, setVideoStartTime] = useState(0);
  const [videoEndedTime, setVideoEndedTime] = useState(0);
  const [videoMode, setVideoMode] = useState(VideoMode.None);
  const [videoFit, setVideoFit] = useState(initialVideoFit);
  const [padColor, setPadColor] = useState<PaletteColor>();
  const [duration, setDuration] = useState<number | null>(null);
  const [trimmedDurationInFrames, setTrimmedDurationInFrames] = useState<number | null>(null);
  const [originalWidth, setOriginalWidth] = useState<number | null>(null);
  const [originalHeight, setOriginalHeight] = useState<number | null>(null);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [cropArea, setCropArea] = useState<Area>({
    x: 0,
    y: 0,
    width: 0,
    height: 0
  });
  const [scaleSize, setScaleSize] = useState<number>(1);
  const [isVideoAndAudioPlaying, setIsVideoAndAudioPlaying] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { scene } = useSelectedScene();
  const appliedPalette = useAppSelector(templatesSelectors.getAppliedPalette);
  const isBrollOnScene = useAppSelector((state) =>
    scenesSelectors.isBrollOnScene(state, sceneId, assetKey)
  );

  const brollEnabled =
    !!scene?.attributes?.[attributeType]?.[assetKey]?.preset_override?.audio_usage?.enabled;
  const isVideoModeNone = videoMode === VideoMode.None;
  const isVideoModeTrim = videoMode === VideoMode.Trim;
  const isVideoModeFit = videoMode === VideoMode.Fit;
  const isVideoFitCrop = videoFit === VideoFit.Crop;

  const videoHeight = isVideoModeTrim ? 420 : 460;

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoPreviewRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (duration) {
      let result = Math.floor((videoEndedTime - videoStartTime) * fps);
      if (isVideoModeTrim || !isVideoLoaded) {
        result = Math.floor(duration * fps);
      }
      setTrimmedDurationInFrames(Math.floor(result));
    }
  }, [videoEndedTime, videoStartTime, isVideoModeTrim, duration, isVideoLoaded]);

  const aspectRatio = useMemo(() => (ratioH === 0 ? 0 : ratioX / ratioH), [ratioX, ratioH]);

  const header = useMemo(() => {
    switch (videoMode) {
      case VideoMode.None:
        return onRetryRecord
          ? formatMessage(videoActionsMessages.screenRecordingSettings)
          : formatMessage(videoActionsMessages.videoSettings);
      case VideoMode.Trim:
        return formatMessage(videoActionsMessages.trimVideo);
      case VideoMode.Fit:
        return formatMessage(videoActionsMessages.fitVideo);
      case VideoMode.Crop:
        return formatMessage(videoActionsMessages.cropVideo);
      default:
        return formatMessage(videoActionsMessages.videoSettings);
    }
  }, [videoFit, videoMode]);

  const playbackTitle = useMemo(() => {
    switch (fillType) {
      case MediaTrimType.loop:
        return formatMessage(videoActionsMessages.loop);
      case MediaTrimType.freeze:
        return formatMessage(videoActionsMessages.freeze);
      case MediaTrimType.adaptSpeed:
        return formatMessage(videoActionsMessages.interpolate);
      default:
        return formatMessage(videoActionsMessages.playback);
    }
  }, [fillType]);

  const fillTypeIcon = useMemo(() => {
    switch (fillType) {
      case MediaTrimType.loop:
        return "far fa-rotate";
      case MediaTrimType.freeze:
        return "far fa-arrow-right-from-line";
      case MediaTrimType.adaptSpeed:
        return "far fa-arrows-left-right";
      default:
        return "far fa-rotate";
    }
  }, [fillType]);

  useEffect(() => {
    if (visible) {
      if (assetProperties?.video_adjustments?.length) {
        const { properties } = assetProperties;
        let selectedPaletteColor;
        assetProperties.video_adjustments.forEach((videoAdjustment: VideoAdjustment) => {
          switch (videoAdjustment.action) {
            case VideoAction.CROP:
              setCropArea({
                x: videoAdjustment.start_width as number,
                y: videoAdjustment.start_height as number,
                width: videoAdjustment.width as number,
                height: videoAdjustment.height as number
              });
              if (properties) {
                setScaleSize(properties.scale as number);
                setCrop({ x: properties.pos_x as number, y: properties.pos_y as number });
              }
              break;
            case VideoAction.FREEZE:
              setFillType(MediaTrimType.freeze);
              break;
            case VideoAction.LOOP:
              setFillType(MediaTrimType.loop);
              break;
            case VideoAction.INTERPOLATE:
              setFillType(MediaTrimType.adaptSpeed);
              break;
            case VideoAction.TRIM:
              setVideoStartTime((videoAdjustment.trim as [number, number])[0]);
              setVideoEndedTime((videoAdjustment.trim as [number, number])[1]);
              break;
            case VideoAction.PAD_FIT:
              selectedPaletteColor = appliedPalette?.find(
                (paletteColor) => paletteColor.key === `color_${videoAdjustment.color_key}`
              );
              if (selectedPaletteColor) {
                setPadColor(selectedPaletteColor);
                setVideoFit(VideoFit.Pad);
              }
          }
        });
      } else {
        setFillType(MediaTrimType.loop);
        setVideoStartTime(0);
        setVideoEndedTime(0);
        setVideoMode(VideoMode.None);
        setVideoFit(VideoFit.Scale);
        setScaleSize(1);
        setCrop({ x: 0, y: 0 });
        setCropArea({ x: 0, y: 0, width: 0, height: 0 });
        const selectedPaletteColor = appliedPalette?.find(
          (paletteColor) => paletteColor.key === `color_A1`
        );
        if (selectedPaletteColor) {
          setPadColor(selectedPaletteColor);
          setVideoFit(VideoFit.Pad);
        }
      }
    }
    if (!visible) {
      setVideoStartTime(0);
      setVideoEndedTime(0);
      setVideoMode(VideoMode.None);
      setDurationInFrames(0);
      setDuration(null);
      setIsVideoLoaded(false);
    }
  }, [visible]);

  useEffect(() => {
    const video = videoRef.current;

    const handlePlay = () => {
      if (!video) return;
      if (video.currentTime >= videoEndedTime) {
        video.currentTime = videoStartTime;
        video.play();
      }
    };

    const handleSeeking = () => {
      if (!video) return;
      if (video.currentTime < videoStartTime || video.currentTime > videoEndedTime) {
        video.pause();
      }
    };

    video?.addEventListener("play", handlePlay);
    video?.addEventListener("seeking", handleSeeking);

    return () => {
      video?.removeEventListener("play", handlePlay);
      video?.removeEventListener("seeking", handleSeeking);
    };
  }, [videoStartTime, videoEndedTime]);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = videoStartTime;
    }
  }, [videoStartTime]);

  const resetVideoTimeAndPause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = videoStartTime;

      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
  };

  const onEndedVideo = () => {
    if (audioRef.current && videoRef.current) {
      setIsVideoAndAudioPlaying(false);
      resetVideoTimeAndPause();
    }
  };

  const onClickReset = () => {
    setVideoStartTime(0);
    setVideoEndedTime(duration as number);
    setVideoMode(VideoMode.None);
    setFillType(MediaTrimType.loop);
    setVideoFit(VideoFit.Scale);
    setScaleSize(1);
    setCrop({ x: 0, y: 0 });
    setCropArea({ x: 0, y: 0, width: 0, height: 0 });
    resetVideoTimeAndPause();
    onSaveVideoActions({
      scale: 1,
      cropArea: { x: 0, y: 0, width: 0, height: 0 },
      crop: { x: 0, y: 0 },
      videoFit: VideoFit.Scale,
      fillType: MediaTrimType.loop,
      videoStart: 0,
      videoFinish: duration as number
    });
    dispatch(
      analyticsEvents.videoActions({
        status: "apply",
        draftId,
        sceneNumber: sceneIdx,
        action: "resetAll",
        origin: source
      })
    );
  };

  const handleDurationInFrames = (frames: number) => {
    setDurationInFrames(frames);
  };

  const onVideoLoaded = () => {
    setIsVideoLoaded(true);
  };

  const onClickTrim = () => {
    setVideoMode(VideoMode.Trim);
    resetVideoTimeAndPause();
  };

  const onClickBRoll = () => {
    const operations: PatchOperation[] = [];
    operations.push({
      op: "replace",
      path: `attributes.${attributeType}.${assetKey}.preset_override.audio_usage`,
      value: { enabled: !brollEnabled, generate_face: true }
    });

    dispatch(
      scenesActions.patchSceneRequest({
        draftId,
        sceneId,
        operations,
        skipRePreview: true
      })
    );
  };

  const onClickFit = () => {
    setVideoMode(VideoMode.Fit);
    resetVideoTimeAndPause();
  };

  const onClickPlay = () => {
    if (audioRef.current && videoRef.current && videoPreviewRef.current) {
      if (isMediaPlaying(videoPreviewRef.current)) {
        if (videoPreviewRef.current) {
          videoPreviewRef.current.pause();
          setIsVideoPlaying(false);
        }
        setIsVideoAndAudioPlaying(false);
        videoRef.current.pause();
        audioRef.current.pause();
      } else {
        if (videoPreviewRef.current) {
          videoPreviewRef.current.play();
          setIsVideoPlaying(true);
        }
        setIsVideoAndAudioPlaying(true);
        videoRef.current.play();
        audioRef.current.play();
      }
    }
  };

  const onClickCancel = (initialVideoFit?: VideoFit) => {
    setVideoMode(VideoMode.None);
    resetVideoTimeAndPause();
    if (initialVideoFit) {
      setVideoFit(initialVideoFit);
    }
  };

  const onUpdateCurrentVideoFit = (currentVideoFit: VideoFit) => {
    setVideoFit(currentVideoFit);
  };

  const onChangePadColor = (color: PaletteColor) => {
    setPadColor(color);
  };

  const onSaveVideoActions = ({
    scale: newScale,
    cropArea: newCropArea,
    crop: newCrop,
    videoFit: newVideoFit,
    videoStart: newVideoStart,
    videoFinish: newVideoFinish,
    fillType: newFillType
  }: {
    scale?: number;
    cropArea?: Area;
    crop?: Point;
    videoFit: VideoFit;
    videoStart?: number;
    videoFinish?: number;
    fillType?: MediaTrimType;
  }) => {
    const videoAdjustments: VideoAdjustment[] = [];
    let properties: VideoProperties | undefined;
    if ((newVideoFinish || videoEndedTime) > (newVideoStart ?? videoStartTime)) {
      videoAdjustments.push({
        action: VideoAction.TRIM,
        trim: [
          (newVideoStart ?? videoStartTime) as number,
          (newVideoFinish || videoEndedTime) as number
        ]
      });
    }
    if (newVideoFit === VideoFit.Crop) {
      properties = {
        scale: (newScale || scaleSize) as number,
        pos_x: newCrop ? newCrop.x : crop.x,
        pos_y: newCrop ? newCrop.y : crop.y
      };
      videoAdjustments.push({
        action: VideoAction.CROP,
        start_width: newCropArea ? newCropArea.x : cropArea.x,
        start_height: newCropArea ? newCropArea.y : cropArea.y,
        width: newCropArea ? newCropArea.width : cropArea.width,
        height: newCropArea ? newCropArea.height : cropArea.height
      });
    } else if (newVideoFit === VideoFit.Scale) {
      const isLandscape = ratioX > ratioH;
      const scaleWidth = isLandscape ? originalWidth : ((originalWidth || 1) * ratioH) / ratioX;
      const scaleHeight = isLandscape ? ((originalHeight || 1) * ratioX) / ratioH : originalHeight;
      videoAdjustments.push({
        action: VideoAction.SCALE,
        method: VideoScaleMethod.SCALE,
        width: scaleWidth as number,
        height: scaleHeight as number
      });
    } else if (newVideoFit === VideoFit.Pad) {
      const isLandscape = ratioX > ratioH;
      const ratio = ratioX / ratioH;
      const FULL_HD_WIDTH = 1920;
      const FULL_HD_HEIGHT = 1080;
      const scaleHeight = isLandscape ? FULL_HD_WIDTH / ratio : FULL_HD_HEIGHT;
      const roundHeight = Math.round(scaleHeight as number);
      const evenHeight = roundHeight % 2 === 0 ? roundHeight : roundHeight + 1;
      const scaleWidth = isLandscape ? FULL_HD_WIDTH : FULL_HD_HEIGHT * ratio;
      const roundWidth = Math.round(scaleWidth as number);
      const evenWidth = roundWidth % 2 === 0 ? roundWidth : roundWidth + 1;

      videoAdjustments.push({
        action: VideoAction.PAD_FIT,
        method: VideoScaleMethod.COLOR,
        color_key: padColor?.key
          ? padColor.key.split("_")[1]
          : PaletteColorKey.color_A1.split("_")[1],
        width: evenWidth,
        height: evenHeight
      });
    }
    if (newFillType) {
      if (newFillType === MediaTrimType.adaptSpeed) {
        videoAdjustments.push({
          action: VideoAction.INTERPOLATE
        });
      }
      if (newFillType === MediaTrimType.freeze) {
        videoAdjustments.push({
          action: VideoAction.FREEZE
        });
      }
      if (newFillType === MediaTrimType.loop) {
        videoAdjustments.push({
          action: VideoAction.LOOP
        });
      }
    } else {
      if (fillType === MediaTrimType.adaptSpeed) {
        videoAdjustments.push({
          action: VideoAction.INTERPOLATE
        });
      }
      if (fillType === MediaTrimType.freeze) {
        videoAdjustments.push({
          action: VideoAction.FREEZE
        });
      }
      if (fillType === MediaTrimType.loop) {
        videoAdjustments.push({
          action: VideoAction.LOOP
        });
      }
    }

    const operations: PatchOperation[] = [
      {
        op: "replace",
        path: `attributes.${attributeType}.${assetKey}`,
        value: {
          ...assetProperties,
          video_adjustments: videoAdjustments,
          properties
        }
      }
    ];

    dispatch(
      scenesActions.patchSceneRequest({
        draftId,
        sceneId,
        operations
      })
    );
  };

  const onClickApplyCrop = (
    newVideoFit: VideoFit,
    newCrop: Point,
    newCropArea: Area,
    scale: number
  ) => {
    resetVideoTimeAndPause();
    setScaleSize(scale);
    setVideoMode(VideoMode.None);
    setCrop(newCrop);
    setCropArea(newCropArea);
    setVideoFit(newVideoFit);
    onSaveVideoActions({ scale, cropArea: newCropArea, crop: newCrop, videoFit: newVideoFit });
    dispatch(
      analyticsEvents.videoActions({
        status: "apply",
        draftId,
        sceneNumber: sceneIdx,
        action: newVideoFit,
        origin: source
      })
    );
  };

  const onClickApplyTrim = ([min, max]: [number, number]) => {
    resetVideoTimeAndPause();
    setVideoMode(VideoMode.None);
    setVideoStartTime(min);
    setVideoEndedTime(max);
    onSaveVideoActions({ videoStart: min, videoFinish: max, videoFit });
    dispatch(
      analyticsEvents.videoActions({
        status: "apply",
        draftId,
        sceneNumber: sceneIdx,
        action: "trim",
        value: `${min} ${max}`,
        origin: source
      })
    );
  };

  const handleMetadataLoaded = () => {
    const currentVideo = videoRef.current;
    if (currentVideo?.duration === Infinity) {
      currentVideo.currentTime = 1000;
      fixVideoDuration();
    } else if (currentVideo) {
      const { duration, videoWidth, videoHeight } = currentVideo;
      setDuration(duration);
      setOriginalWidth(videoWidth);
      setOriginalHeight(videoHeight);
      if (videoEndedTime === 0) {
        setVideoEndedTime(duration);
      }
    }
  };

  const fixVideoDuration = (retry = 0) => {
    const video = videoRef.current;
    // hack for loading duration infinity
    if (retry === 10) {
      console.warn("failed load duration");
      return;
    }
    if (video && video.duration === Infinity) {
      setTimeout(() => {
        fixVideoDuration(retry + 1);
      }, 1000);
    } else if (video) {
      setDuration(video?.duration as number);
      video.currentTime = 0;
      setOriginalWidth(video?.videoWidth);
      setOriginalHeight(video?.videoHeight);
      if (videoEndedTime === 0) {
        setVideoEndedTime(video?.duration);
      }
    }
  };

  const onSelectLoop = () => {
    setFillType(MediaTrimType.loop);
    onSaveVideoActions({ fillType: MediaTrimType.loop, videoFit });
    dispatch(
      analyticsEvents.videoActions({
        status: "apply",
        draftId,
        sceneNumber: sceneIdx,
        action: "changePlayback",
        value: "Loop",
        origin: source
      })
    );
  };
  const onSelectFreeze = () => {
    setFillType(MediaTrimType.freeze);
    onSaveVideoActions({ fillType: MediaTrimType.freeze, videoFit });
    dispatch(
      analyticsEvents.videoActions({
        status: "apply",
        draftId,
        sceneNumber: sceneIdx,
        action: "changePlayback",
        value: "Freeze",
        origin: source
      })
    );
  };
  const onSelectInterpolate = () => {
    setFillType(MediaTrimType.adaptSpeed);
    onSaveVideoActions({ fillType: MediaTrimType.adaptSpeed, videoFit });
    dispatch(
      analyticsEvents.videoActions({
        status: "apply",
        draftId,
        sceneNumber: sceneIdx,
        action: "changePlayback",
        value: "Interpolate",
        origin: source
      })
    );
  };

  const onClickDone = () => {
    // Screen recording
    if (audioUrl) {
      onSaveVideoActions({
        scale: scaleSize,
        cropArea,
        crop,
        videoFit,
        fillType,
        videoStart: videoStartTime,
        videoFinish: videoEndedTime
      });
    }
    onClose();
  };

  const playBackItems: MenuProps["items"] = useMemo(() => {
    return [
      {
        className: `playback-menu clickable ${fillType === MediaTrimType.loop ? "selected" : ""}`,
        key: "loop",
        icon: (
          <Blue2Circle justify="center" align="center" width="36px" height="36px">
            <H1_Icon icon="far fa-rotate" color={theme.gray1} />
          </Blue2Circle>
        ),
        onClick: onSelectLoop,
        label: (
          <H1_FlexColumn>
            <H1_TextXs color={theme.gray5}>{formatMessage(videoActionsMessages.loop)}</H1_TextXs>
            <H1_TextXs color={theme.gray7}>
              {formatMessage(videoActionsMessages.loopInfo)}
            </H1_TextXs>
          </H1_FlexColumn>
        )
      },
      {
        className: `playback-menu clickable ${fillType === MediaTrimType.freeze ? "selected" : ""}`,
        key: "freeze",
        icon: (
          <Blue2Circle justify="center" align="center" width="36px" height="36px">
            <H1_Icon icon="far fa-arrow-right-from-line" color={theme.gray1} />
          </Blue2Circle>
        ),
        onClick: onSelectFreeze,
        label: (
          <H1_FlexColumn>
            <H1_TextXs color={theme.gray5}>{formatMessage(videoActionsMessages.freeze)}</H1_TextXs>
            <H1_TextXs color={theme.gray7}>
              {formatMessage(videoActionsMessages.freezeInfo)}
            </H1_TextXs>
          </H1_FlexColumn>
        )
      },
      {
        className: `playback-menu clickable ${
          fillType === MediaTrimType.adaptSpeed ? "selected" : ""
        }`,
        key: "interpolate",
        icon: (
          <Blue2Circle justify="center" align="center" width="36px" height="36px">
            <H1_Icon icon="far fa-arrows-left-right" color={theme.gray1} />
          </Blue2Circle>
        ),
        onClick: onSelectInterpolate,
        label: (
          <H1_FlexColumn>
            <H1_TextXs color={theme.gray5}>
              {formatMessage(videoActionsMessages.interpolate)}
            </H1_TextXs>
            <H1_TextXs color={theme.gray7}>
              {formatMessage(videoActionsMessages.interpolateInfo)}
            </H1_TextXs>
          </H1_FlexColumn>
        )
      }
    ];
  }, [fillType, originalWidth, originalHeight, sceneId, theme.mode]);

  const handleTimeUpdate = (time: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time;
    }
  };

  const handlePlay = () => {
    const currentVideo = videoRef.current;
    if (currentVideo) {
      currentVideo.play();
    }
    if (videoPreviewRef.current) {
      videoPreviewRef.current.play();
      setIsVideoPlaying(true);
    }
  };

  const handlePause = () => {
    if (videoPreviewRef.current) {
      videoPreviewRef.current.pause();
      setIsVideoPlaying(false);
    }
  };

  return (
    <Modal
      width="895px"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "710px" }}
      onCancel={() => onClose()}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      maskClosable={false}
    >
      <HiddenAudio ref={audioRef} controls src={audioUrl}>
        <track kind="captions" />
      </HiddenAudio>
      <H1_FlexColumn flex="1 1 auto" width="100%">
        <H1_FlexRow height="85px" align="center" width="100%" position="relative">
          <H1_TextSubtitle lineHeight="85px">{header}</H1_TextSubtitle>
          <ResetButton
            startContent={<i className="far fa-rotate-left" />}
            variant="light"
            size="sm"
            onClick={onClickReset}
          >
            {formatMessage(videoActionsMessages.resetAll)}
          </ResetButton>
        </H1_FlexRow>
        <HiddenVideo
          onLoadedMetadata={handleMetadataLoaded}
          muted
          ref={videoRef}
          src={url}
          autoPlay
          controls
          onEnded={onEndedVideo}
        />
        <ConditionalRender condition={!duration}>
          <MinHeightFlexColumn $minHeight={videoHeight} flex="1" position="relative">
            <StyledSkeletonButton
              active
              block
              $height={`${videoHeight}px`}
              $width={`calc(${videoHeight}px * 16 / 9)`}
            />
            <H1_FlexRow position="absolute" width="100%" height="100%">
              <CircleLoader />
            </H1_FlexRow>
          </MinHeightFlexColumn>
        </ConditionalRender>
        <MinHeightFlexColumn
          $minHeight={videoHeight}
          gap="20px"
          justify="space-between"
          height="100%"
        >
          {duration && (
            <HideShowFlexRow
              $visibility={!isVideoFitCrop || isVideoModeTrim || isVideoModeNone}
              width="100%"
              justify="center"
            >
              <VideoPreview
                ref={videoPreviewRef}
                cropAspectRatio={aspectRatio}
                videoFit={videoFit}
                padColor={padColor}
                originalWidth={originalWidth as number}
                originalHeight={originalHeight as number}
                durationInFrames={trimmedDurationInFrames || Math.floor(duration * fps) || 1}
                src={url}
                scaleSize={scaleSize}
                cropX={cropArea.x || 0}
                cropY={cropArea.y || 0}
                trimStart={isVideoModeTrim ? 0 : videoStartTime}
                trimEnd={isVideoModeTrim ? duration : videoEndedTime}
                duration={duration}
                onDurationInFrames={handleDurationInFrames}
                onVideoLoaded={onVideoLoaded}
                isVideoLoaded={isVideoLoaded}
                width={Math.floor(videoHeight * aspectRatio)}
                height={videoHeight}
                currentTime={videoRef.current?.currentTime}
                onTimeUpdate={handleTimeUpdate}
                showControls={!isVideoModeTrim}
                isPlaying={isVideoPlaying}
                onPlay={handlePlay}
                onPause={handlePause}
              />
            </HideShowFlexRow>
          )}

          {/* No selection */}
          <ConditionalRender condition={isVideoModeNone && isVideoLoaded}>
            <H1_FlexRow justify="center" gap="20px">
              <ConditionalRender condition={!!audioUrl}>
                <VideoActionButton
                  $blue
                  isDisabled={!originalWidth}
                  onClick={onClickPlay}
                  variant="light"
                  startContent={
                    <H1_Icon
                      color={theme.blue4}
                      icon={isVideoAndAudioPlaying ? "fas fa-pause" : "fas fa-play"}
                    />
                  }
                >
                  {formatMessage(videoActionsMessages.play)}
                </VideoActionButton>
              </ConditionalRender>
              <VideoActionButton
                isDisabled={!originalWidth}
                onClick={onClickFit}
                variant="light"
                startContent={<H1_Icon icon="far fa-crop" />}
              >
                {formatMessage(videoActionsMessages.fit)}
              </VideoActionButton>
              <VideoActionButton
                isDisabled={!originalWidth}
                onClick={onClickTrim}
                variant="light"
                startContent={<H1_Icon icon="far fa-scissors" />}
              >
                {formatMessage(videoActionsMessages.trim)}
                <ConditionalRender condition={videoStartTime !== 0 || videoEndedTime !== duration}>
                  <Dot />
                </ConditionalRender>
              </VideoActionButton>
              <StyledDropdown
                disabled={!originalWidth}
                icon={<i className={fillTypeIcon} />}
                title={playbackTitle}
                destroyPopupOnHide
                trigger={["click"]}
                menu={{ items: playBackItems }}
                className="video-fill-type"
                getPopupContainer={() => document.querySelector(`.video-fill-type`) as HTMLElement}
                withChevron
              >
                <H1_Icon icon="fa-regular fa-chevron-down" size="22px" />
              </StyledDropdown>
              <ConditionalRender condition={!audioUrl && attributeType !== "media"}>
                <VideoActionButton
                  $selected={brollEnabled}
                  isDisabled={!originalWidth || isBrollOnScene}
                  onClick={onClickBRoll}
                  variant="light"
                  startContent={
                    <H1_Icon icon={brollEnabled ? "far fa-volume" : "far fa-volume-xmark"} />
                  }
                >
                  {brollEnabled
                    ? formatMessage(videoActionsMessages.brollOn)
                    : formatMessage(videoActionsMessages.brollOff)}
                </VideoActionButton>
              </ConditionalRender>
            </H1_FlexRow>
          </ConditionalRender>

          {/* Trim */}
          <ConditionalRender condition={isVideoModeTrim}>
            <VideoTrimmer
              video={videoPreviewRef.current}
              onClickCancel={onClickCancel}
              onClickApply={onClickApplyTrim}
              videoStartTime={videoStartTime}
              videoEndedTime={videoEndedTime}
              durationInFrames={durationInFrames}
              videoFit={videoFit}
              onPlay={handlePlay}
              onPause={handlePause}
              isPlaying={isVideoPlaying}
            />
          </ConditionalRender>

          {/* Fit & Crop */}
          <ConditionalRender condition={isVideoModeFit}>
            <VideoCrop
              src={url}
              aspectRatio={aspectRatio}
              onClickCancel={onClickCancel}
              onUpdateCurrentVideoFit={onUpdateCurrentVideoFit}
              onChangePadColor={onChangePadColor}
              onClickApplyCrop={onClickApplyCrop}
              padColor={padColor}
              initialScale={scaleSize}
              initialCrop={crop as Point}
              initialCropArea={cropArea as Area}
              videoFit={videoFit}
            />
          </ConditionalRender>
        </MinHeightFlexColumn>
      </H1_FlexColumn>
      {/* Buttons */}
      <ConditionalRender condition={isVideoModeNone}>
        <H1_FlexColumn flex="0 0 38px" align="flex-end" width="100%">
          <H1_FlexRow gap="20px">
            <ConditionalRender condition={!!onRetryRecord}>
              <Button isDisabled={!originalWidth} onClick={onRetryRecord} variant="light">
                {formatMessage(videoActionsMessages.recordAgain)}
              </Button>
            </ConditionalRender>
            <Button color="primary" onClick={onClickDone}>
              {formatMessage(
                onRetryRecord ? videoActionsMessages.useRecording : videoActionsMessages.doneBtn
              )}
            </Button>
          </H1_FlexRow>
        </H1_FlexColumn>
      </ConditionalRender>
    </Modal>
  );
};

export default VideoSettingsModal;
