import { ChannelsTabs, defiance, DEFIANCE, FeatureFlag, LiveVideoMode, Location } from "app/types";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { Tab } from "@nextui-org/tabs";
import React from "react";
import styled from "styled-components";
import { Button, Switch, Tabs } from "@nextui-org/react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as HourOneLogo } from "app/assets/images/h1loginlogo.svg";
import ConditionalRender from "app/components/common/ConditionalRender";
import WatchNextModal from "app/pages/CGN/WatchNextModal";
import { useFlags } from "launchdarkly-react-client-sdk";
import UploadVideoModal from "app/pages/CGN/UploadVideoModal";

const WhiteButton = styled(Button)`
  color: white;
`;

const LiveTabs = styled(Tabs)`
  && > div {
    background-color: ${({ theme }) => theme.gray2};
    padding: 4px;
    border-radius: 8px;

    div[data-slot="tabContent"] {
      color: ${({ theme }) => theme.gray6};
    }

    button[aria-selected="true"] > [data-slot="cursor"] {
      color: ${({ theme }) => theme.gray10};
      background-color: ${({ theme }) => theme.gray4}; /* Darker background color */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Darker shadow */
      opacity: 1;

      ~ div[data-slot="tabContent"] {
        color: ${({ theme }) => theme.gray10};
      }
    }
  }
`;

const LiveContainer = styled(H1_FlexRow)`
  border-radius: 14px;
  border: 1px solid #3b3b3b;
  background-color: #3b3b3b;
`;

const RedIcon = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #f31260;
`;

const FlexLink = styled(Link)`
  display: flex;
  svg {
    width: fit-content;
  }
`;

const StyledHourOneLogo = styled(HourOneLogo)<{ $isMainPage: boolean }>`
  margin-left: 5px;
  g path {
    fill: ${(props) => (props.$isMainPage ? "white" : props.theme.gray11)};
  }
  g path:last-child {
    fill: ${(props) => props.theme.blue4};
  }
`;

interface CGNHeaderProps {
  activeTab: ChannelsTabs | DEFIANCE;
  onChangeActiveTab: (tab: ChannelsTabs | DEFIANCE) => void;
  isMainPage?: boolean;
  onChangeVideoMode?: (videoMode: LiveVideoMode) => void;
  videoMode?: LiveVideoMode;
  setIsModalOpen?: (isOpen: boolean) => void;
}

const CGNHeader = ({
  activeTab,
  onChangeActiveTab,
  isMainPage = false,
  onChangeVideoMode,
  videoMode,
  setIsModalOpen
}: CGNHeaderProps) => {
  const [showPromptModal, setShowPromptModal] = React.useState(false);
  const [showUploadVideoModal, setShowUploadVideoModal] = React.useState(false);
  const navigate = useNavigate();
  const flags = useFlags();

  const onClickLiveButton = () => {
    setShowPromptModal(true);
    if (setIsModalOpen) {
      setIsModalOpen(true);
    }
  };

  const onClickUploadVideoButton = () => {
    setShowUploadVideoModal(true);
    if (setIsModalOpen) {
      setIsModalOpen(true);
    }
  };

  const onCloseModal = () => {
    setShowPromptModal(false);
    setShowUploadVideoModal(false);
    if (setIsModalOpen) {
      setIsModalOpen(false);
    }
  };

  const onCategory = (name: ChannelsTabs | DEFIANCE) => {
    if (videoMode === LiveVideoMode.Player) {
      navigate(`${Location.Live}/${name}`);
    } else if (videoMode === LiveVideoMode.FullScreen) {
      onChangeActiveTab(name);
    }
  };

  const onSwitchChange = (value: boolean) => {
    if (onChangeVideoMode) {
      onChangeVideoMode(value ? LiveVideoMode.Player : LiveVideoMode.FullScreen);
    }
  };
  return (
    <>
      <WatchNextModal visible={showPromptModal} onClose={onCloseModal} />
      <UploadVideoModal visible={showUploadVideoModal} onClose={onCloseModal} />
      <H1_FlexRow flex="0 0 60px" height="60px" justify="space-between" padding="10px">
        <H1_FlexRow gap="10px" align="center" position="relative">
          <FlexLink to={Location.Home}>
            <StyledHourOneLogo $isMainPage={isMainPage} width="100%" height={38} />
          </FlexLink>
          <FlexLink to={Location.Live}>
            <LiveContainer
              height="28px"
              width="76px"
              gap="8px"
              justify="center"
              align="center"
              padding="0 8px"
            >
              <RedIcon />
              <H1_TextXs color="white">LIVE</H1_TextXs>
            </LiveContainer>
          </FlexLink>
          <ConditionalRender condition={isMainPage}>
            <H1_FlexRow gap="8px" align="center">
              <WhiteButton variant="light" onClick={() => onCategory(ChannelsTabs.news)}>
                News
              </WhiteButton>
              <WhiteButton variant="light" onClick={() => onCategory(ChannelsTabs.entertainment)}>
                Entertainment
              </WhiteButton>
              <WhiteButton variant="light" onClick={() => onCategory(ChannelsTabs.technology)}>
                Technology
              </WhiteButton>
              <WhiteButton variant="light" onClick={() => onCategory(ChannelsTabs.podcast)}>
                Podcasts
              </WhiteButton>
              <WhiteButton variant="light" onClick={() => onCategory(ChannelsTabs.public)}>
                Public
              </WhiteButton>
              <ConditionalRender condition={flags[FeatureFlag.cgnVodUpload]}>
                <WhiteButton variant="light" onClick={() => onCategory(defiance)}>
                  Defiance Media
                </WhiteButton>
              </ConditionalRender>
            </H1_FlexRow>
          </ConditionalRender>
        </H1_FlexRow>
        <ConditionalRender condition={!isMainPage}>
          <LiveTabs
            variant="underlined"
            selectedKey={activeTab}
            onSelectionChange={(value) => onChangeActiveTab(value as ChannelsTabs)}
          >
            <Tab key={ChannelsTabs.news} title={"News"} />
            <Tab key={ChannelsTabs.entertainment} title={"Entertainment"} />
            <Tab key={ChannelsTabs.technology} title={"Technology"} />
            <Tab key={ChannelsTabs.podcast} title={"Podcast"} />
            <Tab key={ChannelsTabs.public} title={"Public"} />
            {flags[FeatureFlag.cgnVodUpload] && <Tab key={defiance} title="Defiance Media" />}
          </LiveTabs>
        </ConditionalRender>
        <H1_FlexRow gap="20px">
          {!!onChangeVideoMode && (
            <Switch
              size="sm"
              isSelected={videoMode === LiveVideoMode.Player}
              onValueChange={onSwitchChange}
            >
              <H1_TextSmall color="white">
                {videoMode === LiveVideoMode.Player ? "Default View" : "Theater Mode"}
              </H1_TextSmall>
            </Switch>
          )}
          <ConditionalRender condition={flags[FeatureFlag.cgnVodUpload]}>
            <Button
              onClick={onClickUploadVideoButton}
              startContent={<i className="fas fa-export" />}
            >
              Upload Video
            </Button>
          </ConditionalRender>
          <Button color="primary" onClick={onClickLiveButton}>
            What do you want to watch next?
          </Button>
        </H1_FlexRow>
      </H1_FlexRow>
    </>
  );
};

export default CGNHeader;
