export enum CGNType {
  asset = "asset",
  live = "live"
}

export enum MuxStream {
  live = "live",
  onDemand = "on-demand"
}

export type DEFIANCE = "defiance";
export const defiance = "defiance";

export enum ChannelsTabs {
  news = "news",
  entertainment = "entertainment",
  technology = "technology",
  podcast = "podcast",
  public = "public"
}

export interface MuxStreamChapter {
  start_time: number;
  title: string;
  thumbnail?: string;
}

export interface LiveStream {
  id?: string;
  name: ChannelsTabs | DEFIANCE | string;
  title?: string;
  playback_id: string;
  created_at?: string;
  viewers?: number;
  thumbnail?: string;
  next_video?: { title: string; thumbnail: string };
  type?: CGNType | string;
  active?: boolean;
  chapters: MuxStreamChapter[];
}

export enum LiveVideoMode {
  Player = "Player",
  FullScreen = "FullScreen"
}
