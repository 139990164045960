import { LiveStream } from "app/types";

export const getTHumbnail = (stream: LiveStream) => {
  const thumbnailFallback: Record<string, string> = {
    news: "https://df6g5g0b3bt51.cloudfront.net/recipes/64b6acab166f9f0da0d2082d_1.webp",
    podcast: "https://df6g5g0b3bt51.cloudfront.net/recipes/66fbc61d171b2017e5fa2256.webp",
    technology: "https://df6g5g0b3bt51.cloudfront.net/recipes/643d66c5556b1c268e031b4b.webp",
    entertainment: "https://df6g5g0b3bt51.cloudfront.net/recipes/642c128795644a902f42e3ff.webp"
  };
  return (
    stream.thumbnail ||
    (stream.type === "asset"
      ? `https://image.mux.com/${stream.playback_id}/thumbnail.webp`
      : null) ||
    thumbnailFallback[String(stream.name).toString() as string]
  );
};
